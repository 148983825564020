import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Container,
  Stack,
  Button,
  Autocomplete,
  MenuItem,
  Select,
  Grid,
  FormControl,
  OutlinedInput,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  SvgIcon
} from "@mui/material";

import { ReactComponent as PlustWhiteIcon } from "../../assets/ico_plus_white.svg";
import Prompt from "../../components/Prompt";
import {
  IPromptAcceptSchema,
  IProductHeadings,
  IPlanDetail,
  ExtractDoc,
} from "../../redux/api/types";
import {
  useGetProductsNamesQuery,
  useLazySearchProductQuery,
  useLazyGetProductQuery,
} from "../../redux/api/productApi";
import {
  useGetPromptsQuery,
  useExtractDocMutation,
  useSearchbyUseridMutation,
} from "../../redux/api/promptApi";
import { zodResolver } from "@hookform/resolvers/zod";
import { object, string, TypeOf, array, number } from "zod";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useExportFileMutation } from "../../redux/api/genieApi";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const saveSchema = object({
  product_name: string().min(1, "Product name is required"),
  product_module: string().optional(),
  module_description: string().optional(),
  plan_details: array(
    object({
      user: number(),
      plan_name: string(),
      total_wishes: number().optional(),
      price: string(),
      period: string(),
      product_id: string(),
      price_id: string(),
      subscription_level: string(),
      type: string(),
      button_text: string(),
    })
  ),
});
export type PriceSettingSaveInput = TypeOf<typeof saveSchema>;

const PromptConfigurator = () => {
  const [prompts, setPrompts] = useState<IPromptAcceptSchema[]>([]);
  const [products, setProducts] = useState<string[]>([]);
  const [options, setOptions] = useState<IProductHeadings[]>([]);
  const [filter, setFilter] = useState<IProductHeadings | null>(null);
  const [selectedModule, setSelectedModule] = useState<string | null>(null);
  const [updateByName, { data }] = useExtractDocMutation();

  const [originalPrompts, setOriginalPrompts] = useState<IPromptAcceptSchema[]>([]);


  const [plans, setPlans] = useState<IPlanDetail[]>([
    {
      user: 1,
      plan_name: "",
      total_wishes: 0,
      price: "",
      period: "",
      product_id: "",
      price_id: "",
      subscription_level: "",
      feature: [],
      contracts: 0,
      type: "",
      button_text: "",
    },
  ]);
  const [selectedUser, setSelectedUser] = useState<IProductHeadings | null>(null);
  const [selectedUserId, setSelectedUserID] = useState<any>();

  const methods = useForm<PriceSettingSaveInput>({
    resolver: zodResolver(saveSchema),
    defaultValues: {},
  });
  const { setValue } = methods;


  const getNamesState = useGetProductsNamesQuery();

  const getPromptsState = useGetPromptsQuery();
  const [getProduct, getState] = useLazyGetProductQuery();
  const [searchProduct, searchState] = useLazySearchProductQuery();
  const [searchUserid, { data: searchData, isLoading, isSuccess, isError, error }] = useSearchbyUseridMutation();
  const [userid, setuserid] = useState("");
  const [searchResponse, setSearchResponse] = useState<any>();
  const [searchedData, setSearchedData] = useState<any>();
  /* useEffect(() => {
  
    if (selectedUserId && campaignId) {
      searchUserid({ userid: selectedUserId, campaignid: campaignId });
    }
  }, [searchUserid]); */
  useEffect(() => {
    if (isSuccess) {
      console.log('Search data:', searchData);
    }
    if (isError) {
      console.error('Error searching user by ID:', error);
    }
  }, [isSuccess, isError, searchData, error]);


  const handleUserChange = (event: any, value: any) => {
    console.log('dropdown val=>', value);
    setSelectedUser(value);
    setSelectedUserID(value?.userid);
    // setCampaignId(value?.product_id);
    /* if(value && value.userid && value.product_id)
      { */
    console.log("Calling searchUserid with:", { userid: value?.userid, campaignid: value?.productid });
    searchUserid({ userid: value?.userid, campaignid: value?.productid || '' });
    /* } */

    // console.log("searchUserList", event.target, value, userid);
  };

  useEffect(() => {
    if (isSuccess && searchData) {
      console.log('searchData=>', searchData);
      if ('data' in searchData) {
        console.log('search res=>', searchData.data);
        setSearchedData(searchData.data);
      }
    }
  }, [searchData, isSuccess]);

  console.log('prompts', prompts);

  const filteredPrompts = selectedUser
    ? searchedData/* prompts.filter(prompt => prompt.userId === selectedUser.userId) */
    : prompts;
  /* const filteredPrompts = selectedUserId
    ? prompts.filter(prompt => prompt._id === selectedUserId)
    : prompts; */


  useEffect(() => {
    searchProduct("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getNamesState.isSuccess) setProducts(getNamesState.data);
  }, [getNamesState]);

  /* useEffect(() => {
    if (getPromptsState.isSuccess) {
      setPrompts([...getPromptsState.data]);
    }
  }, [getPromptsState]); */

  useEffect(() => {
    if (getPromptsState.isSuccess) {
      const receivedPrompts = getPromptsState.data;
      setPrompts(receivedPrompts);
      setOriginalPrompts(receivedPrompts);
    }
  }, [getPromptsState]);

  const handleModuleChange = (event: React.ChangeEvent<{}>, newValue: IProductHeadings | null) => {
    setSelectedModule(newValue ? newValue.product_module : null);
    // Reset to original prompts when the module changes
    setPrompts(originalPrompts);
  };

  useEffect(() => {
    if (searchState.data) {
      console.log('options val=>', searchState.data);
      setOptions(searchState.data);
      if (searchState.data.length > 0) {
        setSelectedUser(searchState.data[0]);
        searchUserid({ userid: searchState.data[0]?.userid, campaignid: searchState.data[0]?.productid || '' });
      }
    }
  }, [searchState]);

  useEffect(() => {
    const { data } = getState;
    setValue("product_name", data?.product_name as string);
    setValue("product_module", data?.product_module as string);
    setValue("module_description", data?.module_description as string);
    if (data?.plan_details) setPlans([...data.plan_details]);
    else
      setPlans([
        {
          user: 1,
          plan_name: "",
          total_wishes: 0,
          price: "",
          period: "",
          product_id: "",
          price_id: "",
          subscription_level: "",
          feature: [],
          contracts: 0,
          type: "",
          button_text: "",
        },
      ]);
  }, [getState, setValue]);

  useEffect(() => {
    console.log(prompts);
  }, [prompts]);

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [productModule, setProductModule] = useState('');
  const [productName, setProductName] = useState('');
  const [blogcounts, setBlogcounts] = useState('');
  const [seoline, setSeoline] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setFile(file);
  };
  // eslint-disable-next-line @typescript-eslint/no-redeclare
  const handleUpload = async () => {
    if (file) {
      const formData = {
        file: file,
        product_name: productName,
        product_module: productModule,
        blog_count: parseInt(blogcounts, 10),
        seo_line: seoline,
      };
      await updateByName(formData)
      if (data != undefined) {
        toast.success('Updated successfully');
      } else {
        toast.error('Updated unsuccessfully');
      }
      handleClose();
    } else {
      toast.error('Please select a file to upload');
    }
  };

  console.log('prompts=>', prompts);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const totalPrompts = filteredPrompts?.length;
  const totalPages = Math.ceil(totalPrompts / rowsPerPage);

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const displayedPrompts = filteredPrompts?.slice(startIndex, startIndex + rowsPerPage);

  return (
    <>
      <div className="body-17-1" >
        <Box paddingY={5}>
          <Typography
            textAlign="center"
            variant="h4"
            sx={{
              background:
                "-webkit-linear-gradient(139deg, #036AB7 0%, #49A4EA 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              letterSpacing: "-0.8px",
              fontWeight: "bold",
            }}
          >
            Prompt Configurator
          </Typography>
        </Box>
        <Divider />
        <Container>
          <Stack marginTop={5} spacing={2}>
            <Stack alignItems='end'>
              <div>
                <Button
                  variant="contained"
                  sx={{ textTransform: 'none', paddingY: 1, paddingX: 2, color: 'white' }}
                  startIcon={
                    <SvgIcon>
                      <PlustWhiteIcon />
                    </SvgIcon>
                  }
                  onClick={handleOpen}
                >
                  Upload Category
                </Button>
                <Dialog open={open} onClose={handleClose} sx={{ width: '80%', maxWidth: 'md' }}>
                  <DialogTitle>Upload Category</DialogTitle>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    // value={file}
                    onChange={handleFileChange}
                    type="file"
                  />
                  <TextField
                    label="Product Module"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={productModule}
                    onChange={(e) => setProductModule(e.target.value)}
                  />
                  <TextField
                    label="Product Name"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                  />
                  <TextField
                    label="Blog counts"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={blogcounts}
                    onChange={(e) => setBlogcounts(e.target.value)}
                  />
                  <TextField
                    label="SEO line"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={seoline}
                    onChange={(e) => setSeoline(e.target.value)}
                  />


                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleUpload} variant="contained" color="primary">
                      Upload
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Stack>
            <Stack alignItems="end">
              <Button
                variant="contained"
                sx={{ textTransform: "none", paddingY: 1, paddingX: 2, color: "white" }}
                startIcon={
                  <SvgIcon>
                    <PlustWhiteIcon />
                  </SvgIcon>
                }
                onClick={() => {
                  const newPrompt = {
                    _id: "",
                    product_name: "",
                    plan: "",
                    product_module: "",
                    prompt_name: "",
                    order: 0,
                    prompt: "",
                  };
                  setPrompts(prevPrompts => ([
                    newPrompt,
                    ...prevPrompts // Spread the previous prompts after the new one
                  ]));
                }}
              /* onClick={() => {
                setPrompts([
                  ...prompts,
                  {
                    _id: "",
                    product_name: "",
                    plan: "",
                    product_module: "",
                    prompt_name: "",
                    order: 0,
                    prompt: "",
                  },
                ]);
              }} */
              >
                Add prompt configuration settings
              </Button>

            </Stack>
            <Stack>
              <Autocomplete
                options={options}
                noOptionsText="No Products"
                sx={{
                  width: 216,
                  alignItems: 'right',
                  color: '#4BA5EB',
                  '& .MuiAutocomplete-clearIndicator': {
                    color: '#4BA5EB',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color: '#4BA5EB',
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ border: '1px solid #CBCBCB' }}
                    placeholder="Search Products"
                    // label="Search Products"
                    fullWidth
                    size="small"

                  />
                )}
                getOptionLabel={(option) =>
                  `${option.productName} : ${option?.userName} (${option?.userEmail})`
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      {option.productName} : {option?.userName} ({option?.userEmail})
                    </li>
                  );
                }}
                value={selectedUser}
                onChange={handleUserChange}
              />
            </Stack>

            {displayedPrompts && displayedPrompts.map((prompt: any, index: any) => (
              // if (!selectedModule || prompt.product_module === selectedModule) {
              //   return (
              <Prompt
                key={prompt._id || `prompt_box_${index}`}
                id={prompt._id}
                products={products}
                product={prompt.product_name}
                plan={prompt.plan}
                module={prompt.product_module}
                prompt_name={prompt.prompt_name}
                order={prompt.order}
                prompt={prompt.prompt}
              />
              //   );
              // }
              // return null;
            ))}

          </Stack>
        </Container>

        <Box mt={2} display="flex" justifyContent="center">
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item>
              <Box display="flex" alignItems="center">
                <Typography mr={1}>Rows Per Page:</Typography>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                  <Select
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    sx={{
                      border: 'none',
                      '&:hover': {
                        border: 'none'
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                      }
                    }}
                  >
                    {[10, 25, 50].map(pageSize => (
                      <MenuItem key={pageSize} value={pageSize}>
                        {pageSize} <ArrowDropDownIcon />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <Typography>
                {`${startIndex + 1} - ${Math.min(startIndex + rowsPerPage, totalPrompts)} of ${totalPrompts}`}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                <KeyboardArrowLeftIcon />
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <KeyboardArrowRightIcon />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div>&nbsp;</div>
    </>
  );
};

export default PromptConfigurator;
