import React, { useEffect, useState, ChangeEvent } from "react";
import {
    TextField,
    Grid,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Button,
    InputAdornment,
    IconButton,
    Typography,
    Box,
    Stack,
    Modal,
    Divider,
    SvgIcon,
    Autocomplete,
    Switch,
    CircularProgress,
    TextareaAutosize,
    Tooltip
} from "@mui/material";
import Header from "../../../components/headers/Header";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { useCampaignListMutation, useGetCnameEntriesMutation } from "../../../redux/api/promptApi";
import LanguageIcon from '@mui/icons-material/Language';
import { isBrowser, isMobile } from "react-device-detect";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as VerifiedIcon } from '../../../assets/verified_ico.svg';
import { toast } from "react-toastify";
import { ReactComponent as EditBtnIcon } from '../../../assets/traffic_monster/edit_btn.svg';
import { usePlanDetailsMutation } from "../../../redux/api/productApi";
import InfoIcon from '@mui/icons-material/Info';
import YouTubeIcon from '@mui/icons-material/YouTube';
import youtubeIco1 from '../../../assets/traffic_monster/youtubeIco_1.png'



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: '#D0D5DD',
        color: 'lightblack',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'white', // Set background color for odd rows to white
        color: 'black', // Set text color for odd rows to black
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#F2F6FA', // Set background color for even rows to gray
        color: 'darkslategray',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const HeaderCell = styled('div')(({ theme }) => ({
    color: '#626F86',
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '12px',
    textAlign: 'center',
    '&:nth-child(odd)': {
        backgroundColor: '#EAECF0',
    },
    '&:nth-child(even)': {
        backgroundColor: '#F5F5F5',
    }
}));

const DataCell = styled('div')(({ theme }) => ({
    color: '#344054',
    fontSize: '14px',
    padding: '12px',
    textAlign: 'center',
    '&:nth-child(odd)': {
        backgroundColor: '#EAECF0',
    },
    '&:nth-child(even)': {
        backgroundColor: '#F5F5F5',
    }
}));


const Campaign_List = () => {

    const navigate = useNavigate();

    const [campaignListMutation] = useCampaignListMutation();
    const user_email = localStorage.getItem('user_email') || '';

    const [cnameRecords, setCnameRecords] = useState<any>();
    const [cnameDomainName, setCnameDomainName] = useState<any>();
    const [openCnameModal, setOpenCnameModal] = useState<boolean>(false);
    const [isLoadingCnameRec, setIsLoadingCnameRec] = useState<boolean>(false);
    const [getCnameEntriesMutation] = useGetCnameEntriesMutation();
    const [getuserPlanDetails] = usePlanDetailsMutation();
    const [planDetails, setPlanDetails] = useState<any>();
    const [openPlanALert, setOpenPlanAlert] = useState<boolean>(false);


    const [campaignDatas, setCampaignDatas] = useState<any>();


    const fetchPlanDatas = async () => {
        try {
            const response = await getuserPlanDetails({ email: user_email, campaignid: '' });

            if ('data' in response) {
                setPlanDetails(response.data.data);
                console.log('plan details=>', response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchPlanDatas();
    }, []);

    useEffect(() => {
        localStorage.removeItem('publishingCampId');
    }, []);


    useEffect(() => {
        const campaignRes = async () => {
            try {
                const response = await campaignListMutation({ email: user_email });
                if ('data' in response) {
                    setCampaignDatas(response.data);
                    console.log('campaign list response=>', response.data)
                } else {
                    console.error('Error fetching campaign List:', response.error);
                }
            } catch (error) {
                console.log('Error fetching campaign List:', error);
            }
        }
        campaignRes();
    }, [])

    console.log(campaignDatas);
    /* useEffect(() => {
        if (user_email) {
            campaignRes();
        }
    }, [user_email]); */

    const handleCnameClick = async (campaign_id: string) => {
        setOpenCnameModal(true);
        setIsLoadingCnameRec(true);
        try {
            const response = await getCnameEntriesMutation({ email: user_email, campaignid: campaign_id || '' });
            if ('data' in response) {
                console.log('cnameentries=>', response.data.cnamerecords);
                setCnameDomainName(response.data);
                setCnameRecords(response.data.cnamerecords);
            }
        } catch (error) {
            console.log('Error getting cname records!', error);
        } finally {
            setIsLoadingCnameRec(false);
        }
    }

    const handleCopyName = (name: string) => {
        navigator.clipboard.writeText(name).then(() => {
            toast.success('Copied to Clipboard!');
        })
    }

    const handleCopyVal = (value: string) => {
        navigator.clipboard.writeText(value).then(() => {
            toast.success('Copied to Clipboard!');
        })
    }

    const subscriptionLevelFromCampList = (planDetails?.subscription_level === 'level_0' || planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_2' || planDetails?.subscription_level === 'level_0y' || planDetails?.subscription_level === 'level_4' || planDetails?.subscription_level === 'level_5');
    const subscriptionTH = planDetails?.subscription_level === 'level_3';
    const mode = planDetails?.mode;
    return (
        <>
            <Header />
            <div className="row" style={{ margin: '2%' }}>
                <div className="col-md-12">
                    <Tooltip title="Click here for help video">
                        <img src={youtubeIco1} /* style={{ color: 'red', fontSize: '48px', background: '#fff', borderRadius: '49%' }} */ onClick={() => window.open("https://youtube.com/embed/GKYH2Izm2rY?autoplay=1&mute=0&rel=0", '_blank')} />
                    </Tooltip>
                </div>
            </div>
            <div style={{ margin: '7%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography style={{ color: '#fff', fontWeight: 'bold', fontSize: 'xx-large' }}>My Campaigns</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ justifyContent: 'right', display: 'flex' }}>
                        <Button variant="contained" style={{ color: '#fff', marginBottom: '3%' }} onClick={() => {
                            /* if (planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_2' || planDetails?.subscription_level === 'level_4' || planDetails?.subscription_level === 'level_5') {
                                setOpenPlanAlert(true);
                            } else { */
                            navigate('/codegenie/new-campaign', { state: { subscriptionFromCampList: subscriptionLevelFromCampList } });
                            localStorage.removeItem('campaignid');
                            localStorage.removeItem('returningtocampaign');
                            localStorage.removeItem('sourceurl');
                            localStorage.removeItem('campaignName');
                            localStorage.removeItem('paymentOption');
                            localStorage.removeItem('autoPublish');
                            localStorage.removeItem('limitPublish');
                            localStorage.removeItem('smUrlpublish');
                            localStorage.removeItem('addTags');
                            localStorage.removeItem('captureLeads');
                            localStorage.removeItem('newsLetter');
                            localStorage.removeItem('twitterBtn');
                            localStorage.removeItem('fbBtn');
                            localStorage.removeItem('pintrestBtn');
                            localStorage.removeItem('linkedinBtn');
                            localStorage.removeItem('video');
                            localStorage.removeItem('twitterurl');
                            localStorage.removeItem('fburl');
                            localStorage.removeItem('pintresturl');
                            localStorage.removeItem('linkedinurl');
                            localStorage.removeItem('useOwnDomain');
                            localStorage.removeItem('ChangepayMode');
                            localStorage.removeItem('IdChangingMode');
                            localStorage.removeItem('Authorurl');
                            localStorage.removeItem('Authorname');
                            localStorage.removeItem('authorDefaultCheck');
                            localStorage.removeItem('videoTag');
                            localStorage.removeItem('podcastUrl');
                            localStorage.removeItem('sourcetext');
                            // }
                        }}><AddIcon />New Campaign</Button>
                    </Grid>
                </Grid>
                <Divider />
                <Typography style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}>
                    The Blog Campaign feature allows businesses to create automated, tailored blog campaigns, enhancing brand visibility and engagement effortlessly.
                    It benefits entrepreneurs, digital marketing agencies, and content creators by converting content into SEO-optimized blogs, automating the entire process from creation to ranking.
                    This feature is ideal for promoting products, services, and boosting online presence efficiently.
                </Typography>
                {campaignDatas && campaignDatas.map((data: any, index: any) => (
                    <Grid container spacing={2} sx={{ marginTop: '3%' }} key={index}>
                        <Grid item xs={12} sm={2}>
                            {/* <FormControl variant="outlined" style={{ width: '100%' }}>
                                <Select
                                    defaultValue="myCampaign"
                                    variant="outlined"
                                >
                                    <MenuItem value="myCampaign">{data.campaign_name}</MenuItem>
                                </Select>
                            </FormControl> */}
                            <TextField sx={{ width: '100%' }} value={data.campaign_name}></TextField>
                        </Grid>
                        <Grid item xs={12} sm={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Tooltip title="View DNS Record">
                                <LanguageIcon style={{ fontSize: '35px' }} onClick={() => handleCnameClick(data.campaign_id)} />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button variant="contained" sx={{ fontWeight: 'bolder', width: '100%' }}>
                                {index === 0 ?
                                    (data.subscription_level === 'level_0' || data.subscription_level === 'level_0y' ? 'STARTER' : data.subscription_level === 'level_1' || data.subscription_level === 'level_4' ? 'BASIC' : data.subscription_level === 'level_2' || data.subscription_level === 'level_5' ? 'PRO' : data.subscription_level === 'level_3' || data.subscription_level === 'level_6' ? 'TRAFFIC HACKER' : '')
                                    :
                                    `TH ${(data.subscription_level === 'level_0' || data.subscription_level === 'level_0y' ? 'STARTER' : data.subscription_level === 'level_1' || data.subscription_level === 'level_4' ? 'BASIC'
                                        : data.subscription_level === 'level_2' || data.subscription_level === 'level_5' ? 'PRO' : data.subscription_level === 'level_3' || data.subscription_level === 'level_6' ? 'CAMPAIGN HACKER' : '')}`
                                }</Button>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                        {data.url ? 
                        <TextField sx={{ width: '100%' }} value={data.url /* ? data.url : data.product_desc */}></TextField>
                        : <TextareaAutosize style={{ width: '100%', height: '65px', overflow: 'auto', background: 'none', color: '#4BA5EB', borderRadius: '5px' }} value={data.product_desc}></TextareaAutosize>}
                            
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <div style={{ border: '1px solid #4A4D57', borderRadius: '8px', padding: '2%', display: 'flex', alignItems: 'center', height: '100%' }}>
                                <table style={{ width: '100%', height: '24px' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'center', borderRight: '1px solid #41454F' }}>Published: {/* <span style={{ fontWeight: 'bold' }}>{data.published}</span> */}</td>
                                            <td style={{ textAlign: 'center', borderRight: '1px solid #41454F' }}>Unpublished: {/* <span style={{ fontWeight: 'bold' }}>{data.unpublished}</span> */}</td>
                                            <td style={{ textAlign: 'center' }}>Total: {/* <span style={{ fontWeight: 'bold' }}>{data.total}</span> */}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', borderRight: '1px solid #41454F' }}><span style={{ fontWeight: 'bold' }}>{data.published}</span></td>
                                            <td style={{ textAlign: 'center', borderRight: '1px solid #41454F' }}><span style={{ fontWeight: 'bold' }}>{data.unpublished}</span></td>
                                            <td style={{ textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>{data.total}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <EditBtnIcon onClick={() => {
                                navigate('/codegenie/new-campaign', { state: { FromEdit: 'startEditing', campIdFrEdit: data.campaign_id, subscriptionTH: subscriptionTH, mode: mode } });
                                localStorage.removeItem('ChangepayMode');
                                localStorage.removeItem('IdChangingMode');
                            }}/>
                        </Grid>
                    </Grid>
                ))}
            </div>

            <Modal
                open={openCnameModal}
                onClose={() => setOpenCnameModal(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : '70%',
                        bgcolor: "background.paper",
                        // border: "2px solid #000",
                        border: 'none',
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '16px'
                    }}
                >
                    <Typography style={{ fontSize: '30px', fontWeight: 'bold' }}>Configure DNS</Typography>
                    <div style={{ overflowX: isMobile ? 'auto' : 'visible' }}>
                        {isBrowser ?
                            (isLoadingCnameRec ? <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={28} /></div> :
                                <Table sx={{ minWidth: 700, width: '100%', marginTop: '2%' }} aria-label="customized table">
                                    <TableHead style={{ backgroundColor: ' #EAECF0', border: "gray" }}>
                                        <TableRow>
                                            <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">DOMAIN NAME</StyledTableCell>
                                            <StyledTableCell style={{ border: "gray", width: "10%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">RECORD TYPE</StyledTableCell>
                                            <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">HOST NAME</StyledTableCell>
                                            <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">REQUIRED VALUE</StyledTableCell>
                                            <StyledTableCell style={{ border: "gray", width: "10%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">VERIFY</StyledTableCell>
                                            {/* <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">ACTION</StyledTableCell> */}

                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {cnameRecords && cnameRecords.map((record: any, index: any) => (
                                            <StyledTableRow>
                                                <StyledTableCell style={{ width: '20%', fontSize: '16px', color: '#344054', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}>{cnameDomainName.mydomain_name}</StyledTableCell>
                                                <StyledTableCell style={{ width: '10%', fontSize: '16px', color: '#344054', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}>CNAME</StyledTableCell>
                                                <StyledTableCell style={{ width: '20%', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}><TextField variant="outlined" sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: '#9FADBC',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#9FADBC',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#9FADBC',
                                                        },
                                                        height: 45
                                                    },
                                                }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                    size="small"
                                                                    sx={{
                                                                        marginRight: '4px',
                                                                        background: '#000',
                                                                        borderRadius: '3px',
                                                                        padding: '10%',
                                                                        width: '120%',
                                                                        fontSize: '14px',
                                                                        '&:hover': {
                                                                            background: '#000', // Optional: Add hover effect for better UX
                                                                        },
                                                                    }}
                                                                    onClick={() => handleCopyName(record.name)}
                                                                >
                                                                    Copy
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    value={record.name}></TextField></StyledTableCell>
                                                <StyledTableCell style={{ width: '20%', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}><TextField variant="outlined" sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: '#9FADBC',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#9FADBC',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#9FADBC',
                                                        },
                                                        height: 45
                                                    },
                                                }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                    size="small"
                                                                    sx={{
                                                                        marginRight: '4px',
                                                                        background: '#000',
                                                                        borderRadius: '3px',
                                                                        padding: '10%',
                                                                        width: '120%',
                                                                        fontSize: '14px',
                                                                        '&:hover': {
                                                                            background: '#000', // Optional: Add hover effect for better UX
                                                                        },
                                                                    }}
                                                                    onClick={() => handleCopyVal(record.value)}
                                                                >
                                                                    Copy
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }} value={record.value}>
                                                </TextField></StyledTableCell>
                                                <StyledTableCell style={{ width: '15%', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}>{record.verified === true ? <div style={{ border: '1px solid #027A48', borderRadius: '20px', padding: '5%', textAlign: 'center', background: '#A6F4C5' }}><span style={{ color: '#027A48', fontWeight: 'bold', fontSize: '16px' }}><VerifiedIcon />Verified</span></div> : <div style={{ border: '1px solid #D43131', borderRadius: '20px', padding: '5%', textAlign: 'center' }}><span style={{ color: '#D43131', fontWeight: 'bold', fontSize: '16px' }}>Non-verified</span></div>}</StyledTableCell>
                                                {/* <StyledTableCell style={{ width: '15%', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}><div style={{ border: '1px solid #D43131', color: '#D43131', fontSize: '14px', padding: '5%', textAlign: 'center', borderRadius: '5px' }}>Remove Domain</div></StyledTableCell> */}

                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>)
                            :
                            <Box sx={{ marginTop: '2%', overflowX: 'auto' }}>
                                {isLoadingCnameRec ? <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={28} /></div> :
                                    <Grid container>
                                        {cnameRecords && cnameRecords.map((rec: any, index: any) => (
                                            <>
                                                <Grid item xs={6}>
                                                    <HeaderCell>DOMAIN NAME</HeaderCell>
                                                    <HeaderCell>RECORD TYPE</HeaderCell>
                                                    <HeaderCell>HOST NAME</HeaderCell>
                                                    <HeaderCell>REQUIRED VALUE</HeaderCell>
                                                    <HeaderCell>VERIFY</HeaderCell>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DataCell>{cnameDomainName.mydomain_name}</DataCell>
                                                    <DataCell>CNAME</DataCell>
                                                    <DataCell><TextField variant="outlined" sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#9FADBC',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#9FADBC',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#9FADBC',
                                                            },
                                                            height: 30,
                                                            marginBottom: '-6%'
                                                        },
                                                    }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        edge="end"
                                                                        size="small"
                                                                        sx={{
                                                                            marginRight: '8px',
                                                                            background: '#000',
                                                                            borderRadius: '3px',
                                                                            padding: '5%',
                                                                            width: '140%',
                                                                            fontSize: '10px',
                                                                            '&:hover': {
                                                                                background: '#000',
                                                                            },
                                                                        }}
                                                                        onClick={() => handleCopyName(rec.name)}
                                                                    >
                                                                        Copy
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }} value={rec.name}></TextField></DataCell>
                                                    <DataCell><TextField variant="outlined" sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#9FADBC',
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#9FADBC',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#9FADBC',
                                                            },
                                                            height: 30,
                                                            marginBottom: '-6%'
                                                        },
                                                    }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        edge="end"
                                                                        size="small"
                                                                        sx={{
                                                                            marginRight: '8px',
                                                                            background: '#000',
                                                                            borderRadius: '3px',
                                                                            padding: '5%',
                                                                            width: '140%',
                                                                            fontSize: '10px',
                                                                            '&:hover': {
                                                                                background: '#000',
                                                                            },
                                                                        }}
                                                                        onClick={() => handleCopyVal(rec.value)}
                                                                    >
                                                                        Copy
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        value={rec.value}></TextField></DataCell>
                                                    <DataCell>{rec.verified === true ? <div style={{ border: '1px solid #027A48', borderRadius: '20px', /* padding: '5%', */ textAlign: 'center', background: '#A6F4C5' }}><span style={{ color: '#027A48', fontWeight: 'bold', fontSize: '10px' }}><VerifiedIcon />Verified</span></div> : <div style={{ border: '1px solid #D43131', borderRadius: '20px', /* padding: '5%', */ textAlign: 'center' }}><span style={{ color: '#D43131', fontWeight: 'bold', fontSize: '10px' }}>Non-verified</span></div>}</DataCell>

                                                    <br />
                                                </Grid>

                                            </>
                                        ))}
                                    </Grid>
                                }
                            </Box>
                        }
                    </div>


                    <Stack flexDirection="row" style={{ marginTop: '1%' }}>
                        <Button
                            onClick={() => setOpenCnameModal(false)}
                            variant="contained"
                            sx={{ mr: 2 }}
                        >
                            Close
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={openPlanALert}
                onClose={() => setOpenPlanAlert(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : '50%',
                        bgcolor: "background.paper",
                        // border: "2px solid #000",
                        border: 'none',
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '16px'
                    }}
                >
                    <Typography style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}><InfoIcon style={{ color: 'Orange', fontSize: '40px' }} /><br></br>Only Traffic Hackers can create new campaigns. <br></br>Please upgrade to Traffic Hacker Plan to create a New Campaign.</Typography>
                </Box>
            </Modal>
        </>
    )
}

export default Campaign_List;