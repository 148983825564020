import { Box, Typography, Container, Divider, SvgIcon, Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import img54 from "../../assets/newimg/logo.png";

import { object, string, TypeOf } from "zod";
import { useSubscribeNewsMutation } from "../../redux/api/authApi";
import { toast } from "react-toastify";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";



const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>
const  TermsPage = () => {
const navigate = useNavigate();
const handleLearnMore = () => {
  navigate('/codegenie/smart_contracts');
}
const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();

const methods = useForm<NewsSubscriptionForm>({
  resolver: zodResolver(newsSubscribeform),
});

const {
  register,
  handleSubmit,
  formState: { errors },
} = methods;

const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
  newsSubsctiptionMutation(values);
};

const [currentDate, setCurrentDate] = useState(new Date());

useEffect(() => {
  const timerID = setInterval(() => setCurrentDate(new Date()), 1000);
  return () => clearInterval(timerID);
}, []);


const formattedDate = currentDate.toLocaleDateString('en-US', {
  month: 'long',
  day: 'numeric',
  year: 'numeric'
});



const handleSubscribeNews = () => {
  newsSubsctiptionMutation(subscribeVal)
    .unwrap()
    .then((response) => {
      console.log('news subscription=>', response);
        const status = response.status;
      
        console.log(status);
        if (status === 'success') {
          toast.success(response.message);
          setSubscribeVal({
            email: '',
            status: ''
          });
        }
    })
    .catch((error) => {
      toast.error(error?.data?.detail ?? 'Something went wrong!');
      console.error('Error sending query:', error);

    });
}
  return (
    <>
    
      <Container maxWidth="lg">
      <nav className="navbar navbar-expand-md">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              {/* <img src="./img/logo.png" alt="" /> */}
              <img src={img54} className="img-fluid" alt="" />
              {/* <img src={logo} alt="" /> */}
  
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="">
                <i className="bi bi-list text-white" />
              </span>
            </button>



            
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                <li className="nav-item">
                  <a
                    className="nav-link nav_active"
                    style={{ color: "#4ba5eb", fontWeight: 800 }}
                    href="/"
                  >
                    Home
                  </a>
                </li>




                <li className="nav-item">
                  <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup">
                    Affiliate
                  </a>
                </li> 
              </ul>


              
              <div className="d-flex gap-4" role="search">
              <button className="button_leniar px-3" type="submit"  onClick={() => {
                navigate("/signup");
              }}>
                  Try Now
                </button>
                <button className="btn btn-outline-primary px-3"
                  onClick={() => {
                    navigate("/signin");
                  }}>Sign  in</button>
              </div>
            </div>
          </div>
        </nav>
          <Box py={5}>
            <Typography
              variant="h2"
              color="text.secondary"
              fontWeight={600}
              textAlign="justify"
              mb={10}
              
            >
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Code Genie Terms of Service </div>
          </Typography>
          <Typography>
            {/* Last Updated: {formattedDate} */}
            Last Updated: April 26, 2024
            <br />
            <br />
            This Terms of Service (the “Terms”) is a binding agreement between you ("End User" or "you") and OceSha, Inc DBA Code Genie AI ("Code Genie"). 
            These Terms govern your use of Code Genie’s https://www.trafficmonster.ai/, Traffic Monster (the “Site”) and provide you with the opportunity to use the artificial intelligence services such as Blog Creation, Content Creation, AI Agent, and other services which shall be done through the Site (together with the Site, the “Services”). The Services are licensed, not sold to you, subject to your acceptance without modification of the terms, conditions, and notices contained herein.
             <br></br><br></br> 
             BY CLICKING THE BOX TO INDICATE ACCEPTANCE, BY USING THE SITE, OR OTHERWISE USING THE SERVICES, YOU<br></br><br></br>  
              (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD THESE TERMS; <br></br><br></br>
              (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND<br></br><br></br>
              (C) ACCEPT THESE TERMS AND AGREE THAT YOU ARE LEGALLY BOUND BY THESE TERMS AND THE CODE GENIE PRIVACY POLICY. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE SITE AND CEASE USE OF THE SERVICES. SECTIONS BELOW INCLUDE AN ARBITRATION PROVISION AS WELL AS A CLASS ACTION WAIVER. YOU AGREE TO SETTLE ALL DISPUTES BY BINDING INDIVIDUAL ARBITRATION THROUGH AGREEING TO THESE TERMS OF USE, WHICH ENSURES YOU FORFEIT YOUR RIGHT TO HAVE THE CASE RESOLVED BY A JUDGE OR JURY, AS WELL AS ANY RIGHT TO ENGAGE IN COLLECTIVE ACTION, SUCH AS A CLASS ACTION, CLASS ARBITRATION, OR REPRESENTATIVE ACTION.

          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            1. License Grant
          </Typography>
          <Typography>
            Subject to these Terms, Code Genie grants you a limited, non-exclusive, 
            and nontransferable license to use the Site for your personal use on devices 
            owned or otherwise controlled by you (“Device(s)”) and to use the Services strictly in accordance with these Terms.
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            2. Changes to the Terms
          </Typography>
          <Typography>
            We amend these Terms from time to time, for instance when we update the functionality of our Services, 
            or when there are regulatory changes. We will use commercially reasonable efforts to generally notify all users 
            of any material changes to these Terms, such as through a notice on our platform or Site, however, you should look at 
            the Terms regularly to check for such changes. We will also update the “Last Updated” date at the top of these Terms, 
            which reflect the effective date of such Terms. Your continued access or use of the Services after the date of the new Terms 
            constitutes your acceptance of the new Terms. 
            If you do not agree to the new Terms, you must stop accessing or using the Services.

          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            3.Your Account with Us 
          </Typography>
          <Typography>
            To access or use some of our Services, you must create an account with us. 
            When you create this account, you must provide accurate and up-to-date information. 
            It is important that you maintain and promptly update your details and any other information you provide to us, 
            to keep such information current and complete. It is important that you keep your account password confidential and 
            that you do not disclose it to any third party. If you know or suspect that any third party knows your password or has 
            accessed your account, you must notify us immediately. You agree that you are solely responsible (to us and to others) 
            for the activity that occurs under your account. We reserve the right to disable your user account at any time, including 
            if you have failed to comply with any of the provisions of these Terms, or if activities occur on your account which, in 
            our sole discretion, would or might cause damage to or impair the Services or infringe or violate any third party rights, 
            or violate any applicable laws or regulations. If you no longer want to use our Services again, and would like your account 
            cancelled or deleted, contact us. We will provide you with further assistance and guide you through the process. Once you 
            choose to delete your account, you will not be able to reactivate your account or retrieve any of the content or information
            you have added.

          </Typography>

          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            4. Intellectual Property
          </Typography>
          <Typography>
          Your Access and Use of Our Services - You will access the Site from your browser. You will not be able to engage in any features of the Site other than through your authorized access.<br></br><br></br>

a. Access - you understand and accept that access to your Code Genie account is restricted to the account owner only. Without our prior written permission, you agree not to sell, rent, lease, or give access to your Code Genie account to anyone.<br></br><br></br>
b. Protection - You accept and agree that you are solely responsible for the security of your account and for maintaining control over any user names, passwords, or other codes you use to access the Services. You accept and consent that you will not hold us accountable for the maintenance and protection of your account. You also understand that if you share your account with others, you risk having your personal information exposed and agree that we will not be held liable for any losses or damages resulting from such unauthorized sharing.<br></br><br></br>
c. Use of Services - You agree to use the Services only for purposes that are allowed both by these Terms and any applicable law, regulation, or generally accepted practices or guidelines in the relevant jurisdiction. You accept and consent not to engage in any activity that interferes with or disrupts the Services. You also agree not to use any automated systems or means to access, obtain, or download materials from the Services (including without limitation, robots, spiders, or scripts).<br></br><br></br>
d. Acceptable Use Policy - You understand and agree to comply with the Code Genie Acceptable Use Policy which provides you with an overview of the allowable uses of the Site and the Services, as well as the actions that are prohibited. You must adhere to these restrictions when accessing and using Code Genie's services:<br></br><br></br>
e. User Eligibility: Access and use are only permitted if you are of legal age, legally competent or authorized by a parent or guardian.<br></br><br></br>
f. Prohibited Actions: You may not engage in the following activities:


          <Box component="ul">
              <Box component="li">
              Unauthorized copying, modifying, reverse engineering, or creating derivative works.
              </Box> 

              <Box component="li">
               Using the services for commercial or unauthorized purposes, including spamming.
              </Box>

              <Box component="li">
              Distributing, licensing, transferring, or selling the services or derivatives.


              </Box>
              <Box component="li">
              Renting or leasing the services for a fee, or using them for commercial solicitation.

              </Box>

              <Box component="li">
              Interfering with service operation or bypassing access measures.
              </Box>

              <Box component="li">
              Incorporating services into other products without permission.
              </Box>

              {/* <Box component="li">
              Incorporating services into other products without permission.
              </Box> */}
              
              <Box component="li">
              Using automated scripts to interact with the services.
              </Box>

              <Box component="li">
              Impersonating others or engaging in harassment, explicit content, or discrimination.
              </Box>

              <Box component="li">
              Unauthorized account use or creating false identities.
              </Box>
              
              <Box component="li">
              Using services for malicious content, unsolicited advertising, or prohibited solicitation.
              </Box>

              <Box component="li">
              Extracting private information of third parties.
              </Box>

              <Box component="li">
              Uploading material that infringes intellectual property, is defamatory, obscene, offensive, or harmful.
              </Box>

              <Box component="li">
              Sharing criminal, discriminatory, threatening, or harmful content.
              </Box>


              <Box component="li">
              Engaging in fraud, deceit, or misleading activities.
              </Box>
              </Box>
             g.Compliance with Community Guidelines: Your use must align with community guidelines.<br></br>
             h.Code Genie's Discretion: Code Genie may remove or disable content without notice for various reasons, such as violating terms or harming the services or users. Your use of Code Genie services should always respect these restrictions and comply with community guidelines. Code Genie reserves the right to remove or disable content at its discretion, based on factors including violation of terms or harm to the services or users.


          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            5. User Content 
          </Typography>
          <Typography>
          You may provide input to the Services (“Input”), and receive output 
          generated and returned by the Services based on the Input (“Output”). Input and Output are collectively “Content.” As 
          between the parties and to the extent permitted by applicable law, you own all Input. Subject to your compliance with these 
          Terms, Code Genie hereby assigns to you all its right, title and interest in and to Output. This means you can use Content 
          for any purpose, including commercial purposes such as sale or publication, if you comply with these Terms. Code Genie may 
          use Content to provide and maintain the Services, comply with applicable law, and enforce our policies. You are responsible 
          for Content, including for ensuring that it does not violate any applicable law or these Terms. All Content shall adhere to 
          the following guidelines:<br></br>
          <Box component="ol" type="a">
              <Box component="li">
              By providing Input and information to the Site, you grant us and our associates, as well as their respective licensees, successors, and assigns, the right to use, reproduce, alter, execute, view, distribute, retransmit, print, broadcast, and otherwise reveal any such material to third parties for any reason.
              </Box>
              <Box component="li">
              You reflect and warrant that (1) you own and control all rights to your User Content, and that you have the authority to issue such licenses to us and our associates, as well as their respective licensees, successors, and assigns; and (2) all of your User Content complies with these Terms of Use.
              </Box>
              <Box component="li">
              You recognize and accept that you, not Code Genie, are solely responsible for any User Content you send, contribute or generate, and that you, not Code Genie, are solely responsible for such content’s legality, reliability, accuracy, and appropriateness. We are not liable to any third parties for the quality, accuracy, or appropriateness of any User Content posted on or generated through the Site by you or any other user.
              </Box>
              <Box component="li">
              d.You give Code Genie permission to use your name and picture for marketing and promotional purposes.
              </Box>
              <Box component="li">
              As a User, you understand and accept that (1) You are responsible for the accuracy and quality of your content; (2) Any content that breaches any of Code Genie policies can be obfuscated or removed at Code Genie’s sole discretion;
              </Box>
              <Box component="li">
              Code Genie reserves the right to track User Content in order to identify and avoid fraud or breaches of these Terms of Service.
              </Box>
              <Box component="li">
              Due to the nature of artificial intelligence, generative AI, and machine learning, Output may not be unique across users and the Services may generate the same or similar output for Code Genie or a third party. Other users may also ask similar questions or contribute similar content (input) and receive the same response (output). Responses that are requested by and generated for other users are not considered your Content. Code Genie uses Artificial Intelligence, Generative AI, and Machine Learning in generating content and does NOT guarantee the accuracy of Content
              </Box>
            </Box>
          </Typography>

          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            6. Ownership, Intellectual Property, and Privacy
          </Typography>

              <Typography>
              Ownership
         
                <Box component="ul">
                  <Box component="li">
                  Code Genie Content: The Site's content and materials, including the Code Genie logo, designs, text, graphics, and more, belong to Code Genie or its partners.
                  </Box>

                  <Box component="li">
                  Trademark: Code Genie trademarks and logos, including product and service names, are the property of Code Genie and affiliates. Their use without permission is prohibited.
                  </Box>

                  <Box component="li">
                  Usage Restrictions: You cannot use Code Genie Content on the Site without written permission, including through framing techniques.
                  </Box>
                </Box>
              </Typography>
              <Typography>
              Intellectual Property
                <Box component="ul">
                  <Box component="li">
                  Third-party Trademarks: Other trademarks and logos on the Site are owned by their respective holders and require permission for use.
                  </Box>

                  <Box component="li">
                  Copyright or Trademark Infringement: If you believe third-party material on Code Genie's site violates your copyright or trademark rights, you can contact Code Genie. Provide details about the infringement, your contact information, and a declaration of correctness.
                  </Box>
                </Box>
              </Typography>

              <Typography>
              Privacy
                <Box component="ul">
                  <Box component="li">
                  Personal Information: Your personal information may be collected, used, and released according to Code Genie's Privacy Policy, which is part of this User Agreement.
                  </Box>
                </Box>
              </Typography>

          <Typography>
          We may terminate or suspend your access to Code Genie at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to us, other users of Code Genie, or third parties, or for any other reason.
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            7. Indemnification
          </Typography>
          <Typography>
          You accept and agree to indemnify and hold Code Genie and its subsidiaries, affiliates, officers, employees, agents, partners, and licensors harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Services, your connection to the Services, your violation of these Terms, or your violation of any rights of another.

          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            8. Disclaimers 
          </Typography>
          <Typography>
          Your use of the Services is at your sole risk. The Services are provided on an "as is" and "as available" basis. Code Genie expressly disclaims all warranties of any kind, whether express, implied, or statutory, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.
          
            <Box component="ul">
              <Box component="li">
              Code Genie provides the Website and its Content on an "as is" and "as available" basis without warranties or conditions of any kind.

              </Box>

              <Box component="li">
              No warranty that the Site will meet your requirements, be uninterrupted, timely, secure, error-free, accurate, reliable, complete, legal, or safe.

              </Box>

              <Box component="li">
              Code Genie makes no representation or warranty regarding the accuracy, completeness, reliability, currency, or error-free nature of the Content.
             is part of this User Agreement.
              </Box>

              <Box component="li">
              While Code Genie strives for safe access to the Website and Content, it cannot guarantee the absence of viruses or harmful components, nor the security of disclosed data.
              </Box>

              <Box component="li">
              Code Genie is not liable for user errors, server failures, data loss, unauthorized access, or third-party activities on the Site.
              </Box>

              <Box component="li">
              Code Genie is not responsible for losses due to software vulnerabilities, AI issues, or related problems.
              </Box>

              <Box component="li">
              Code Genie disclaims all warranties, whether express or implied, statutory or otherwise, including but not limited to merchantability, fitness for a particular purpose, title, and non-infringement.
              </Box>

              <Box component="li">
              Code Genie shall not be liable for lost profits or indirect, consequential, exemplary, incidental, special, or punitive damages.

              </Box>

              <Box component="li">
              Access to and use of the Site, Services, and third-party sites/products are at your own risk.

              </Box>

              <Box component="li">
              You are responsible for any damage to your system or device due to Site access.
              </Box>

              <Box component="li">
              Code Genie's aggregate liability shall not exceed specified limit of $1.
              </Box>
            </Box>
          </Typography>
          {/* <Typography>
          Your use of the Services is at your sole risk. The Services are provided on an "as is" and "as available" basis. Code Genie expressly disclaims all warranties of any kind, whether express, implied, or statutory, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.
          </Typography> */}
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            9. Limitation of Liability 
          </Typography>
          <Typography>
          You accept and agree that Code Genie will not be liable for any indirect, incidental, special, consequential, or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if Code Genie has been advised of the possibility of these damages), resulting from your use of the Services.

          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            10.  Governing Law and Jurisdiction
          </Typography>
          <Typography>
          These Terms will be governed by and construed in accordance with the laws of California without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in Orange County, California, USA to resolve any legal matter arising from these Terms.
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            11. Dispute Resolution and Arbitration
          </Typography>
          <Typography>
          Arbitration Agreement: This section outlines the process for resolving disputes between users and Code Genie through arbitration and limits options for seeking relief.

          
          <Box component="ul">
            <Box component="li">
            Individual Resolution: All users agree that any arbitration or action related to a dispute will be conducted individually, and the right to file or seek relief on a class basis is waived.
            </Box>
              
            <Box component="li">
            Binding Arbitration: Disputes concerning site access, use, services, accuracy of Content or the user's relationship with Code Genie will be settled through binding arbitration, except for specific intellectual property claims in small claims court.

              </Box>
              
            <Box component="li">
            Arbitration Location: Arbitration will occur within the United States, following the rules of California.
                </Box>
                
            <Box component="li">
            Arbitrator's Authority: Arbitrators have authority to interpret and enforce the Arbitration Agreement, resolve disputes about its understanding, enforceability, or formation, determine rights and liabilities, grant motions, and award damages and remedies.

                </Box>
                
            <Box component="li">
            Individual Remedies: Arbitrators can provide individual remedies, and their decisions are binding, foregoing the right to jury trials or class actions.

                </Box>
                
            <Box component="li">
            Validity: If any part of the Arbitration Agreement is unenforceable, the rest remains valid.

                </Box>
                
            <Box component="li">
            Duration: The Arbitration Agreement remains effective until the user's association with Code Genie concludes.
            </Box>
          </Box>
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            12. Relationship, Termination, Severability, and Survival
          </Typography>
          <Typography>
            <Box component="ul">
              <Box component="li">
              Relationship: These Terms do not establish a partnership, joint venture, or agency relationship between you and Code Genie or its affiliates. Both parties are independent contractors, lacking the authority to bind or obligate each other without prior written consent.
              </Box>
            
              <Box component="li">
              Termination: Code Genie holds the right to terminate your access or use of the Site or Services without notice, at its discretion. Upon termination, Code Genie bears no responsibility or obligation to you.
              </Box>
                          
              <Box component="li">
              Severability and Entire Agreement: If any portion of these Terms is deemed unenforceable, it won't affect the validity of the rest. These Terms encompass your entire agreement with Code Genie regarding Site access and use, replacing any prior negotiations or agreements.
              </Box>

              <Box component="li">
              Survival: All clauses in these Terms persist beyond their termination or expiration, ensuring their continued applicability.
              </Box>
            </Box>
          </Typography>
          <Typography variant="h4" color="text.secondary" mt={5} mb={2}>
            13. Contact Information
          </Typography>

          <Typography>
          If you have any questions about these Terms, please contact Code Genie at <Link
                          to=""
                          onClick={(e) => {
                            window.location.href = `mailto:support@code-genie.ai`;
                            e.preventDefault();
                          }}
                          style={{
                            color: "#0168B5",
                          }}
                        >
                          support@code-genie.ai 
                        </Link>
          </Typography>
          <Link
            to="/signin"
            style={{
              display: "flex",
              marginTop: 40,
              textDecoration: "none",
              color: "white",
            }}
          >
            <SvgIcon sx={{ mr: 1 }}>
              <KeyboardBackspace />
            </SvgIcon>
            Back to log in
          </Link>
          </Box>
      </Container>

      
    </>
  );
};

export default TermsPage;
