import React, { useEffect, useState } from "react";
import {
    TextField,
    Grid,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Button,
    InputAdornment,
    IconButton,
    LinearProgress,
    Box,
    Stack,
    Modal,
    Typography,
    Tooltip,
    Radio,
    Paper,
    TextareaAutosize
} from "@mui/material";
import { makeStyles } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useGenerateBusinessBlogMutation } from "../../redux/api/promptApi";
import { useGetUserDetailsMutation } from "redux/api/authApi";
import { useNavigate } from "react-router-dom";
import PayNowButton from '../../pages/payment/payNowButton';
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { usePlanDetailsMutation } from "../../redux/api/productApi";
import { responsiveProperty } from "@mui/material/styles/cssUtils";
import InfoIcon from '@mui/icons-material/Info';
import YouTubeIcon from '@mui/icons-material/YouTube';
import youtubeIco1 from '../../assets/traffic_monster/youtubeIco_1.png';
import { styled } from '@mui/material/styles';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const BorderLinearProgress = styled(LinearProgress)(({ theme }: { theme: Theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const Dashboard_tm = () => {

    const navigate = useNavigate();
    const [userBusinessUrl, setUserBusinessUrl] = useState('');
    const [userBusinessText, setUserBusinessText] = useState('');
    const [userBusinessurl2, setUserBusinessUrl2] = useState('');
    const [podcastoryoutubeurl, setPodcastOrYoutubeUrl] = useState('');
    const [selectedSection, setSelectedSection] = useState('websiteSection');
    const [isLoading, setIsLoading] = useState(false);
    const [openPaidModal, setOpenPaidModal] = useState(false);
    const [businessBlogMutation] = useGenerateBusinessBlogMutation();
    const freePlan = parseInt(localStorage.getItem('free_plan') || '');
    const payment = localStorage.getItem('payment');
    // const subscription_level = localStorage.getItem('subscription_level');
    const [toneSelection, setToneSelection] = useState<any>();
    const [httpsVal, setHttpsVal] = useState('https://');
    const [fieldType, setFieldType] = useState<any>('url');
    const [getuserPlanDetails] = usePlanDetailsMutation();
    const [planDetails, setPlanDetails] = useState<any>();
    const [subscription_level, setSubscriptionLevel] = useState<any>();
    const [userDets, setUserDets] = useState<any>();
    const [getUserDetailsMutation] = useGetUserDetailsMutation();

    useEffect(() => {
        const loggedIn = localStorage.getItem("logged_in");
        if (loggedIn !== "true") {
            navigate("/signin");
        }
    }, [navigate]);

    const user_email = localStorage.getItem('user_email');

    const fetchPlanDatas = async () => {
        try {
            const response = await getuserPlanDetails({ email: user_email || '', campaignid: '' });

            if ('data' in response) {
                setPlanDetails(response.data.data);
                console.log('plan details=>', response.data.data);
                setSubscriptionLevel(planDetails.subscription_level);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchPlanDatas();
        console.log(subscription_level);
    }, [subscription_level]);

    const fetchData = async () => {
        try {
            const response = await getUserDetailsMutation({ email: user_email || '' });
            if ('data' in response) {
                setUserDets(response.data.data);
                // console.log('userDetails=>', response.data.data);
            }
        } catch (error) {
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    const handleGenerateBlogClick = async (url: string, userid: string, tone: any, product_desc: any) => {
        if (/* freePlan === 0 &&  */subscription_level === "") {
            setOpenPaidModal(true);
        } else if ((selectedSection === 'podcastSection' || userDets?.appsumo_coupon_code) && !url) {
            toast.error('Please enter your youtube/podcast url!');
        }
        else if (freePlan === 1 || payment === 'Success' || subscription_level === 'level_1' || subscription_level === 'level_2' || subscription_level === 'level_3' || subscription_level === 'level_4' || subscription_level === 'level_5' || subscription_level === 'level_6' ||
            planDetails.subscription_level === 'level_1' || planDetails.subscription_level === 'level_2' || planDetails.subscription_level === 'level_3' || planDetails.subscription_level === 'level_4' || planDetails.subscription_level === 'level_5' || planDetails.subscription_level === 'level_6'
        ) {
            setIsLoading(true);
            try {

                // const youtubeMatch = podcastoryoutubeurl?.match(
                //     /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
                // );
                var tmpurl = httpsVal === 'https://' ? httpsVal + (document.getElementById('sourceurl') as HTMLInputElement)?.value : '';

                let youtubeMatch;
                let videoid = "";
                if (userDets?.appsumo_coupon_code || selectedSection === 'podcastSection') {
                    youtubeMatch = tmpurl?.match(
                        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
                    );

                    videoid = youtubeMatch ? youtubeMatch[1] : '';
                }
                url = tmpurl !== '' ? encodeURIComponent(tmpurl) : '';

                const result = await businessBlogMutation({ url, userid, tone, sourcemode: /* selectedSection === 'podcastSection' || userDets?.appsumo_coupon_code ? 'youtube' : */ httpsVal === 'text' ? 'text' : 'url', videoid: videoid ? videoid : '', product_desc });

                if ('data' in result) {
                    const { data } = result;

                    // Increment the generating count
                    const generatingCount = localStorage.getItem('Generating Count');
                    const newGeneratingCount = generatingCount ? parseInt(generatingCount) + 1 : 1;
                    localStorage.setItem('Generating Count', newGeneratingCount.toString());

                    localStorage.setItem("finalData", JSON.stringify(data.data).replace(/\*\*/g, '')
                        .replace(/\#\#\#/g, '')
                        .replace(/\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+/g, '')
                        .replace(/<h3>/g, '<h5>')
                        .replace(/<\/h3>/g, '</h5>')
                        .replace(/<h2>/g, '<h5>')
                        .replace(/<\/h2>/g, '</h5>'));
                    // localStorage.setItem('Blogs Generated', 'true');

                    if (data.result === 'error') {
                        toast.error(data.message);
                        console.log(data.message);
                    } else if (data.result === 'success') {
                        navigate('/codegenie/generated-blogs', { state: { FromDashboard: "true" } });
                        console.log('Data:', data.result);
                    }
                } else {
                    const errorMessage = (result?.error as any)?.data?.detail as string | undefined;
                    toast.error(errorMessage);
                    setIsLoading(false);
                    //window.location.reload();
                }
            } catch (error) {
                console.log('Unexpected Error:', error);
            } finally {
                setIsLoading(false);
                // navigate('/codegenie/generated-blogs');
            }
        }
    }

    const handledropdownChange = (event: any) => {
        setSelectedSection(event.target.value);
    }

    const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputUrl = event.target.value.trim();

        // Check if the input already starts with "https://"
        if (inputUrl.startsWith('https://')) {
            // If it does, remove the first occurrence of "https://"
            inputUrl = inputUrl.replace('https://', '');
        }

        setUserBusinessUrl(inputUrl);
    };

    const handleTextChange = (event: any) => {
        let inputText = event.target.value;
        setUserBusinessText(inputText);
    };

    return (
        <>
            <div className="row" style={{ width: '100%' }}>
                <div className="col-md-12">
                    <Tooltip title="Click here for help video">
                        <img src={youtubeIco1} style={{ /* color: 'red', fontSize: '48px', */ margin: '2%'/* , background: '#fff', borderRadius: '49%' */ }} onClick={() => window.open("https://youtube.com/embed/ocbezJpEohM?autoplay=1&mute=0&rel=0", '_blank')} />
                    </Tooltip>
                </div>
            </div>

            {/* website url section*/}
            <div style={{ margin: '12%', marginTop: '0%', marginBottom: '1%' }}>
                <h3 style={{ fontWeight: 'bolder' }}>
                    {/* {selectedSection === 'websiteSection' && !userDets?.appsumo_coupon_code ? */}
                    <Typography>Generate Blogs with Code Genie AI</Typography>
                    {/* :
                            <Typography>Traffic Monster will generate blogs from your podcast or video.</Typography> */}
                    {/* } */}
                </h3>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                        {/* <TextField
                            fullWidth
                            variant="outlined"
                            value={httpsVal}
                            InputProps={{
                                readOnly: true,
                                style: { color: '#4AA2E7', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px', backgroundColor: '#fff' }
                            }}
                        /> */}
                        <Paper
                            elevation={3}
                            sx={{
                                padding: 1,
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                width: '100%',
                            }}
                        >
                            <Select
                                value={httpsVal}
                                onChange={(event) => setHttpsVal(event.target.value)}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    width: '100%',
                                    height: '40px',
                                    '.MuiInputBase-root': {
                                        height: '100%'
                                    },
                                    backgroundColor: 'white',
                                    color: '#4BA5EB',
                                    '& .MuiSelect-icon': {
                                        color: '#4BA5EB',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                }}
                            >
                                <MenuItem value="https://">https://</MenuItem>
                                <MenuItem value="text">Text</MenuItem>
                            </Select>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {httpsVal === 'text' ?
                            <TextareaAutosize style={{ width: '100%', height: '200px', borderColor: '#CBCBCB', borderRadius: '5px', overflow: 'auto' }}
                                value={userBusinessText}
                                onChange={handleTextChange}></TextareaAutosize>
                            :
                            <TextField
                                fullWidth
                                label={/* selectedSection === 'websiteSection' &&  !userDets?.appsumo_coupon_code  ? */"Enter your business website URL"/* :"Enter your podcast/youtube URL" */}
                                variant="outlined"
                                value={userBusinessUrl}
                                onChange={handleUrlChange}
                                id="sourceurl"
                                style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px', backgroundColor: '#fff', color: '#4AA2E7' }}
                                InputLabelProps={{ style: { color: '#4AA2E7' } }}
                            // disabled={selectedSection !== 'websiteSection'}
                            /* InputProps={
                                !userDets?.appsumo_coupon_code ? 
                                {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Select
                                            value={selectedSection}
                                            onChange={handledropdownChange}
                                            variant="standard"
                                            disableUnderline
    
                                            sx={{
                                                color: '#4AA2E7',
                                                backgroundColor: '#f0f0f0',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                padding: '2px 8px',
                                                '& .MuiSelect-icon': {
                                                    color: 'black',
                                                },
                                                width: '235px'
                                            }}
                                        >
                                            <MenuItem value="websiteSection">Your Website URL</MenuItem>
                                            <MenuItem value="podcastSection">Your Podcast/Video</MenuItem>
                                        </Select>
                                    </InputAdornment>
                                )
                            } : {}} */
                            />
                        }
                        {/* {isMobile ? <FormControl variant="outlined" style={{ width: '100%' }}>
                            <Select
                                defaultValue="url"
                                variant="outlined"
                                onChange={(event) => setFieldType(event.target.value)}
                                style={{ paddingTop: 2, paddingBottom: 2, height: 45, color: '#000', backgroundColor: '#CBCBCB', marginTop: '4%' }}
                            >
                                <MenuItem value="url">URL</MenuItem>
                            </Select>
                        </FormControl> : ''} */}
                    </Grid>

                    <Grid item xs={6} sm={2}>
                        <Button style={{ height: httpsVal === 'text' ? '56px' : '-webkit-fill-available', color: '#fff', filter: isLoading ? 'blur(2px)' : 'none', pointerEvents: isLoading ? 'none' : 'auto' }} variant="contained" fullWidth /* disabled={selectedSection !== 'websiteSection'} */ onClick={() => {
                            if (userBusinessUrl || user_email && userBusinessText) {
                                const decodedBusinessUrl = httpsVal === 'https://' ? encodeURIComponent(httpsVal + userBusinessUrl) : '';
                                const decodedBusinessText = encodeURIComponent(userBusinessText);
                                const decodedEmail = encodeURIComponent(user_email || '');
                                handleGenerateBlogClick(decodedBusinessUrl, decodedEmail, toneSelection, decodedBusinessText);
                            } else {
                                if ((httpsVal === 'text' && userBusinessText === '') ||
                                (httpsVal !== 'text' && userBusinessUrl === ''))
                                    toast.error("Please enter your Business URL/Text");
                                /* else
                                    toast.error("Please enter your Business URL"); */

                                console.log("userBusinessUrl or user_email is null");
                            }
                        }}>
                            {'Generate Blog'}
                        </Button>
                    </Grid>
                </Grid>

                {/* {selectedSection === 'podcastSection' || userDets?.appsumo_coupon_code ?
                    <Grid container spacing={2} sx={{ marginTop: '1px' }}>
                        <Grid item xs={12} sm={12} >

                            <TextField
                                fullWidth
                                label="Enter your podcast/youtube URL"
                                variant="outlined"
                                value={podcastoryoutubeurl}
                                onChange={(e) => setPodcastOrYoutubeUrl(e.target.value)}
                                style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px', backgroundColor: '#fff', color: '#4AA2E7' }}
                                InputLabelProps={{ style: { color: '#4AA2E7' } }}

                            />
                        </Grid>
                    </Grid>
                    : ''} */}

                <Grid container>
                    <Grid item xs={12} sm={12}>
                        {/* <Typography>{fieldType === 'url' ? 'NOTE: Please copy/paste your website url from your browser.  This url should normally be the main landing page of your website that describes your products or services.  We will use the data from this page to create personalized blogs and content to represent your brand.<br/>Note that you will NOT be able to change this website url later' : ((fieldType === 'upload' || fieldType === 'addtext') ? 'NOTE: Tell us details about your company, products, services that you want to create blogs about.' : '')}</Typography> */}
                        {/* {selectedSection === 'websiteSection' ? */}
                        <Typography>NOTE: Please copy/paste your website url from your browser.  This url should normally be the main landing page of your website that describes your products or services.  We will use the data from this page to create personalized blogs and content to represent your brand.<br />Note that you will NOT be able to change this website url later</Typography>
                        {/*  :
                            <Typography>Note: Copy/paste your YouTube podcast/video url .  Our AI will use the information from the video to create personalized blogs to represent your brand and content.</Typography> */}
                        {/* } */}
                    </Grid>
                </Grid>
                {/* </Grid> */}

                {/* </Grid> */}
            </div>
            {/* section end */}

            {/* Loader section */}
            {isLoading && (
                <div style={{
                    width: '100%',
                    height: '100%',
                    marginLeft: '12%',
                }}>
                    <div style={{
                        width: '75%',
                        borderRadius: '5px',
                        // backgroundColor: '#fff',
                        textAlign: 'center',
                        marginTop: '6%',
                    }}>
                        <h4 style={{ textAlign: 'center', color: '#fff', fontWeight: '300px' }}>Creating Blogs with Code Genie Ai Agents. This could take a couple of minutes …</h4>
                        <br />
                        <LinearProgress color="primary" />
                    </div>
                </div>
            )}
            {/*  {isLoading && (
                <Grid container spacing={2} sx={{ marginTop: '2%' }}>
                    {!isMobile ? <Grid item xs={12} sm={2}>
                        &nbsp;
                    </Grid> : ''}
                    <Grid item xs={12} sm={8} sx={{ margin: isMobile ? '4%' : '' }}>
                        <Box sx={{ background: '#fff', padding: '10%', borderRadius: '10px' }}>
                            <Typography sx={{ fontWeight: 'Bolder', color: '#000', fontSize: 'large' }}>Creating Blogs with Code Genie Ai Agents. This could take a couple of minutes …</Typography>
                            <BorderLinearProgress variant="determinate" value={isLoading ? 50 : 100} sx={{ marginTop: '4%' }} />
                        </Box>
                    </Grid>
                    {!isMobile ? <Grid item xs={12} sm={2}>
                        &nbsp;
                    </Grid> : ''}
                </Grid>
            )} */}
            {/* section end */}
            <Modal
                open={openPaidModal}
                onClose={() => setOpenPaidModal(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : 600,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    <p id="child-modal-description">Sorry! you can Generate a blog only after paid.</p>
                    <Stack flexDirection="row">
                        <Box sx={{ display: "inline-block", margin: "0 10px" }}>
                            <Button variant="contained" onClick={() => navigate('/pricing')}>Go For Pricing</Button>
                        </Box>

                        <Button
                            onClick={() => {
                                // localStorage.setItem("localStorage_msg", JSON.stringify(localStorage_msg))
                                setOpenPaidModal(false);
                            }}
                            variant="contained"
                            sx={{ mr: 2 }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default Dashboard_tm;