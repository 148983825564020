import React, { useEffect, useState } from 'react';
import { useGetOrderSuccessQuery } from '../../redux/api/subscriptionApi';
import { Container, Stack, Typography, Button, Box } from '@mui/material';
import { useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { setModule } from '../../redux/features/genieSlice';
import { useSigninUserMutation } from '../../redux/api/authApi';
import { userApi } from '../../redux/api/userApi';
import Dashboard_tm from '../traffic_monster/dashboard_tm';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';



const ActivationSuccessPage = () => {
    const navigate = useNavigate();

    /*  const siteUrl = window.location.search;
     const urlParams = new URLSearchParams(siteUrl);
 
 
     const dispatch = useAppDispatch();
     const { data, error, isSuccess } = useGetOrderSuccessQuery({ "session_id": String(urlParams.get('session_id')) });
     const subscription_level = urlParams.get("subscription_level") || "";
 
     useEffect(() => {
         if (isSuccess) {
             localStorage.setItem("payment", "Success");
             localStorage.setItem("subscription_level", subscription_level);
             const fromUpgrade = localStorage.getItem('fromUpgrade');
             if (fromUpgrade === 'true') {
                 navigate('/codegenie/generated-blogs')
             } else {
                 navigate("/codegenie/dashboard");
             }
         }
     }, [isSuccess]);
 
     const getModule = urlParams.get("module");
 
     useEffect(() => {
         localStorage.removeItem('fromUpgrade');
     }, []) */


    return (
        <>
            <Container>
                <Stack height="100vh" justifyContent="center" alignItems="center">

                    {/* <Typography variant='h3'>Thankyou for Activated!
                        </Typography> */}
                    {/* <Modal
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                    > */}
                    <Box
                        sx={{
                            position: "absolute" as "absolute",
                            top: "50%",
                            left: "50%",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            transform: "translate(-50%, -50%)",
                            width: isMobile ? "95%" : 600,
                            bgcolor: "background.paper",
                            border: "2px solid #000",
                            boxShadow: 24,
                            pt: 2,
                            px: 4,
                            pb: 3,
                        }}
                    >
                        <Typography sx={{ fontSize: '25px' }}>ThankYou!</Typography>
                        <TaskAltIcon style={{ color: 'green', fontSize: '38px' }} />
                        <p id="child-modal-description" style={{ textAlign: 'center'/* , fontSize: 'xx-large', marginTop: '22px' */ }}>We have activated your plan.  Your blogs are being created.</p>
                        <Stack flexDirection="row">
                            <Button
                                onClick={() => {
                                    if (localStorage.getItem('blogsCount') === 'true') {
                                        navigate('/codegenie/generated-blogs');
                                    } else {
                                        navigate('/codegenie/dashboard');
                                    }
                                    window.location.reload();
                                }}
                                variant="contained"
                                sx={{ mr: 2 }}
                            >
                                Close
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </Container>

        </>)
}

export default ActivationSuccessPage;