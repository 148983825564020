import { useEffect, useState } from "react";
import {
    Container,
    Stack,
    Card,
    Typography,
    TextField,
    Box,
} from "@mui/material";
import Rating from 'rating-scale-with-rtl';
import { FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../redux/store";
import { useVerifySiteQuery } from "../redux/api/subscriptionApi";

const VerifySite = () => {
    const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);
    const dispatch = useAppDispatch();
    const campaignid = String(urlParams.get('campaignid')) || "";
    const userid = urlParams.get("userid") || "";
    const domain = urlParams.get("domain") || "";
    const { data, error, isSuccess } = useVerifySiteQuery({ "userid": userid, "campaignid":campaignid, "domain_recieved":domain });
    console.log('success page data=>', data);

    useEffect(() => {
        if (isSuccess) {
            
            console.log('success page data=>', data);
        }
    }, [isSuccess]);

  return (
    // JSX content of your component
    <div>
      {/* Component content */}
    </div>
  );
};

  
export default VerifySite;
  