import {
  Box,
  Button,
  Typography,
  Tooltip
} from "@mui/material";

import {
  useLazyGetProductQuery,
  useGetAddOnQuery,
} from "../redux/api/productApi";
import { FormEvent, useEffect, useState, useContext, useMemo, ChangeEvent, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Routes, Route, useLocation } from "react-router-dom";
import { IPlanDetail } from "../redux/api/types";

import "./pricing.page.css";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { setModule } from "../redux/features/genieSlice";
import { setPayment } from "../redux/features/paymentSlice";
import rating_img from "../assets/traffic_monster/rating_img.png";
import { useLazyCreateCheckoutSessionQuery } from "../redux/api/subscriptionApi";
import { getDesignTokens, ColorModeContext } from "../theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import logo_white from '../assets/logo_white.png';
import { isMobile } from "react-device-detect";
import img54 from '../assets/newimg/logo.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { usePlanDetailsMutation } from "../redux/api/productApi";
import { LoadingButton } from "@mui/lab";
import InfoIcon from '@mui/icons-material/Info';
import YouTubeIcon from '@mui/icons-material/YouTube';
import youtubeIco1 from '../assets/traffic_monster/youtubeIco_1.png';

// Define interface for referral event
interface ReferralEvent {
  detail: {
    referralId: string;
    // Add other properties if needed
  };
}

const PricingPage = () => {

  // Check if the user is logged in
  const isAuthenticated = localStorage.getItem("logged_in");

  const [mode, setMode] = useState<PaletteMode>("dark");
  const userRole = localStorage.getItem('userRole');
  //console.log('role of the user3=>' + userRole);
  // State to hold the text value
  const [couponCode, setCouponCode] = useState<string>('');



  // Function to handle text input change
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(event.target.value);
  };



  const colorMode1 = useMemo(
    () => ({

      toggleColorMode: () => {
        {
          userRole === 'admin' ?
            setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "light" : "light"
            ) : setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "dark" : "dark"
            )
        }
      },
    }),
    []
  );



  const theme1 = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const [getProduct, getState] = useLazyGetProductQuery();
  const [createCheckoutSession, checkoutState] = useLazyCreateCheckoutSessionQuery();
  const [period, getPeriodState] = useState("monthly");
  const [productModule, setProductModule] = useState("");
  const [additionalContract, setAdditionalContract] = useState(0);
  const [addon, setAddOn] = useState({
    plan_name: "",
    price: 0,
    price_id: "",
    product_id: "",
  });
  const [plans, setPlans] = useState<IPlanDetail[]>([
    {
      user: 1,
      plan_name: "",
      total_wishes: 0,
      price: "",
      period: "",
      product_id: "",
      price_id: "",
      subscription_level: "",
      feature: [],
      contracts: 0,
      type: "",
      button_text: "",
    },
  ]);
  const [referralInfo, setReferralInfo] = useState<ReferralEvent | null>(null);
  const [showRemaining, setShowRemaining] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const user = useAppSelector((state) => state.userState.user);
  const genieSelector = useAppSelector((state) => state.genieState);
  //const getAddOn = useGetAddOnQuery({});

  const [planDetails, setPlanDetails] = useState<any>();
  const [getuserPlanDetails] = usePlanDetailsMutation();

  const email = localStorage.getItem("user_email");

  const location = useLocation();
  const { campIdFromUpgrade } = location.state || {};

  const fetchPlanDatas = async () => {
    try {
      const response = await getuserPlanDetails({ email: email || '', campaignid: '' });

      if ('data' in response) {
        setPlanDetails(response.data.data);
        console.log('plan details=>', response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchPlanDatas();
  }, []);

  useEffect(() => {
    // Function to handle referral information
    const handleReferral = (event: CustomEvent<ReferralEvent>) => {
      const referralData = event.detail;
      setReferralInfo(referralData);
    };

    // Add event listener for 'rewardful.referral'
    window.addEventListener('rewardful.referral', handleReferral as EventListener);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('rewardful.referral', handleReferral as EventListener);
    };
  }, []);


  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/api/products/get-add-on`
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data.data);
        setAddOn(data.data);
      });
  }, [setAddOn]);

  useEffect(() => {

    const value = {
      _id: "",
      product_name: "CodeGenie",
      //product_module: genieSelector.module ? genieSelector.module : ""
      product_module: "Any Code",
      userid: "",
      productid: ""
    };
    getProduct(value);
  }, [genieSelector, getProduct]);

  useEffect(() => {

    const { data } = getState;
    if (data?.plan_details) {
      console.log(data?.plan_details);
      setPlans([...data.plan_details]);
      setProductModule(data.product_module);
    }
    else
      setPlans([
        {
          user: 1,
          plan_name: "",
          total_wishes: 0,
          price: "",
          period: "",
          product_id: "",
          price_id: "",
          subscription_level: "",
          feature: [],
          contracts: 0,
          type: "",
          button_text: "",
        },
      ]);
  }, [getState]);

  useEffect(() => {
    if (checkoutState.isSuccess) {
      window.location = checkoutState.data?.checkout_url;
      //window.open(checkoutState.data?.checkout_url, '_blank');
    }
  }, [checkoutState, navigate]);


  function handleSubmit(e: FormEvent<HTMLFormElement>, subscription_level: string) {
    e.preventDefault();

    if (subscription_level === "Free") {
      if (user?.email) {
        navigate("/codegenie/smart_contracts");
      } else {
        navigate("/signup");
      }
    } else {
      if (isAuthenticated != "true") {
        localStorage.setItem("fromPricing", "true");
        localStorage.setItem("subscription_level", subscription_level);
        let referral_param = ""
        if (referralInfo)
          referral_param = referralInfo.detail.referralId
        localStorage.setItem("referral_param", referral_param);
        localStorage.setItem("couponcode", couponCode);

        navigate('/signup');
      } else if (isAuthenticated == "true") {
        let email = localStorage.getItem("user_email");

        let referral_param = ""
        if (referralInfo)
          referral_param = referralInfo.detail.referralId
        const fromUpgrade = localStorage.getItem('fromUpgrade');
        const username = localStorage.getItem('user_name');
        createCheckoutSession({ "emailid": email || "", "username": username/* user?.name */ || "", "subscription_level": subscription_level, "referral_param": referral_param || "", "couponcode": couponCode, "mode": fromUpgrade === 'true' ? "upgrade" : '', campaignid: campIdFromUpgrade ? campIdFromUpgrade : '', "from_page": 'pricing' });
      }

    }

  }

  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    setAdditionalContract(Number(event.target.value));
  }

  function handlePayNow(e: FormEvent<HTMLFormElement>, subscription_level: string) {
    e.preventDefault();
    if (isAuthenticated != "true") {
      navigate('/signin');
    } else {
      let referral_param = ""
      if (referralInfo)
        referral_param = referralInfo.detail.referralId
      const fromUpgrade = localStorage.getItem('fromUpgrade');
      createCheckoutSession({ "emailid": user?.email || "", "username": user?.name || "", "subscription_level": subscription_level, "referral_param": referral_param || "", "couponcode": couponCode, "mode": fromUpgrade === 'true' ? "upgrade" : '', campaignid: '', "from_page": 'pricing' });
    }
  }

  const NormallyValArray = [period == "monthly" ? '' : 'Normally $49/mo.', period == "monthly" ? '' : 'Normally $99/mo.', period == "monthly" ? '' : 'Normally $199/mo.', period == "monthly" ? 'Normally $499/mo.' : 'Normally $499/mo.'];

  const handlePlanToggle = () => {
    setShowRemaining(!showRemaining);
  }

  const fromUpgrade = localStorage.getItem('fromUpgrade');

  return (
    <>

      {/* Header */}
      {localStorage.getItem('logged_in') === 'true' ? '' :
        <div className="container">
          <div className="row">
            <nav className="navbar navbar-expand-md">
              <div className="container-fluid">
                <a className="navbar-brand" href="#">
                  {/* <img src="./img/logo.png" alt="" /> */}
                  <img src={img54} className="img-fluid" alt="" />

                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarScroll"
                  aria-controls="navbarScroll"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="">
                    <i className="bi bi-list text-white" />
                  </span>
                </button>
                <div className="collapse navbar-collapse" id="navbarScroll">
                  <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                    <li className="nav-item">
                      <a
                        className="nav-link nav_active"
                        style={{ color: "#4ba5eb", fontWeight: 800 }}
                        href="/"
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => {
                      localStorage.setItem('pricing_from_landing', 'true');
                      navigate("/pricing");
                    }} style={{ cursor: 'pointer' }}>
                      <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                        Pricing
                      </a>
                    </li>
                    <li className="nav-item" /* onClick={() => {
                                        localStorage.setItem('pricing_from_landing', 'true');
                                        navigate("/pricing");    
                                    }} */ style={{ cursor: 'pointer' }}>
                      <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup">
                        Affiliates
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => {
                      navigate("/contactus");
                    }} style={{ cursor: 'pointer' }}>
                      <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                        Contact
                      </a>
                    </li>
                  </ul>
                  <div className="d-flex gap-4" role="search">
                    <button className="btn btn-outline-primary px-3"
                      onClick={() => {
                        navigate("/signin");
                      }}>Sign  In</button>
                    <button className="button_leniar px-3" type="submit" onClick={() => {
                      navigate("/signup");
                    }}>
                      {/* Try Now */}Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </nav>
            <hr className="mt-3" style={{ color: "#9aa0a3" }} />
          </div>
        </div>
      }
      {/*  */}

      <div className="priceContainer">
        {fromUpgrade && (
        <div className="row">
          <div className="col-md-6" style={{display: 'flex', alignItems: 'center', marginTop: isMobile ? '3%' : ''}}>
            <Tooltip title="Click here for help video">
              <img src={youtubeIco1} style={{ /* color: 'red', fontSize: '34px', marginLeft: '5%', background: '#fff', borderRadius: '49%', */ marginTop: isMobile ? '3%' : '' }} onClick={() => window.open("https://youtu.be/YhH8Env_Nms", '_blank')} />
            </Tooltip>
          </div>
          <div className="col-md-6">
            {/* {fromUpgrade ? */}
              <LoadingButton
                style={{
                  background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                  color: "white",
                  fontSize: 'large',
                  fontWeight: 'bold',
                  marginLeft: 'auto',
                  marginTop: '1%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '10px',
                }}
              >
                <div>Current Plan: {planDetails?.subscription_level === 'level_0' || planDetails?.subscription_level === 'level_0y' ? 'STARTER' : planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_4' ? 'BASIC' : planDetails?.subscription_level === 'level_2' || planDetails?.subscription_level === 'level_5' ? 'PRO' : planDetails?.subscription_level === 'level_3' || planDetails?.subscription_level === 'level_6' ? 'TRAFFIC HACKER' : ''}</div>
                <div style={{ textAlign: 'left', fontSize: '18px' }}>You can upgrade or downgrade to any plan.<br />New plan will start at your next billing period.</div>
              </LoadingButton> {/*  : ''}  */}
          </div>
        </div>)}
        <div className="price_head">



          {/* {isMobile && fromUpgrade ? <Button variant="outlined" className="HeadingBlogList" style={{ color: '#fff', fontWeight: 'lighter', width: isMobile ? '100%' : '20%', marginTop: '3%' }}><a style={{ textDecoration: 'none' }} href='https://youtu.be/y29AzqqbWRU' target="_blank"><svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ marginRight: '8px' }}
          >
            <polygon points="10 17 15 12 10 7 10 17"></polygon>
            <circle cx="12" cy="12" r="10"></circle>
          </svg><span style={{ color: '#fff' }}>Site Overview Video</span></a></Button> : ''} */}

          <div className="head_bottom" style={{ marginTop: fromUpgrade ? '0px' : '', width: '100%', display: fromUpgrade && !isMobile ? 'flex' : '', gap: fromUpgrade && !isMobile ? '13%' : '' }}>
            {/* {!isMobile && fromUpgrade ? <Button variant="outlined" className="HeadingBlogList" style={{ color: '#fff', fontWeight: 'lighter', width: isMobile ? '100%' : '20%',  }}><a style={{ textDecoration: 'none' }} href='https://youtu.be/y29AzqqbWRU' target="_blank"><svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ marginRight: '8px' }}
            >
              <polygon points="10 17 15 12 10 7 10 17"></polygon>
              <circle cx="12" cy="12" r="10"></circle>
            </svg><span style={{ color: '#fff' }}>Site Overview Video</span></a></Button> : ''} */}
            {fromUpgrade ? <div style={{ textAlign: 'center', marginTop: isMobile ? '3%' : '' }}>
              {/* <Tooltip title="Click here for help video">
              <InfoIcon sx={{ color: '#4BA5EB', fontSize: '34px' }} onClick={()=>window.open("https://youtu.be/YhH8Env_Nms", '_blank')}/>
              </Tooltip> */}
              <h1>Upgrade/Downgrade Plan</h1>
            </div> :
              <>
                <h1>Choose Your Plan And Start</h1>
                <h1>Your Free 7-Day Trial</h1>
              </>}
          </div>

          <div className="SaveUp" style={{ textAlign: isMobile ? 'center' : undefined }}>
            {/* {period == "monthly" ? */} "Save $2,998/year with the ‘Traffic Hacker’ plan, billed annually." {/* : "Save $5,468/year with the 'Traffic Hacker' plan"} */}
          </div>
        </div>


        <div className="billingBtn">
          <Box component="div" textAlign="center" sx={{ p: 2 }}>


            <Button
              size="large"
              //variant={period==="yearly"?"contained":"text"}
              sx={{
                // background: `${period === "yearly" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                color: `${period === "yearly" ? "white" : "inherit"}`,
                paddingX: 2,
                paddingY: 1,
                borderBottom: `${period === "yearly" ? "3px solid #fff" : "none"}`,
                borderRadius: '0px',
                fontSize: 'large'
              }}
              onClick={() => {
                getPeriodState("yearly")
              }}

            >
              Yearly Discount
            </Button>
            <Button
              size="large"
              //variant={period==="monthly"?"contained":"text"}
              sx={{
                // background: `${period === "monthly" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                color: `${period === "monthly" ? "white" : "inherit"}`,
                paddingX: 2,
                paddingY: 1,
                borderBottom: `${period === "monthly" ? "3px solid #fff" : "none"}`,
                borderRadius: '0px',
                fontSize: 'large'
              }}
              onClick={() => {
                getPeriodState("monthly");
              }}
            >
              Monthly billing
            </Button>

          </Box>
        </div>
        <button className="button_leniar px-3"
         /*  onClick={() => window.history.back()} */ style={{ fontSize: '13px', marginBottom: "10px", background: 'none' }}>{/* Go Back */}</button>
        {/* period == 'monthly' ? */ <>&nbsp;&nbsp;&nbsp;&nbsp;Redeem Coupon: <input className="px-3" style={{ marginBottom: '1%' }} type="text" value={couponCode} onChange={handleTextChange} name="couponcode" id="couponcode" ></input></> /* : '' */}

        {/* {!isMobile ? plans.filter((item) => item.period === period).length >= 2 &&
          <img src={rating_img} alt="" className="rightImage" /> : ''
        } */}
        <div className="price_table">
          {
            plans.filter((item) => item.period === period).map((plan, index) => (
              <div className="columns" key={`plan_item_${index}`}>
                {/* {isMobile ? index === 3 && plans.filter((item) => item.period === period).length >= 2 && (
                  <img src={rating_img} alt="" className="rightImage" />
                ) : ''} */}
                {plan.plan_name === "Developers" && <div className="ribbon ribbon-top-right"><span>Popular</span></div>}

                <ul className="price" style={{ boxShadow: plan.plan_name === 'TRAFFIC HACKER' ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '', border: '2px solid ' + (plan.plan_name === 'TRAFFIC HACKER' ? '#B2B4B9' : '#202634') }}>
                  <div style={{ height: '248px', background: plan.plan_name === 'TRAFFIC HACKER' ? '#626F86' : '', borderBottom: '2px solid #202634', borderTopLeftRadius: '22px', borderTopRightRadius: '22px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    {/* !isMobile ?  */index === 3 && plans.filter((item) => item.period === period).length >= 2 &&
                      <img src={rating_img} alt="" className="rightImage" /> /* : '' */
                    }
                    <li className="tableHeader">{plan.plan_name === 'STARTER' ? 'Starter' : plan.plan_name === 'BASIC' ? 'Basic' : plan.plan_name === 'PRO' ? 'Pro' : plan.plan_name === 'TRAFFIC HACKER' ? 'Traffic Hacker' : ''}</li>
                    <li style={{ textAlign: 'center', textDecorationLine: 'line-through' }}>{NormallyValArray[index]}</li>
                    <li className="planPrice"><b>$ {index === 2 && period == "monthly" ? Number(plan.price).toLocaleString() + `/Month (save $200/month)` : Number(plan.price).toLocaleString() + `/Month`}</b></li>
                    <li className="planPrice">{(plan.period === "yearly" && plan.price == "48.8") && "Billed annually (Save $2.4/year)"}{(plan.period === "yearly" && plan.price == "82.5") && "Billed annually (Save $198/year)"}{(plan.period === "yearly" && plan.price == "165.8") && "Billed annually (Save $398/year)"}{(plan.period === "yearly" && plan.price == "249.16") && "Billed annually (Save $2,998/year)"}</li>
                    {/* {plan.user && <li className="tickli">User: <b>{plan.user}</b></li>} */}
                    {/* <li className="tickli">{productModule === "SMART CONTRACTS" ? "Audits" : "Wishes"}: <b>{plan.total_wishes}</b></li> */}

                    <form method="POST" onSubmit={(event) => handleSubmit(event, plan.subscription_level)}>
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em', padding: '2%' }}>
                        <Button
                          className="button"
                          size="large"
                          type="submit"
                          sx={{
                            // marginLeft: "20px",
                            background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                            color: "white",
                            paddingX: 2,
                            paddingY: 1,
                            '&:hover': {
                              background: "linear-gradient(270deg, #0168B5 0%, #4BA5EB 100%)",
                            },
                            width: '70%'
                          }}
                          endIcon={<ArrowForwardIcon sx={{ color: 'white' }} />}
                        >
                          {plan.plan_name === "Free" && "Sign in"}
                          {(plan.plan_name === "Individual" || plan.plan_name === "Startups") && "Pay Once"}
                          {fromUpgrade ? 'Upgrade' : (plan.plan_name !== "Individual" && plan.plan_name !== "Startups" && plan.plan_name !== "Free") && plan.button_text}
                        </Button>
                      </div>
                    </form>
                  </div>
                  <br />
                  {/* <hr></hr> */}
                  {plan.feature/* .slice(0, 10) */.map((features, index) => (
                    <>
                      <li className={features == '-' ? 'notickli' : 'tickli'
                      } dangerouslySetInnerHTML={{ __html: features == '-' ? '&nbsp;' : features }} />
                      {/* {index === 9 ? (
                        plan.plan_name === 'TRAFFIC HACKER' ? (
                          <Typography className='tickli'
                            style={{ fontWeight: 'bold', fontSize: '16px', cursor: 'pointer' }}
                            onClick={handlePlanToggle}
                          >
                            Create and Manage Campaigns <ArrowDropDownIcon />
                          </Typography>
                        ) : (
                          <Typography className='notickli'
                            style={{ marginLeft: '7%', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer' }}
                          >
                            &nbsp;
                          </Typography>
                        )
                      ) : null} */}

                    </>
                  ))}
                  {showRemaining && plan.feature.slice(10).map((features, index) => (
                    <li
                      key={index + 9} // Adjust key to avoid duplicate keys
                      className={features === '-' ? 'notickli' : 'tickli'}
                      dangerouslySetInnerHTML={{ __html: features === '-' ? '&nbsp;' : features }}
                    />
                  ))}
                  {/* <li style={{ fontWeight: 'bolder', fontSize: 'large', textAlign: 'center' }}>Limited Time Offer</li>
                  <li style={{ fontWeight: 'bolder', fontSize: 'large', textAlign: 'center' }}>Free AI Introduction eBook</li> */}
                  <li className="sub_button">
                    <form method="POST" onSubmit={(event) => handleSubmit(event, plan.subscription_level)}>
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em', marginTop: "10px" }}>
                        <Button
                          className="button"
                          size="large"
                          type="submit"
                          sx={{
                            width: "70%",
                            // marginLeft: "25px",
                            background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                            color: "white",
                            paddingX: 2,
                            paddingY: 1,
                          }}
                          style={{ color: 'white' }}
                          endIcon={<ArrowForwardIcon sx={{ color: 'white' }} />}
                        >
                          {plan.plan_name === "Free" && "Sign in"}
                          {(plan.plan_name === "Individual" || plan.plan_name === "Startups") && "Pay Once"}
                          {fromUpgrade ? 'Upgrade' : (plan.plan_name !== "Individual" && plan.plan_name !== "Startups" && plan.plan_name !== "Free") && plan.button_text}
                        </Button>
                      </div>
                    </form>
                    {/*<Button
                      className="button"
                      size="large"
                      fullWidth
                      sx={{
                        background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                        color: "white",
                        paddingX: 2,
                        paddingY: 1,
                      }}
                      onClick={() => {
                        dispatch(setPayment({'price_id': plan.price_id,'product_id': plan.product_id}));
                        navigate("/checkout");
                      }}
                    >
                    Subscribe
                    </Button>*/}

                  </li>
                </ul>
              </div>

            ))
          }
        </div>


        {/*
        <div className="pricing_botton">
            <div className="pricing_botton_left">
                <h2>Want to try our product </h2>
                <a onClick={() => {
                  localStorage.setItem("module", "SMART CONTRACTS");
                  dispatch(setModule("SMART CONTRACTS"));
                  navigate("/codegenie/smart_contracts");
                }} 
                className="button whiteButton">Try for free</a>
            </div>

            <div className="pricing_botton_right">
                <div className="head">
                    <h2>Add-ons</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the
                        industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                        type
                        and scrambled it to make a type specimen book.
                    </p>
                </div>

                <div className="addonCards">
                    <div className="addoncard">
                        <h2>Extra wishes credits</h2>
                        <p>$5 per 15 wish credit/month</p>
                        <div>I want <input type="number" className="addon_count_input"/> extra wishes credits
                        </div>
                        <h3>Your Total:$5/month</h3>
                        <a href="#" className="button">Get Add-on</a>
                    </div>

                    <div className="addoncard">
                        <h2>Extra wishes credits</h2>
                        <p>$5 per 15 wish credit/month</p>
                        <div>I want <input type="number" className="addon_count_input"/> extra wishes credits
                        </div>
                        <h3>Your Total:$5/month</h3>
                        <a href="#" className="button">Get Add-on</a>
                    </div>

                </div>


            </div>

        </div>*/}

        {/* {(localStorage.getItem("logged_in") == "true") && (
          <div className="pricing_botton">
            <div className="pricing_botton_right">
              <div className="head">
                <h2>1-OFF AUDITS WITH FIX CODE</h2>
                <p>Start up plan can audit additional contracts as one off payments below.
                </p>
              </div>


              <div className="addonCards">
                <div className="addoncard">
                  <h2>Add More Contracts</h2>
                  <p>${(addon.price).toLocaleString()} per contract</p>
                  <div>I want <input type="number" className="addon_count_input" onChange={(event) => handleOnChange(event)} /> extra contracts
                  </div>
                  <h3>Your Total:${(additionalContract * addon.price).toLocaleString()} 1-time payment</h3>
                  <form method="POST" onSubmit={(event) => handlePayNow(event, "add-on")}>
                    <Button
                      className="button"
                      size="large"
                      fullWidth
                      type="submit"
                      sx={{
                        background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                        color: "4BA5EB",
                        paddingX: 2,
                        paddingY: 1,
                      }}
                    >
                      Pay now
                    </Button>
                  </form>
                </div>
              </div>

            </div>

          </div>
        )} */}
        {/* <div className="HaveQues1" style={{ marginTop: isMobile ? '10%' : '' }}>
          <div>
            <p className="HaveQues1Container">Have Questions About Pricing?</p>
          </div>
        </div>
        <div className="HaveQues">
          <h2> <p>Frequently Asked Questions</p></h2>

        </div>

        <div className="ContactSupport">
          <Button
            className="button_leniar px-3" sx={{ color: 'white' }}
            endIcon={<ArrowForwardIcon sx={{ color: 'white' }} />} onClick={()=>navigate('/contactus')}>
            Contact Support
          </Button>
        </div>

        <div className="FrequentlyAsked col-md-8 py-sm-5 py-3">
          <div>
            <div className="accordion" id="regularAccordionRobots">
              <div className="Frequentlylist rounded-1 mb-2">
                <h2 id="regularHeadingFirst" className="accordion-header">
                  <button
                    className="accordion-button accordion_style px-3"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapseFirst"
                    aria-expanded="true"
                    aria-controls="regularCollapseFirst"
                  >
                    is there a 30-days money back guarantee
                  </button>
                </h2>
                <div
                  id="regularCollapseFirst"
                  className="accordion-collapse collapse show"
                  aria-labelledby="regularHeadingFirst"
                  data-bs-parent="#regularAccordionRobots"
                >
                  <div className="accordion-body">
                    Content
                  </div>
                </div>
              </div>

              <div className="Frequentlylist rounded-1 mb-2">
                <h2 className="accordion-header" id="regularHeadingThird">
                  <button
                    className="accordion-button accordion_style collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapseThird"
                    aria-expanded="false"
                    aria-controls="regularCollapseThird"
                  >
                    Can I change my plan later on?
                  </button>
                </h2>
                <div
                  id="regularCollapseThird"
                  className="accordion-collapse collapse"
                  aria-labelledby="regularHeadingThird"
                  data-bs-parent="#regularAccordionRobots"
                >
                  <div className="accordion-body">
                    content
                  </div>
                </div>
              </div>
              <div className=" Frequentlylist rounded-1 mb-2">
                <h2 className="accordion-header" id="regularHeading4">
                  <button
                    className="accordion-button accordion_style collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapse4"
                    aria-expanded="false"
                    aria-controls="regularCollapse4"
                  >
                    Can I use a custom domain?
                  </button>
                </h2>
                <div
                  id="regularCollapse4"
                  className="accordion-collapse collapse"
                  aria-labelledby="regularHeading4"
                  data-bs-parent="#regularAccordionRobots"
                >
                  <div className="accordion-body">
                    Yes.You can use your custom domain when you have "Traffic Monster" Plan.
                  </div>
                </div>
              </div>
              <div className="Frequentlylist rounded-1 mb-2">
                <h2 className="accordion-header" id="regularHeading5">
                  <button
                    className="accordion-button accordion_style collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapse5"
                    aria-expanded="false"
                    aria-controls="regularCollapse5"
                  >
                    What are Ai Agents?
                  </button>
                </h2>
                <div
                  id="regularCollapse5"
                  className="accordion-collapse collapse"
                  aria-labelledby="regularHeading5"
                  data-bs-parent="#regularAccordionRobots"
                >
                  <div className="accordion-body">
                    content
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr></hr> */}
      </div>

      {/* Footer section */}
      <div className="py-5 mx-sm-0 mx-2">
        <div className="container">
          <div className="row px-sm-0 px-2">
            <div className="col-lg-3 text-white text-lg-start text-center">
              <div>
                <div>
                  <Typography style={{ color: '#fff', fontWeight: 'bold', fontSize: 22 }}><img src={logo_white} alt="" />CodeGenie</Typography>

                </div>
                <p /* className="fw_samibold" */ style={{ fontSize: 14, fontWeight: 'lighter' }}>
                  {/* Mr John Smith. 132, My Street, Kingston, New York 12401. */}
                </p>
              </div>
            </div>

            <div className="col-lg-2 col-sm-4">
              &nbsp;
            </div>
            <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
              &nbsp;
            </div>
            <div className="col-lg-5 col-sm-4  text-lg-start text-sm-end" style={{ display: 'flex', justifyContent: 'right' }}>
              Copyright © 2024 Code-Genie.ai | All Rights Reserved.
            </div>

          </div>
        </div>
      </div>
      {/* footer section end*/}


      <p></p>
      <p></p>
    </>
  );
};
export default PricingPage;