import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Container,
  Stack,
  Card,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import { object, string, TypeOf } from "zod";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useLocation } from "react-router-dom";
import { useVerifyEmailMutation, useSigninUserMutation } from "../../redux/api/authApi";
import { useAppDispatch } from "../../redux/store";
import { setModule } from "../../redux/features/genieSlice";
import { useLazyCreateCheckoutSessionQuery } from "../../redux/api/subscriptionApi";

const verifySchema = object({
  code: string().min(1, "Verification code is required"),
});

export type VerifyInput = TypeOf<typeof verifySchema>;

const VerifyPage = (props: { page: any; setPassCallback: any }) => {
  const { page, setPassCallback } = props;
  const [verifyEmail, verifyState] = useVerifyEmailMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [signinUser, signinState] = useSigninUserMutation();
  const [createCheckoutSession, checkoutState] = useLazyCreateCheckoutSessionQuery();

  const location = useLocation();
  const { appsumo_coupon_code } = location.state || {};

  console.log('code=>', appsumo_coupon_code);

  const methods = useForm<VerifyInput>({
    resolver: zodResolver(verifySchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<VerifyInput> = (values) => {
    verifyEmail(values);
  };

  const module = localStorage.getItem("module");


  const handleSigninSuccess = (signinState: any) => {

    if (signinState.isSuccess) {
      localStorage.clear();

      localStorage.setItem("logged_in", "true");
      localStorage.setItem("free_plan", signinState.data.free_plan.toString());
      localStorage.setItem("remaining_wish_count", signinState.data.remaining_wish_count.toString());
      const subscriptionLevel = signinState.data.subscription_level || '';
      localStorage.setItem("subscription_level", subscriptionLevel);
      localStorage.setItem("user_email", signinState.data.user_email || '');
      localStorage.setItem('userRole', signinState.data.role);
      localStorage.setItem('payment', '');
      alert('test....2' + signinState.data.user_email);
      navigate("/pricing");
    }

  };


  useEffect(() => {
    handleSigninSuccess(signinState);
  }, [signinState]);

  const handleCheckout = async () => {
    localStorage.removeItem("fromPricing");
    let subscription_level = localStorage.getItem("subscription_level");
    // localStorage.removeItem("subscription_level");
    let referral_param = localStorage.getItem("referral_param");
    localStorage.removeItem("referral_param");
    let couponcode = localStorage.getItem("couponcode");
    localStorage.removeItem("couponcode");
    let email = localStorage.getItem("user_email");

    try {
      const response = await createCheckoutSession({ "emailid": email || "", "username": "", "subscription_level": subscription_level ?? "", "referral_param": referral_param || "", "couponcode": couponcode ?? "", "mode": '', campaignid: '', from_page: '' });

      if (response.data.message == "Checkout url created") {
        // Assuming response contains the checkout URL
        const checkoutUrl = response.data.checkout_url;

        // Redirect to the Stripe checkout page
        window.location.href = checkoutUrl;
      }
      else {
        toast.error("Something went wrong, check your coupon code.");
      }

    }
    catch (e) {
      alert(e);
      console.log(e);
    }
  }

  useEffect(() => {


    let fromPricing = localStorage.getItem("fromPricing");
    if (fromPricing == "true" && verifyState.isSuccess == true) {
      toast.success("Verified successfully");
      handleCheckout();

    }
    else if (page === "forgotPass" && fromPricing != "true") {
      if (verifyState.isSuccess) {
        toast.success("Verified successfully");
        setPassCallback(true);
      }
      if (verifyState.isError) {
        if (Array.isArray((verifyState.error as any).data.detail)) {
          (verifyState.error as any).data.detail.map((el: any) =>
            toast.error(`${el.loc[1]} ${el.msg}`)
          );
        } else toast.error((verifyState.error as any).data.detail);
        setPassCallback(false);
      }
    }

    if (page === "signin" && fromPricing != "true") {
      if (module == "SMART CONTRACTS") {
        if (verifyState.isSuccess) {
          toast.success("Verified successfully");
          localStorage.setItem("module", "SMART CONTRACTS");
          let email = localStorage.getItem("user_email");
          let pass = localStorage.getItem("user_pass");
          signinUser({ email: email ?? '', password: pass ?? '' });
          dispatch(setModule("SMART CONTRACTS"));
          // navigate("/codegenie/smart_contracts");
            navigate("/pricing");
        }
      }
      else if (module == "Any Code") {
        if (verifyState.isSuccess) {
          toast.success("Verified successfully");
          localStorage.setItem("module", "Any Code");
          dispatch(setModule("Any Code"));
          let email = localStorage.getItem("user_email");
          let pass = localStorage.getItem("user_pass");
          signinUser({ email: email ?? '', password: pass ?? '' });
          if (appsumo_coupon_code) {
            navigate("/codegenie/dashboard");
          } else {
            navigate("/pricing");
          }
          //navigate("/codegenie/any_code");
        }
      }
      else {
        if (verifyState.isSuccess) {
          toast.success("Verified successfully");
          localStorage.setItem("module", "SMART CONTRACTS");
          let email = localStorage.getItem("user_email");
          let pass = localStorage.getItem("user_pass");
          signinUser({ email: email ?? '', password: pass ?? '' });
          dispatch(setModule("SMART CONTRACTS"));
          // navigate("/codegenie/smart_contracts");
          if (appsumo_coupon_code) {
            navigate("/codegenie/dashboard");
          } else {
            navigate("/pricing");
          }
          // navigate("/signin");

        }
        else if (verifyState.isError) {
          /* if (Array.isArray((verifyState.error as any).data.detail)) {
            (verifyState.error as any).data.detail.map((el: any) =>
              toast.error(`${el.loc[1]} ${el.msg}`)
            );
          } else toast.error((verifyState.error as any).data.detail); */
          if (verifyState.error && typeof verifyState.error === 'object') {
            if ((verifyState.error as any).data && (verifyState.error as any).data.detail) {
              if (Array.isArray((verifyState.error as any).data.detail)) {
                (verifyState.error as any).data.detail.map((el: any) =>
                  toast.error(`${el.loc[1]} ${el.msg}`)
                );
              } else {
                toast.error((verifyState.error as any).data.detail);
              }
            } else {
              toast.error("Unexpected code format", verifyState.error as any);
            }
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyState]);

  return (

    <>
      <Container>
        <Stack height="100vh" justifyContent="center" alignItems="center">
          <FormProvider {...methods}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <Card sx={{ padding: 6 }}>
                <Typography variant="h4" mb={2}>
                  Verification Code
                </Typography>
                <TextField
                  {...register("code")}
                  fullWidth
                  size="small"
                  placeholder="Verify code"
                  error={!!errors["code"]}
                  helperText={errors["code"]?.message}
                />
                <Typography mb={2}>
                  Please check your email for the verification code.<br />Copy and paste the code here.
                </Typography>
                <LoadingButton
                  fullWidth
                  type="submit"
                  sx={{ mt: 2 }}
                  variant="contained"
                  loading={verifyState.isLoading}
                >
                  {/* Send */}Verify
                </LoadingButton>
              </Card>
            </Box>
          </FormProvider>
        </Stack>
      </Container>
    </>
  );
};

export default VerifyPage;
