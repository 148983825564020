import {
  Container,
  MenuItem,
  SvgIcon,
  Grid,
  Paper,
  TablePagination,
  Divider,
  Select
} from "@mui/material";
import { KeyboardBackspace, KeyboardArrowLeft, KeyboardArrowRight, SettingsPowerTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import * as React from 'react';
import { useCallback } from 'react';
import { ReactComponent as CalendarIcon } from "../../assets/ico_calendar.svg";
import Autocomplete from '@mui/material/Autocomplete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { IUserDetails, ITotalUsers, ITotalUsersIncrease, ITotalRevenue, RevenueIncreasePercentage, TotalGoogleVisit, TotalGoogleIncreasePercentage, TotalUsers, TotalRevenueSum } from "../../redux/api/types";
import { useGetTotalUsersQuery, useGetTotalUsersIncreaseQuery, useGetTotalRevenueQuery, useGetRevenueIncreasePercentageQuery, useGetTotalGoogleVisitQuery, useGetTotalGoogleIncreasePercentageQuery, useGetTotalCountUsersQuery, useGetTotalRevenueSumQuery } from "../../redux/api/paymentApi";
import { useActiveUserMutation, useDeactiveUserMutation, useUsersListMutation } from "../../redux/api/authApi";
import { isBrowser, isMobile, MobileView, BrowserView } from "react-device-detect";
import { useState, useEffect } from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { IProductHeadings } from "../../redux/api/types";
import { useLazySearchProductQuery } from "../../redux/api/productApi";
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
import { ToastContainer, toast } from "react-toastify";
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';
import {
  startOfDay,
  endOfDay,
  subDays,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  format,
} from 'date-fns';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import Tooltip from "@mui/material/Tooltip";


const DashboardDemo = () => {
  const customInputStyle = {
    color: 'Black',
    border: '1px solid #CBCBCB',
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: '#1377C3', 
      color: 'lightblack',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'white', // Set background color for odd rows to white
      color: 'black', // Set text color for odd rows to black
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#F2F6FA', // Set background color for even rows to gray
      color: 'darkslategray',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const StyledButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
  }));
  const StyledDay = styled(PickersDay)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.dark
        : theme.palette.primary.light,
  }));


  const [userlists, setuserlists] = useState<IUserDetails[] | undefined>(undefined);
  const [userRecords, setUserRecords] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  // const pageSize = isMobile ? 5 : 10;
  const [pageSize, setPageSize] = useState(isMobile ? 5 : 10);
  const [searchText, setSearchText] = useState('');



  //predefined ranges
  /*  const [selectedRange, setSelectedRange] = useState<string>('thisWeek');
   const [dateRange, setDateRange] = useState<Date[] | null>(getDateRange(selectedRange)); */
  const [selectedRange, setSelectedRange] = useState<string>('thisWeek');
  const [dateRange, setDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  });

  const [selectedPrevRange, setSelectedPrevRange] = useState('previous_week');

  const [UsersListMutation] = useUsersListMutation();
  console.log(UsersListMutation);
  const [ActivateUserMutation] = useActiveUserMutation();
  const [DeactivateUserMutation] = useDeactiveUserMutation();

  const handleActivateUser = useCallback(async (email: string, iindex: number, uuserlist: IUserDetails[]) => {
    try {

      const isConfirmed = window.confirm('Are you sure you want to activate this user?');
      // If the user confirms, proceed with activation
      if (isConfirmed) {
        const response = await ActivateUserMutation({ email });
        if ('data' in response) {
          const data = response.data;
          if (data.status === 'success') {
            toast.success('User activated successfully');
            // After successful activation, update userlists
            const updatedUserList = uuserlist.map((user, index) =>
              index === iindex ? { ...user, status: 'active' } : user
            );
            console.log('After update:', updatedUserList);
            setuserlists(updatedUserList);
          } else {
            toast.error('Failed to activate user');
          }
        } else {
          toast.error('Failed to activate user');
        }
      }
    } catch (error) {
      console.error('Error activating user', error);
      toast.error('An error occurred while activating the user');
    }
  }, [ActivateUserMutation]);

  const handleDeactivateUser = useCallback(async (email: string, iindex: number, uuserlist: IUserDetails[]) => {
    try {

      // Display a confirmation dialog
      const isConfirmed = window.confirm('Are you sure you want to deactivate this user?');
      // If the user confirms, proceed with activation
      if (isConfirmed) {
        const response = await DeactivateUserMutation({ email });
        console.log('deactivated user=>', data);
        if ('data' in response) {
          const data = response.data;
          if (data.status === 'success') {
            toast.success('Deactivated successfully');
            // After successful activation, update userlists
            const updatedUserList = uuserlist.map((user, index) =>
              index === iindex ? { ...user, status: 'inactive' } : user
            );
            console.log('After update:', updatedUserList);
            setuserlists(updatedUserList);
          }
          else {
            toast.error('Failed to deactivate user');
          }
        } else {
          toast.error('Failed to deactivate user');
        }

      }

    } catch (error) {
      console.error('Error activating user', error);
      toast.error('An error occurred while activating the user');
    }

  }, [DeactivateUserMutation]);

  /* useEffect(() => {
    setuserlists(data?.userdet);
  }, [data]) */

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const [customerFromDate, setCustomerFromDate] = useState('');
  const [customerToDate, setCustomerToDate] = useState('');
  const [options, setOptions] = useState<IProductHeadings[]>([]);
  const [searchProduct, searchState] = useLazySearchProductQuery();
  const [filter, setFilter] = useState<IProductHeadings | null>(null);


  useEffect(() => {
    searchProduct("");
  }, []);


  const handlePrevRangeChange = (event: React.ChangeEvent<{ value: string }>) => {
    const newMode: string = event.target.value;
    setSelectedPrevRange(newMode);
  }

  const currentWeekStart = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd');
  const currentWeekEnd = format(endOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd');

  const [productVal, setproductVal] = useState('');
  const [totalusers, setTotalUsers] = useState<ITotalUsers | undefined>(undefined);
  const [totalusersincrease, setTotalUsersIncrease] = useState<ITotalUsersIncrease | undefined>(undefined);
  const [totalRevenue, setTotalRevenue] = useState<ITotalRevenue | undefined>(undefined);
  const [totalCountusers, setTotalCountUsers] = useState<TotalUsers | undefined>(undefined);
  const [totalRevenueSum, setTotalRevenueSum,] = useState<TotalRevenueSum | undefined>(undefined);
  const [revenueincreasepercentage, setRevenueIncreasePercentage] = useState<RevenueIncreasePercentage | undefined>(undefined);
  const [totalgooglevisit, setTotalGoogleVisit] = useState<TotalGoogleVisit | undefined>(undefined);
  const [totalgoogleincreasepercentage, setTotalGoogleIncreasePercentage] = useState<TotalGoogleIncreasePercentage | undefined>(undefined);

  const { data, isSuccess, isError } = useGetTotalUsersQuery({ from_date: /* '2023-10-27' */fromDate ? format(new Date(fromDate), 'yyyy-MM-dd') : dateRange.startDate ? dateRange.startDate : currentWeekStart, to_date: /* '2023-11-02' */toDate ? format(new Date(toDate), 'yyyy-MM-dd') : dateRange.endDate ? dateRange.endDate : currentWeekEnd, mode: selectedPrevRange, product: productVal });
  const userincreasedata = useGetTotalUsersIncreaseQuery({ from_date: fromDate ? format(new Date(fromDate), 'yyyy-MM-dd') : dateRange.startDate ? dateRange.startDate : currentWeekStart, to_date: toDate ? format(new Date(toDate), 'yyyy-MM-dd') : dateRange.endDate ? dateRange.endDate : currentWeekEnd, mode: selectedPrevRange, product: productVal });
  const revenuedata = useGetTotalRevenueQuery({ from_date: fromDate ? format(new Date(fromDate), 'yyyy-MM-dd') : dateRange.startDate ? dateRange.startDate : currentWeekStart, to_date: toDate ? format(new Date(toDate), 'yyyy-MM-dd') : dateRange.endDate ? dateRange.endDate : currentWeekEnd, mode: selectedPrevRange, product: productVal });
  const totalUsersCount = useGetTotalCountUsersQuery({});
  const totalRevenueSumCount = useGetTotalRevenueSumQuery({});
  const revenueincreasedata = useGetRevenueIncreasePercentageQuery({ from_date: fromDate ? format(new Date(fromDate), 'yyyy-MM-dd') : dateRange.startDate ? dateRange.startDate : currentWeekStart, to_date: toDate ? format(new Date(toDate), 'yyyy-MM-dd') : dateRange.endDate ? dateRange.endDate : currentWeekEnd, mode: selectedPrevRange, product: productVal });
  const totalgoogledata = useGetTotalGoogleVisitQuery({ from_date: fromDate ? format(new Date(fromDate), 'yyyy-MM-dd') : dateRange.startDate ? dateRange.startDate : currentWeekStart, to_date: toDate ? format(new Date(toDate), 'yyyy-MM-dd') : dateRange.endDate ? dateRange.endDate : currentWeekEnd, mode: selectedPrevRange, product: productVal });
  const totalgooglepercentagedata = useGetTotalGoogleIncreasePercentageQuery({ from_date: fromDate ? format(new Date(fromDate), 'yyyy-MM-dd') : dateRange.startDate ? dateRange.startDate : currentWeekStart, to_date: toDate ? format(new Date(toDate), 'yyyy-MM-dd') : dateRange.endDate ? dateRange.endDate : currentWeekEnd, mode: selectedPrevRange, product: productVal });


  useEffect(() => {
    if (searchState.data) {
      setOptions(searchState.data);
    }
  }, [searchState]);

  useEffect(() => {
    const formattedDateRange = getDateRange(selectedRange);
    setDateRange(formattedDateRange);
    console.log(formattedDateRange.startDate);
    console.log(formattedDateRange.endDate);
  }, [selectedRange]);

  const handleRangeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newRange = event.target.value as string;
    setSelectedRange(newRange);
  }


  function getDateRange(range: string): { startDate: string; endDate: string } {
    const currentDate = new Date();
    let startDate: Date;
    let endDate: Date;

    switch (range) {
      case 'today':
        startDate = startOfDay(currentDate);
        endDate = endOfDay(currentDate);
        break;
      case 'yesterday':
        const yesterday = subDays(currentDate, 1);
        startDate = startOfDay(yesterday);
        endDate = endOfDay(yesterday);
        break;
      case 'last7Days':
        const last7DaysStartDate = subDays(currentDate, 7);
        startDate = startOfDay(last7DaysStartDate);
        endDate = endOfDay(currentDate);
        break;
      case 'last30Days':
        const last30DaysStartDate = subDays(currentDate, 30);
        startDate = startOfDay(last30DaysStartDate);
        endDate = endOfDay(currentDate);
        break;
      case 'thisMonth':
        startDate = startOfMonth(currentDate);
        endDate = endOfMonth(currentDate);
        break;
      case 'lastMonth':
        const lastMonthStartDate = startOfMonth(subMonths(currentDate, 1));
        const lastMonthEndDate = endOfMonth(subMonths(currentDate, 1));
        startDate = lastMonthStartDate;
        endDate = lastMonthEndDate;
        break;
      case 'thisWeek':
        const thisWeekStartDate = startOfWeek(currentDate);
        const thisWeekEndDate = endOfWeek(currentDate);
        startDate = thisWeekStartDate;
        endDate = thisWeekEndDate;
        break;
      default:
        startDate = new Date(); // You can provide a default value here
        endDate = new Date(); // You can provide a default value here
        break;
    }

    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  }



  const handleFromDateChange = (date: any) => {
    console.log(date);
    setFromDate(date);
  };

  const handleToDateChange = (date: any) => {
    console.log(date);
    setToDate(date);
  };


  const handlecustoFromDate = (date: any) => {
    setCustomerFromDate(date);
  }

  const handlecustoToDate = (date: any) => {
    setCustomerToDate(date);
  }

  const handleProductChange = (
    event: React.ChangeEvent<{}>,
    value: IProductHeadings | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<{}> | undefined
  ) => {
    if (value) {
      // Assuming IProductHeadings has a property like 'product_module'
      setproductVal(value.product_module);
    } else {
      // Handle the case where value is null
      setproductVal('');
    }
  };

  useEffect(() => {
    console.log(userlists);
    console.log(userRecords);
    setTotalUsers(data as ITotalUsers | undefined);
    setTotalUsersIncrease(userincreasedata.data as ITotalUsersIncrease | undefined);
    setTotalRevenue(revenuedata.data as ITotalRevenue | undefined);
    setTotalCountUsers(totalUsersCount.data as TotalUsers | undefined);
    setTotalRevenueSum(totalRevenueSumCount.data as TotalRevenueSum | undefined);
    setRevenueIncreasePercentage(revenueincreasedata.data as RevenueIncreasePercentage | undefined);
    setTotalGoogleVisit(totalgoogledata.data as TotalGoogleVisit | undefined);
    setTotalGoogleIncreasePercentage(totalgooglepercentagedata.data as TotalGoogleIncreasePercentage | undefined);
  }, [userlists, userRecords, data, userincreasedata, revenuedata, revenueincreasedata, totalgoogledata, totalgooglepercentagedata])


  useEffect(() => {
    const fetchdata = async () => {
      try {
        const response: any = await UsersListMutation({
          skip: (currentPage - 1) * pageSize,
          limit: pageSize,
          from_date: customerFromDate ? format(new Date(customerFromDate), 'yyyy-MM-dd') : '',
          to_date: customerToDate ? format(new Date(customerToDate), 'yyyy-MM-dd') : '',
          search_str: searchText
        });
        const userValues = response.data.userdet;
        setuserlists(userValues);

        const userRec = response.data;
        setUserRecords(userRec.totalrecords);

      } catch (error) {
        console.error('Error generating blog:', error);

      }
    }
    fetchdata();
  }, [currentPage, pageSize, customerFromDate, customerToDate, searchText])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  /*commented by sara*/
  // useEffect(() => {
  //   if (userlists) {
  //     const userhistory = userlists.map((users, index) => ({
  //       ...users,
  //       serialNumber: (currentPage - 1) * pageSize + index + 1,
  //     }));
  //     setuserlists(userhistory);
  //   }
  // }, [userlists]);
  /*commented by sara*/


  // Pagination
  let totalPages = Math.ceil(parseInt(userRecords) / pageSize);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const generatePageNumbers = (totalPages: number, currentPage: number): (number | '...')[] => {
    const pageNumbers: (number | '...')[] = [];
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) < 3) {
        pageNumbers.push(i);
      } else if (pageNumbers[pageNumbers.length - 1] !== '...') {
        pageNumbers.push('...');
      }
    }
    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers(totalPages, currentPage);
  //


  // Filter the userlists based on the searchText
  /* const filteredUsers = userlists ? userlists.filter(user => {
    const search = searchText.toLowerCase();
    return user.name.toLowerCase().includes(search) ||
      user.email.toLowerCase().includes(search) ||
      user.initial_product.toLowerCase().includes(search);
  }) : []; */




  return (
    <>

      <div className="body-17">

        <Container sx={{ paddingTop: 5 }} maxWidth="lg">
          <Stack gap={9}>
            <Stack gap={3}>
              <Typography style={{ color: 'black' }} variant="h5">Your Overview</Typography>
              <Stack padding={1}
                sx={{
                  // gap: 7,

                  '@media (min-width: 600px)': {
                    // Adjust gap for screens with a minimum width of 600px (sm)
                    flexDirection: "column",

                  },
                  '@media (min-width: 960px)': {
                    // Adjust gap for screens with a minimum width of 960px (md)
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: 'space-between'
                  },
                  '@media (min-width: 1280px)': {
                    // Adjust gap for screens with a minimum width of 1280px (lg)
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: 'space-between'
                  },
                }}>
                <Box
                  sx={{
                    display: 'grid',
                    gap: '10px',
                    '@media (min-width: 600px)': {
                      display: 'grid', // Apply this style for screens with a minimum width of 600px (sm)
                      gap: '10px',
                    },
                    '@media (min-width: 960px)': {
                      display: 'flex', // Apply this style for screens with a minimum width of 960px (md)
                      gap: '10px',
                    },
                  }}>

                  <TextField select defaultValue="last_seven" size="small"
                    value={selectedRange}
                    onChange={handleRangeChange}
                    InputProps={{
                      className: 'red-border',
                      style: customInputStyle,
                      /* endAdornment: (
                        <ArrowDropDownIcon />
                      ), */
                    }}>


                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="yesterday">Yesterday</MenuItem>
                    <MenuItem value="last7Days">Last 7 Days</MenuItem>
                    <MenuItem value="last30Days">Last 30 Days</MenuItem>
                    <MenuItem value="thisMonth">This Month</MenuItem>
                    <MenuItem value="lastMonth">Last Month</MenuItem>
                    <MenuItem value="thisWeek">This Week</MenuItem>

                  </TextField>

                  <Stack padding={1}
                    sx={{
                      // gap: 7,

                      '@media (min-width: 600px)': {
                        // Adjust gap for screens with a minimum width of 600px (sm)
                        flexDirection: "column",

                      },
                      '@media (min-width: 960px)': {
                        // Adjust gap for screens with a minimum width of 960px (md)
                        flexDirection: "row",
                        display: 'flex',
                        justifyContent: 'space-between'
                      },
                      '@media (min-width: 1280px)': {
                        // Adjust gap for screens with a minimum width of 1280px (lg)
                        flexDirection: "row",
                        display: 'flex',
                        justifyContent: 'space-between'
                      },
                    }} >
                    <Box className="year"
                      sx={{
                        display: 'grid',
                        gap: '10px',
                        '@media (min-width: 600px)': {
                          display: 'grid', // Apply this style for screens with a minimum width of 600px (sm)
                          gap: '10px',
                        },
                        '@media (min-width: 960px)': {
                          display: 'flex', // Apply this style for screens with a minimum width of 960px (md)
                          gap: '10px',
                        },
                      }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                          sx={{
                            display: 'grid',
                            gap: '10px',
                            '@media (min-width: 600px)': {
                              display: 'grid', // Apply this style for screens with a minimum width of 600px (sm)
                              gap: '10px',
                            },
                            '@media (min-width: 960px)': {
                              display: 'flex', // Apply this style for screens with a minimum width of 960px (md)
                              gap: '10px',
                            },
                          }}
                        >
                          <DemoItem>
                            <DatePicker
                              sx={{ width: isMobile ? '100%' : '200px' }}
                              slotProps={{
                                openPickerIcon: { fontSize: 'small' },
                                openPickerButton: { color: 'primary' },
                                leftArrowIcon: { color: 'primary' },
                                rightArrowIcon: { color: 'primary' },
                                textField: {
                                  variant: 'filled',
                                  focused: true,
                                  color: 'primary',
                                },
                              }}
                              value={fromDate}
                              onChange={handleFromDateChange}
                            />
                          </DemoItem>
                        </Box>
                      </LocalizationProvider>
                    </Box>
                  </Stack>

                  <Stack padding={1}
                    sx={{
                      // gap: 7,

                      '@media (min-width: 600px)': {
                        // Adjust gap for screens with a minimum width of 600px (sm)
                        flexDirection: "column",

                      },
                      '@media (min-width: 960px)': {
                        // Adjust gap for screens with a minimum width of 960px (md)
                        flexDirection: "row",
                        display: 'flex',
                        justifyContent: 'space-between'
                      },
                      '@media (min-width: 1280px)': {
                        // Adjust gap for screens with a minimum width of 1280px (lg)
                        flexDirection: "row",
                        display: 'flex',
                        justifyContent: 'space-between'
                      },
                    }}  >
                    <Box className="year" >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                          sx={{
                            display: 'grid',
                            gap: '10px',
                            '@media (min-width: 600px)': {
                              display: 'grid', // Apply this style for screens with a minimum width of 600px (sm)
                              gap: '10px',
                            },
                            '@media (min-width: 960px)': {
                              display: 'flex', // Apply this style for screens with a minimum width of 960px (md)
                              gap: '10px',
                            },
                          }}
                        >
                          <DemoItem>
                            <DatePicker
                              sx={{ width: isMobile ? '100%' : '200px' }}
                              slotProps={{
                                openPickerIcon: { fontSize: 'small' },
                                openPickerButton: { color: 'primary' },
                                leftArrowIcon: { color: 'primary' },
                                rightArrowIcon: { color: 'primary' },
                                textField: {
                                  variant: 'filled',
                                  focused: true,
                                  color: 'primary',
                                },
                              }}
                              value={toDate}
                              onChange={handleToDateChange}
                            />
                          </DemoItem>

                        </Box>
                      </LocalizationProvider>
                    </Box>
                  </Stack>
                  <Typography style={{ color: 'black' }}>Compared to</Typography>
                  <TextField select
                    InputProps={{
                      className: 'red-border',
                      style: customInputStyle,
                      /* endAdornment: (
                        <ArrowDropDownIcon /> 
                      ), */
                    }}
                    value={selectedPrevRange}
                    onChange={handlePrevRangeChange}
                    defaultValue="previous_period" size="small">
                    <MenuItem value="previous_period">Previous Day</MenuItem>
                    <MenuItem value="previous_week">Previous Week</MenuItem>
                    <MenuItem value="previous_month">Previous Month</MenuItem>
                    <MenuItem value="previous_year">Previous Year</MenuItem>
                  </TextField>

                  {/* <Autocomplete
                    options={options}
                    noOptionsText="No Products"
                    sx={{ width: isMobile ? '100%' : '170px', alignItems: 'right' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          '&::placeholder': {
                            color: 'black',
                          },
                        }}
                        placeholder="Products"
                        fullWidth
                        size="small"
                        InputProps={{
                          style: customInputStyle,
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}

                    getOptionLabel={(option) =>
                      `${option.product_module}`
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>
                          {option.product_module}
                        </li>
                      );
                    }}
                    onChange={handleProductChange}
                  /> */}
                </Box>
              </Stack>

              {/* 1. cards*/}
              {/* <Stack flexDirection="row" gap={2}>
                  <Card >
                  <Box sx={{ flex: 1 }}>
                    <Box padding={2}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Typography color="black" display="flex" alignItems="center">
                          TOTAl USERS
                        </Typography>
                        <Box
                          color={"green"}
                          borderRadius={6}
                      
                          padding="8px 64px 8px 12px"
                          sx={{
                            fontSize: "12px",
    display: "flex", // Use flex display
    alignItems: "left", // Set the font size to 12px or your desired size
                          }}
                        >
                          +20% Increase
                        </Box>
                      </Stack>
                      <Typography color="#4BA5EB" variant="h4" fontWeight="bold">
                        200
                      </Typography>
                    </Box>
                    <Box
                      color="black"
                      bgcolor="#F2F6FA"
                      display="flex"
                      py={1}
                      justifyContent="center"
                    >
                      More Info &gt;
                    </Box>
                  </Box>

      </Card> &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
      <Card >
        <Box sx={{ flex: 1 }}>
                    <Box padding={2}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Typography color="black" display="flex" alignItems="center">
                          TOTAL Revenue
                        </Typography>
                        <Box
                          color={"green"}
                          borderRadius={6}
                         
                          padding="8px 49px 8px 12px"
                          sx={{
                            fontSize: "12px",
    display: "flex", // Use flex display
    alignItems: "left", // Set the font size to 12px or your desired size
                          }}
                        >
                          +20% Increase
                        </Box>
                      </Stack>
                      <Typography color="#4BA5EB" variant="h4" fontWeight="bold">
                        $12,989
                      </Typography>
                    </Box>
                    <Box
                      color="black"
                      bgcolor="#F2F6FA"
                      display="flex"
                      py={1}
                      justifyContent="center"
                    >
                      More Info &gt;
                    </Box>
                  </Box>
 
      </Card>  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;
      <Card >
        <Box sx={{ flex: 1 }}>
                    <Box padding={2}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Typography color="black" display="flex" alignItems="center">
                          TOTAl USERS
                        </Typography>
                        <Box
                          color={"green"}
                          borderRadius={6}
                          
                          padding="8px 80px 8px 12px"
                          sx={{
                            fontSize: "12px",
    display: "flex", // Use flex display
    alignItems: "left", // Set the font size to 12px or your desired size
                          }}
                        >
                          +20% New Wisher
                        </Box>
                      </Stack>
                      <Typography color="#4BA5EB" variant="h4" fontWeight="bold">
                        78
                      </Typography>
                    </Box>
                    <Box
                      color="black"
                      bgcolor="#F2F6FA"
                      display="flex"
                      py={1}
                      justifyContent="center"
                    >
                      More Info &gt;
                    </Box>
                  </Box>

      </Card>
    </Stack> */}

              {/* 2. cards*/}
              {/* <Stack flexDirection="row" gap={2}>
                  <Card >
                  <Box sx={{ flex: 1 }}>
                    <Box padding={2}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Typography color="black" display="flex" alignItems="center">
                          TOTAl USERS
                        </Typography>
                        <Box
                          color={"green"}
                          borderRadius={6}
                      
                          padding="8px 64px 8px 12px"
                          sx={{
                            fontSize: "12px",
    display: "flex", // Use flex display
    alignItems: "left", // Set the font size to 12px or your desired size
                          }}
                        >
                          +20% Increase
                        </Box>
                      </Stack>
                      <Typography color="#4BA5EB" variant="h4" fontWeight="bold">
                        200
                      </Typography>
                    </Box>
                    <Stack direction="row" sx={{ width: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <SparkLineChart data={[1, 4, 2, 5, 7, 2, 4, 6]} height={100} />
      </Box>

    </Stack>
                  </Box>

      </Card> &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
      <Card >
        <Box sx={{ flex: 1 }}>
                    <Box padding={2}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Typography color="black" display="flex" alignItems="center">
                          TOTAL Revenue
                        </Typography>
                        <Box
                          color={"green"}
                          borderRadius={6}
                         
                          padding="8px 49px 8px 12px"
                          sx={{
                            fontSize: "12px",
    display: "flex", // Use flex display
    alignItems: "left", // Set the font size to 12px or your desired size
                          }}
                        >
                          +20% Increase
                        </Box>
                      </Stack>
                      <Typography color="#4BA5EB" variant="h4" fontWeight="bold">
                        $12,989
                      </Typography>
                    </Box>
                    <Stack direction="row" sx={{ width: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <SparkLineChart data={[1, 4, 2, 5, 7, 2, 4, 6]} height={100} />
      </Box>

    </Stack>
                  </Box>
 
      </Card>  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;
      <Card >
        <Box sx={{ flex: 1 }}>
                    <Box padding={2}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Typography color="black" display="flex" alignItems="center">
                          TOTAl USERS
                        </Typography>
                        <Box
                          color={"green"}
                          borderRadius={6}
                          
                          padding="8px 80px 8px 12px"
                          sx={{
                            fontSize: "12px",
    display: "flex", // Use flex display
    alignItems: "left", // Set the font size to 12px or your desired size
                          }}
                        >
                          +20% New Wisher
                        </Box>
                      </Stack>
                      <Typography color="#4BA5EB" variant="h4" fontWeight="bold">
                        78
                      </Typography>
                    </Box>
                    <Stack direction="row" sx={{ width: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <SparkLineChart data={[1, 4, 2, 5, 7, 2, 4, 6]} height={100} />
      </Box>

    </Stack>
                  </Box>

      </Card>
    </Stack> */}
              <Stack
                sx={{
                  // gap: 7,

                  '@media (min-width: 600px)': {
                    // Adjust gap for screens with a minimum width of 600px (sm)
                    flexDirection: "column"
                  },
                  '@media (min-width: 960px)': {
                    // Adjust gap for screens with a minimum width of 960px (md)
                    flexDirection: "row"
                  },
                  '@media (min-width: 1280px)': {
                    // Adjust gap for screens with a minimum width of 1280px (lg)
                    flexDirection: "row"
                  },
                }}>
                <Card style={{ margin: '1%' }}>
                  <Box sx={{ flex: 1, height: '170px' }}>
                    <Box padding={2}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Typography color="black" display="flex" alignItems="center">
                          TOTAL USERS
                        </Typography>

                        <Box
                          color={"green"}
                          borderRadius={6}

                          padding="8px 165px 8px 12px"
                          sx={{
                            fontSize: "12px",
                            display: "flex", // Use flex display
                            alignItems: "left", // Set the font size to 12px or your desired size
                          }}
                        >
                          {totalusersincrease ? ("+" + totalusersincrease.user_increase_percentage + "% increase") : '0% increase'}
                        </Box>

                      </Stack>
                      <Typography color="#4BA5EB" variant="h4" fontWeight="bold">
                        {totalusers ? totalusers.totalrecords : 0}
                      </Typography>
                    </Box>

                  </Box>

                </Card>
                {/* &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; */}
                <Card style={{ margin: '1%' }}>
                  <Box sx={{ flex: 1, height: '170px' }}>
                    <Box padding={2}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Typography color="black" display="flex" alignItems="center">
                          TOTAL REVENUE
                        </Typography>
                        <Box
                          color={"green"}
                          borderRadius={6}

                          padding="8px 165px 8px 12px"
                          sx={{
                            fontSize: "12px",
                            display: "flex", // Use flex display
                            alignItems: "left", // Set the font size to 12px or your desired size
                          }}
                        >
                          {revenueincreasepercentage ? "+" + revenueincreasepercentage.revenue_increase_percent + "% increase" : '0% increase'}
                        </Box>
                      </Stack>
                      <Typography color="#4BA5EB" variant="h4" fontWeight="bold">
                        {totalRevenue ? ("$" + totalRevenue.total_revenue) : 0}
                      </Typography>
                    </Box>

                  </Box>

                </Card>
                {/* &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; */}
                <Card style={{ margin: '1%' }}>
                  <Box sx={{ flex: 1, height: '170px' }}>
                    <Box padding={2}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Typography color="black" display="flex" alignItems="center">
                          TOTAL VISITS
                        </Typography>
                        <Box
                          color={"green"}
                          borderRadius={6}

                          padding="8px 165px 8px 12px"
                          sx={{
                            fontSize: "12px",
                            display: "flex", // Use flex display
                            alignItems: "left", // Set the font size to 12px or your desired size
                          }}
                        >
                          {totalgoogleincreasepercentage ? "+" + totalgoogleincreasepercentage.total_google_visit_increment_percent + "% increase" : '0% increase'}
                        </Box>
                      </Stack>
                      <Typography color="#4BA5EB" variant="h4" fontWeight="bold">
                        {totalgooglevisit ? totalgooglevisit.total_google_visit : 0}
                      </Typography>
                    </Box>

                  </Box>

                </Card>
                {/* &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;   */}
              </Stack> &nbsp; &nbsp;

              <Stack
                sx={{
                  // gap: 7,

                  '@media (min-width: 600px)': {
                    // Adjust gap for screens with a minimum width of 600px (sm)
                    flexDirection: "column"
                  },
                  '@media (min-width: 960px)': {
                    // Adjust gap for screens with a minimum width of 960px (md)
                    flexDirection: "row"
                  },
                  '@media (min-width: 1280px)': {
                    // Adjust gap for screens with a minimum width of 1280px (lg)
                    flexDirection: "row"
                  },
                }}>
                <Card style={{ margin: '1%', width: '100%' }}>
                  <Box sx={{ flex: 1 }}>
                    <Box padding={2}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Typography color="black" display="flex" alignItems="center">
                          Users
                        </Typography>
                        <Box
                          color={"green"}
                          borderRadius={6}

                          padding="8px 106px 8px 12px"
                          sx={{
                            fontSize: "12px",
                            display: "flex", // Use flex display
                            alignItems: "left", // Set the font size to 12px or your desired size
                          }}
                        >
                          {totalusersincrease ? ("+" + totalusersincrease.user_increase_percentage + "% increase") : '0% increase'}

                        </Box>
                      </Stack>
                      <Typography color="lightdark" variant="h4" fontWeight="bold">
                        {totalusers ? totalusers.totalrecords : 0}
                      </Typography>
                    </Box>
                    <Stack direction="row" sx={{ width: '100%' }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <SparkLineChart data={[1, 4, 2, 5, 7, 2, 4, 6]} height={100} />
                      </Box>

                    </Stack>
                  </Box>

                </Card>
                {/* &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; */}
                <Card style={{ margin: '1%', width: '100%' }}>
                  <Box sx={{ flex: 1 }}>
                    <Box padding={2}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Typography color="lightdark" display="flex" alignItems="center">
                          Revenue
                        </Typography>
                        <Box
                          color={"green"}
                          borderRadius={6}

                          padding="8px 101px 8px 12px"
                          sx={{
                            fontSize: "11px",
                            display: "flex", // Use flex display
                            alignItems: "left", // Set the font size to 12px or your desired size
                          }}
                        >
                          {revenueincreasepercentage ? ("+" + revenueincreasepercentage.revenue_increase_percent + "% increase") : '0% increase'}

                        </Box>
                      </Stack>
                      <Typography color="lightdark" variant="h4" fontWeight="bold">
                        {totalRevenue ? ("$" + totalRevenue.total_revenue) : 0}
                      </Typography>
                    </Box>
                    <Stack direction="row" sx={{ width: '100%' }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <SparkLineChart data={[1, 4, 2, 5, 7, 2, 4, 6]} height={100} />
                      </Box>

                    </Stack>
                  </Box>

                </Card>
                {/* &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; */}
                <Card style={{ margin: '1%', width: '100%' }}>
                  <Box sx={{ flex: 1 }}>
                    <Box padding={2}>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Typography color="black" display="flex" alignItems="center">
                          Visits
                        </Typography>
                        <Box
                          color={"green"}
                          borderRadius={6}

                          padding="8px 80px 8px 12px"
                          sx={{
                            fontSize: "11px",
                            display: "flex", // Use flex display
                            alignItems: "left", // Set the font size to 12px or your desired size
                          }}
                        >
                          {totalgoogleincreasepercentage ? ("+" + totalgoogleincreasepercentage.total_google_visit_increment_percent + "% increase") : ('0% increase')}

                        </Box>
                      </Stack>
                      <Typography color="lightdark" variant="h4" fontWeight="bold">
                        {totalgooglevisit ? totalgooglevisit.total_google_visit : 0}
                      </Typography>
                    </Box>
                    <Stack direction="row" sx={{ width: '100%' }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <SparkLineChart data={[1, 4, 2, 5, 7, 2, 4, 6]} height={100} />
                      </Box>

                    </Stack>
                  </Box>

                </Card>
                {/* &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;   */}
              </Stack> &nbsp; &nbsp;



            </Stack>
          </Stack> &nbsp; &nbsp;
          <Typography style={{ color: 'black' }} variant="h5">Customer List</Typography>

          <Stack sx={{
            // gap: 7,

            '@media (min-width: 600px)': {
              // Adjust gap for screens with a minimum width of 600px (sm)
              flexDirection: "column"
            },
            '@media (min-width: 960px)': {
              // Adjust gap for screens with a minimum width of 960px (md)
              flexDirection: "row"
            },
            '@media (min-width: 1280px)': {
              // Adjust gap for screens with a minimum width of 1280px (lg)
              flexDirection: "row"
            },
          }}>
            <Box style={{ width: '100%' }}>
              <Box padding={1} style={{ width: '100%' }}>
                <Stack sx={{
                  // gap: 7,

                  '@media (min-width: 600px)': {
                    // Adjust gap for screens with a minimum width of 600px (sm)
                    flexDirection: "column"
                  },
                  '@media (min-width: 960px)': {
                    // Adjust gap for screens with a minimum width of 960px (md)
                    flexDirection: "row"
                  },
                  '@media (min-width: 1280px)': {
                    // Adjust gap for screens with a minimum width of 1280px (lg)
                    flexDirection: "row",
                    display: 'flex', justifyContent: 'space-between'
                  },
                }}>
                  <Box sx={{

                    '@media (min-width: 600px)': {
                      flexDirection: 'column', // Adjust direction for screens with a minimum width of 600px (sm)
                    },
                    '@media (min-width: 960px)': {
                      flexDirection: 'row', // Adjust direction for screens with a minimum width of 960px (md)
                    },
                    '@media (min-width: 1280px)': {
                      flexDirection: 'row', // Adjust direction for screens with a minimum width of 1280px (lg)
                      display: 'flex',
                      justifyContent: 'space-between',
                    },
                  }}>
                    {/* <TextField style={{ width: '100%' }} select defaultValue="last_seven" size="small"
                      InputProps={{
                        className: 'red-border',
                        style: customInputStyle,
                        endAdornment: (
                          <ArrowDropDownIcon /> // Add the dropdown icon here
                        ),// Apply the CSS class to set the border color to red
                      }}>

                      <MenuItem value="last_seven">Pick a date range</MenuItem>

                    </TextField> */}
                    <Stack padding={1}
                      sx={{
                        // gap: 7,

                        '@media (min-width: 600px)': {
                          // Adjust gap for screens with a minimum width of 600px (sm)
                          flexDirection: "column",

                        },
                        '@media (min-width: 960px)': {
                          // Adjust gap for screens with a minimum width of 960px (md)
                          flexDirection: "row",
                          display: 'flex',
                          justifyContent: 'space-between'
                        },
                        '@media (min-width: 1280px)': {
                          // Adjust gap for screens with a minimum width of 1280px (lg)
                          flexDirection: "row",
                          display: 'flex',
                          justifyContent: 'space-between'
                        },
                      }} >
                      <Box className="year"
                        sx={{
                          display: 'grid',
                          gap: '10px',
                          '@media (min-width: 600px)': {
                            display: 'grid', // Apply this style for screens with a minimum width of 600px (sm)
                            gap: '10px',
                          },
                          '@media (min-width: 960px)': {
                            display: 'flex', // Apply this style for screens with a minimum width of 960px (md)
                            gap: '10px',
                          },
                        }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            sx={{
                              display: 'grid',
                              gap: '10px',
                              '@media (min-width: 600px)': {
                                display: 'grid', // Apply this style for screens with a minimum width of 600px (sm)
                                gap: '10px',
                              },
                              '@media (min-width: 960px)': {
                                display: 'flex', // Apply this style for screens with a minimum width of 960px (md)
                                gap: '10px',
                              },
                            }}
                          >
                            <DemoItem>
                              <DatePicker
                                sx={{ width: isMobile ? '100%' : '230px' }}
                                slotProps={{
                                  openPickerIcon: { fontSize: 'small' },
                                  openPickerButton: { color: 'primary' },
                                  leftArrowIcon: { color: 'primary' },
                                  rightArrowIcon: { color: 'primary' },
                                  textField: {
                                    variant: 'filled',
                                    focused: true,
                                    color: 'primary',
                                  },
                                }}
                                value={customerFromDate}
                                onChange={handlecustoFromDate}
                              />
                            </DemoItem>

                          </Box>
                        </LocalizationProvider>
                      </Box>
                    </Stack>

                    <Stack padding={1}
                      sx={{
                        // gap: 7,

                        '@media (min-width: 600px)': {
                          // Adjust gap for screens with a minimum width of 600px (sm)
                          flexDirection: "column",

                        },
                        '@media (min-width: 960px)': {
                          // Adjust gap for screens with a minimum width of 960px (md)
                          flexDirection: "row",
                          display: 'flex',
                          justifyContent: 'space-between'
                        },
                        '@media (min-width: 1280px)': {
                          // Adjust gap for screens with a minimum width of 1280px (lg)
                          flexDirection: "row",
                          display: 'flex',
                          justifyContent: 'space-between'
                        },
                      }} >
                      <Box className="year"
                        sx={{
                          display: 'grid',
                          gap: '10px',
                          '@media (min-width: 600px)': {
                            display: 'grid', // Apply this style for screens with a minimum width of 600px (sm)
                            gap: '10px',
                          },
                          '@media (min-width: 960px)': {
                            display: 'flex', // Apply this style for screens with a minimum width of 960px (md)
                            gap: '10px',
                          },
                        }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            sx={{
                              display: 'grid',
                              gap: '10px',
                              '@media (min-width: 600px)': {
                                display: 'grid', // Apply this style for screens with a minimum width of 600px (sm)
                                gap: '10px',
                              },
                              '@media (min-width: 960px)': {
                                display: 'flex', // Apply this style for screens with a minimum width of 960px (md)
                                gap: '10px',
                              },
                            }}
                          >
                            <DemoItem>
                              <DatePicker
                                sx={{ width: isMobile ? '100%' : '230px' }}
                                slotProps={{
                                  openPickerIcon: { fontSize: 'small' },
                                  openPickerButton: { color: 'primary' },
                                  leftArrowIcon: { color: 'primary' },
                                  rightArrowIcon: { color: 'primary' },
                                  textField: {
                                    variant: 'filled',
                                    focused: true,
                                    color: 'primary',
                                  },
                                }}
                                value={customerToDate}
                                onChange={handlecustoToDate}
                              />
                            </DemoItem>

                          </Box>
                        </LocalizationProvider>
                      </Box>
                    </Stack>
                    &nbsp;

                    <TextField
                      style={{ border: '1px solid #CBCBCB', borderRadius: '5px', width: '100%', marginTop: '0%', height: '80%' }}
                      placeholder="Search by name, email, product, etc."
                      size="small"
                      /*  InputLabelProps={{
                         className: 'red-label',
                         style: { height: '110%' }
                       }} */
                      // onChange={(e) => setSearchText(e.target.value)}
                      value={searchText}
                      onChange={handleInputChange}

                    />
                  </Box>&nbsp;
                  <Box sx={{

                    '@media (min-width: 600px)': {
                      flexDirection: 'column', // Adjust direction for screens with a minimum width of 600px (sm)
                    },
                    '@media (min-width: 960px)': {
                      flexDirection: 'row', // Adjust direction for screens with a minimum width of 960px (md)
                    },
                    '@media (min-width: 1280px)': {
                      flexDirection: 'row', // Adjust direction for screens with a minimum width of 1280px (lg)
                      display: 'flex',
                      justifyContent: 'space-between',
                    },
                  }}>
                    <MenuItem value="last_seven" >
                      Shown by
                    </MenuItem>
                    <TextField style={{ width: '100%', justifyContent: 'center' }} select defaultValue="last_seven" size="small"
                      InputProps={{
                        className: 'red-border',
                        style: customInputStyle,
                        endAdornment: (
                          <ArrowDropDownIcon />
                        ),
                      }}
                      value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>

                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="25">25</MenuItem>
                      <MenuItem value="50">50</MenuItem>
                    </TextField>
                    &nbsp;

                  </Box>

                </Stack>
              </Box>
            </Box>
          </Stack>





          {isBrowser ?
            <TableContainer component={Paper} sx={{ margin: '1%', width: '100%' }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead style={{ backgroundColor: ' #F2F6FA', border: "gray" }}>
                  <TableRow>
                    <StyledTableCell style={{ border: "gray" }} align="center">S.No </StyledTableCell>
                    <StyledTableCell style={{ border: "gray" }} align="center">NAME </StyledTableCell>
                    <StyledTableCell style={{ border: "gray" }} align="center">EMAIL</StyledTableCell>
                    <StyledTableCell style={{ border: "gray" }} align="center">{/* PRODUCT */}CAMPAIGN</StyledTableCell>
                    {/* <StyledTableCell style={{ border: "gray" }} align="center">PLAN</StyledTableCell> */}
                    {/* <StyledTableCell style={{ border: "gray" }} align="center">NO.OF.<br />BLOGS/MONTH</StyledTableCell> */}
                    <StyledTableCell style={{ border: "gray" }} align="center">REGISTRATION DATE</StyledTableCell>
                    {/* <StyledTableCell style={{ border: "gray" }} align="center">LAST LOGIN</StyledTableCell>
                    <StyledTableCell style={{ border: "gray" }} align="center">IP</StyledTableCell> */}
                    <StyledTableCell style={{ border: "gray" }} align="center">STATUS</StyledTableCell>
                    <StyledTableCell style={{ border: "gray" }} align="center">ACTION</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {filteredUsers ? filteredUsers.map((user, index) => ( */}
                  {userlists ? userlists.map((user, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{index + 1 + (currentPage - 1) * pageSize}</StyledTableCell>
                      <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{user.name}</StyledTableCell>
                      <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{user.email}</StyledTableCell>
                      <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">
                        {user.campaigns.map((campaign, index) => (
                          <React.Fragment key={index}>
                            <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">
                              {/* <div><span style={{ color: 'black', fontWeight: 'bold' }}>CampaignId:</span> {campaign.campaign_id}</div> */}
                              <div><span style={{ color: 'black', fontWeight: 'bold' }}>CampaignName:</span> {campaign.product_name}</div>
                              <div><span style={{ color: 'black', fontWeight: 'bold' }}>URL:</span> {campaign.url}</div>
                              <div><span style={{ color: 'black', fontWeight: 'bold' }}>Mode:</span> {campaign.mode}</div>
                              <div><span style={{ color: 'black', fontWeight: 'bold' }}>SubscriptionLevel:</span> {campaign.subscription_level}</div>
                              <div><span style={{ color: 'black', fontWeight: 'bold' }}>ExpiryDate:</span> {campaign.expiry_date}</div>
                              <div><span style={{ color: 'black', fontWeight: 'bold' }}>Blogs/month:</span> {campaign.monthly_blogs}</div>
                            </StyledTableCell>
                            <br />
                          </React.Fragment>
                        ))}
                      </StyledTableCell>

                      {/* <StyledTableCell style={{ color: 'black', border: "darkgray", verticalAlign: 'top' }} align="left">
                        {user.campaigns.map((campaign, index) => (
                          <React.Fragment key={index}>
                            <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">
                              <div>{campaign.monthly_blogs}</div>
                            </StyledTableCell>
                            <br />
                          </React.Fragment>
                        ))}
                      </StyledTableCell> */}

                      <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{user.created_at.split("T")[0]}</StyledTableCell>
                      {/*  <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{''}</StyledTableCell>
                      <StyledTableCell style={{ color: 'black', border: "darkgray" }} align="left">{''}</StyledTableCell> */}
                      <StyledTableCell align="center" style={{ cursor: 'pointer', border: "darkgray" }}>{user.status ? (user.status === 'active' ? <Button variant="contained" style={{ color: 'black', backgroundColor: 'lightgreen', height: '30px', width: '60%' }}>{'Active'}</Button> : <Button variant="contained" style={{ color: 'white', backgroundColor: 'red', height: '30px', width: '74%' }}>{'InActive'}</Button>) : <Button variant="contained" style={{ color: 'black', backgroundColor: 'lightgreen', height: '30px', width: '60%' }}>{'Active'}</Button>}</StyledTableCell>
                      <StyledTableCell align="center" style={{ cursor: 'pointer', border: "darkgray" }}>{user.status ? (user.status === 'inactive' ? <Tooltip title="Activate Campaign"><SvgIcon style={{ color: 'dodgerblue' }} component={PersonAddAlt1Icon} inheritViewBox onClick={() => handleActivateUser(user.email, index, userlists)}>{'Activate'} </SvgIcon></Tooltip> : <Tooltip title="Deactivate Campaign"><SvgIcon aria-label="Deactivate user" style={{ color: "red" }} component={PersonAddDisabledIcon} inheritViewBox onClick={() => handleDeactivateUser(user.email, index, userlists)}>{'Deactivate'}  </SvgIcon></Tooltip>) : <Tooltip title="Activate user"><SvgIcon aria-label="Activate user" style={{ color: "red" }} component={PersonAddDisabledIcon} inheritViewBox onClick={() => handleDeactivateUser(user.email, index, userlists)}>{'Deactivate'}  </SvgIcon></Tooltip>}</StyledTableCell>
                    </StyledTableRow>
                  )) : null}
                </TableBody>
              </Table>
            </TableContainer> :
            <Box style={{ backgroundColor: '#F9FBFC', height: '100%', padding: '1%', borderRadius: '5px' }}>
              {userlists ? userlists.map((user, index) => (
                <Stack>
                  <Grid container>
                    <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={900}>S.No:</Typography>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={400}>{index + 1 + (currentPage - 1) * pageSize}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={900}>Name:</Typography>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={400}>{user.name}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={900}>Email:</Typography>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={400}>{user.email}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={900}>Campaign:</Typography>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                      {user.campaigns.map((campaign, index) => (
                        <React.Fragment>
                          <div key={index}>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}><span style={{ color: 'black', fontWeight: 'bold' }}>CampaignName:</span> {campaign.product_name}</Typography>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}><span style={{ color: 'black', fontWeight: 'bold' }}>URL:</span> {campaign.url}</Typography>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}><span style={{ color: 'black', fontWeight: 'bold' }}>Mode:</span> {campaign.mode}</Typography>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}><span style={{ color: 'black', fontWeight: 'bold' }}>SubscriptionLevel:</span> {campaign.subscription_level}</Typography>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}><span style={{ color: 'black', fontWeight: 'bold' }}>ExpiryDate:</span> {campaign.expiry_date}</Typography>
                            <Typography color="#000"
                              variant="h6"
                              fontWeight={400}><span style={{ color: 'black', fontWeight: 'bold' }}>Blogs/month:</span> {campaign.monthly_blogs}</Typography>
                          </div>
                          <br />
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>

                  {/*  <Grid container>
                    <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={900}>No.Of.Blogs/Month:</Typography>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                      {user.campaigns.map((campaign, index) => (
                        <div>
                          <Typography color="#000"
                            variant="h6"
                            fontWeight={400}>{campaign.monthly_blogs}</Typography>
                        </div>
                      ))}
                    </Grid>
                  </Grid> */}

                  <Grid container>
                    <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={900}>Registration Date:</Typography>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={400}>{user.created_at.split("T")[0]}**</Typography>
                    </Grid>
                  </Grid>

                  {/* <Grid container>
                    <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={900}>Last Login:</Typography>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={400}></Typography>
                    </Grid>
                  </Grid> */}

                  {/* <Grid container>
                    <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={900}>IP:</Typography>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={400}></Typography>
                    </Grid>
                  </Grid> */}

                  <Grid container>
                    <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={900}>Status:</Typography>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={400}>{user.status ? (user.status === 'active' ? <Button variant="contained" style={{ color: 'black', backgroundColor: 'lightgreen', height: '30px', width: '60%' }}>{'Active'}</Button> : <Button variant="contained" style={{ color: 'white', backgroundColor: 'red', height: '30px', width: '74%' }}>{'InActive'}</Button>) : <Button variant="contained" style={{ color: 'black', backgroundColor: 'lightgreen', height: '30px', width: '60%' }}>{'Active'}</Button>}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={5} md={5} lg={5} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={900}>Action:</Typography>
                    </Grid>
                    <Grid item xs={7} md={7} lg={7} style={{ padding: '1%' }}>
                      <Typography color="#000"
                        variant="h6"
                        fontWeight={400} style={{ cursor: 'pointer' }}>{user.status ? (user.status === 'inactive' ? <Tooltip title="Activate user"><SvgIcon style={{ color: 'dodgerblue' }} component={PersonAddAlt1Icon} inheritViewBox onClick={() => handleActivateUser(user.email, index, userlists)}>{'Activate'} </SvgIcon></Tooltip> : <Tooltip title="Deactivate user"><SvgIcon aria-label="Deactivate user" style={{ color: "red" }} component={PersonAddDisabledIcon} inheritViewBox onClick={() => handleDeactivateUser(user.email, index, userlists)}>{'Deactivate'}  </SvgIcon></Tooltip>) : <Tooltip title="Activate user"><SvgIcon aria-label="Activate user" style={{ color: "red" }} component={PersonAddDisabledIcon} inheritViewBox onClick={() => handleDeactivateUser(user.email, index, userlists)}>{'Deactivate'}  </SvgIcon></Tooltip>}</Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{
                    margin: '1%', flexShrink: '0px', borderWidth: '0px', borderStyle: 'solid',
                    borderColor: '#000', borderBottomWidth: 'thin'
                  }} />
                </Stack>
              )) : <Stack>
                <Grid container>
                  <Grid item xs={12} md={12} lg={12} style={{ padding: '1%' }}>
                    <Typography color="#000"
                      variant="h6"
                      fontWeight={400}>No users Available</Typography>
                  </Grid>
                </Grid>
              </Stack>}
            </Box>
          }



          <div style={{ textAlign: 'center' }}>
            <LoadingButton
              startIcon={
                <SvgIcon>
                  <KeyboardArrowLeft />
                </SvgIcon>
              }

              sx={{
                paddingRight: '15px'
              }}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >

            </LoadingButton>

            {/* <span>{currentPage}</span> */}
            {pageNumbers.map((page, index) => (
              <span key={index} style={{ padding: '0 5px' }}>
                <span
                  style={{
                    cursor: page === '...' ? 'default' : 'pointer',
                    fontWeight: page === currentPage ? 'bold' : 'normal'
                  }}
                  onClick={() => {
                    if (typeof page === 'number') setCurrentPage(page);
                  }}
                >
                  {page}
                </span>
              </span>
            ))}

            <LoadingButton
              startIcon={
                <SvgIcon>
                  <KeyboardArrowRight />
                </SvgIcon>
              }

              sx={{
                paddingLeft: '35px'
              }}
              onClick={handleNextPage}
              disabled={userlists && userlists?.length < pageSize}
            >

            </LoadingButton>
          </div>
        </Container>

        <Container>&nbsp;</Container>
        <Container>&nbsp;</Container>
        <Container>&nbsp;</Container>
        <Container>&nbsp;</Container>
        <Container>&nbsp;</Container>

      </div>


    </>
  );
};

export default DashboardDemo;
