
import { Button } from '@mui/material';
import React, { FormEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useLazyCreateCheckoutSessionQuery } from '../../redux/api/subscriptionApi';
import { useNavigate } from 'react-router-dom';
import { setComplexity } from '../../redux/features/genieSlice';
import { toast } from "react-toastify";

const PayNowButton = () => {  
    
    // Check if the user is logged in
    const isAuthenticated = localStorage.getItem("logged_in");
    const isPaid = localStorage.getItem("payment");
    const localStorage_msg = localStorage.getItem("msg");
    const smart_contracte_complexity = localStorage.getItem("complexity");

    const navigate = useNavigate();
    const dipatch = useAppDispatch();
  
    const user = useAppSelector((state) => state.userState.user);
    const genieSelector = useAppSelector((state) => state.genieState);
    const [createCheckoutSession, checkoutState] = useLazyCreateCheckoutSessionQuery();

    useEffect(() => {
      if(checkoutState.isSuccess){
        window.location = checkoutState.data?.checkout_url;
      }
    }, [checkoutState, navigate]);

    function handlePayNow(e: FormEvent<HTMLFormElement>){
        e.preventDefault();
        if(isAuthenticated!="true") {
          navigate('/signin');
        }else{
          const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
          // if(storedAllSet)
          //   createCheckoutSession({"emailid": user?.email||"", "username": user?.name||"", "subscription_level": String(smart_contracte_complexity), "couponcode":""});
          // else
          //   toast.success('Pls. wait, we are working in background.');
         }
    } 

    return (
        <>
          { (isPaid == null && localStorage_msg != null && genieSelector.module == "SMART CONTRACTS" && smart_contracte_complexity != "Error") &&
            <form method="POST" onSubmit={(event)=>handlePayNow(event)} >
              <Button
                size="large"
                type="submit"
                sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 5,
                    color: "white",
                    paddingX: 2,
                    paddingY: 1,
                }}
                >
                Pay now
              </Button>
            </form>
          }
        </>
    )
}

export default PayNowButton;