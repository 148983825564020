import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Container,
  Stack,
  SvgIcon,
  Divider,
  FormControl,
  TextField,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialTwitter,
  LoginSocialGithub,
  LoginSocialLinkedin,
  IResolveParams,
} from "reactjs-social-login";
import { toast } from "react-toastify";
import { object, string, TypeOf } from "zod";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import { ReactComponent as GoogleIcon } from "../../assets/ico_google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/ico_facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/ico_twtter.svg";
import { ReactComponent as GithubIcon } from "../../assets/ico_github.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/ico_linkedin.svg";

import BackSignup from "../../assets/back_signup.png";
import Logo from "../../assets/logo_white.png";

import {
  useSignupUserMutation,
  useSocialAuthMutation,
  useSigninUserMutation
} from "../../redux/api/authApi";
import { useGeneratedbusinessurlblogsMutation } from '../../redux/api/promptApi';
import { useAppDispatch } from "../../redux/store";
import { setModule } from "../../redux/features/genieSlice";
import { useSubscribeNewsMutation } from "../../redux/api/authApi";
import { useUnSubscribepaymentMutation } from "../../redux/api/authApi";
import img54 from '../../assets/newimg/logo.png';

const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>

const signupSchema = object({
  name: string().min(1, "Full name is required"),
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters"),
  passwordConfirm: string().min(1, "Please confirm your password"),
  appsumo_coupon_code: string(),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "Passwords do not match",
});

export type SignupInput = TypeOf<typeof signupSchema>;

const SignupPage = () => {
  const [termsCheck, setTermsCheck] = useState(false);

  const [authSocial, socialState] = useSocialAuthMutation();
  const [signupUser, signupState] = useSignupUserMutation();
  const [signinUser, signinState] = useSigninUserMutation();
  const [tuseremail, settUseremail] = useState('');
  const [tuserpass, settUserpass] = useState('');
  const [generatedBlogDetail, setGeneratedBlogDetail] = useState<any>();
  const [generatedBusinessUrlBlogsMutation] = useGeneratedbusinessurlblogsMutation();

  const appsumoCouponCode = localStorage.getItem("appsumo_coupon_code") || '';

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm<SignupInput>({
    resolver: zodResolver(signupSchema),
    defaultValues: {
      appsumo_coupon_code: appsumoCouponCode,
    }
  });

  const REDIRECT_URI = window.location.href;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (socialState.isSuccess) {
      toast.success("Social signup success");
      localStorage.setItem("logged_in", "true");
      if (socialState.data.role === "user") {
        localStorage.setItem("module", "SMART CONTRACTS");
        dispatch(setModule("SMART CONTRACTS"));
        navigate("/codegenie/smart_contracts");
      } else {
        navigate("/admin/dashboard");
      }
    }
    if (socialState.isError) {
      if (Array.isArray((socialState.error as any).data.detail)) {
        (socialState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((socialState.error as any).data.detail);
    }
  }, [socialState]);
  const user_email = localStorage.getItem('user_email') ?? '';


  useEffect(() => {
    const fetchData = async () => {
      // if (blogGenerated === 'true') {
      try {
        const response = await generatedBusinessUrlBlogsMutation({ userid: user_email });
        if ('data' in response) {
          const { data } = response.data;
          setGeneratedBlogDetail(data);
          console.log('data from variable=>', generatedBlogDetail);
        } else if ('error' in response) {

          const { error } = response;
          console.log('Error:', error);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
      // }
    };

    fetchData();
  }, [generatedBusinessUrlBlogsMutation, user_email]);

  useEffect(() => {
    console.log(generatedBlogDetail);
  }, [generatedBlogDetail]);

  useEffect(() => {
    if (signupState.isSuccess) {
      toast.success("User created successfully");
      localStorage.setItem("logged_in", "true");
      // navigate("/signin");
      navigate("/verify", { state: { appsumo_coupon_code: localStorage.getItem('appsumo_coupon_code') || '' } });
    }
    if (signupState.isError) {
      if (Array.isArray((signupState.error as any).data.detail)) {
        (signupState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((signupState.error as any).data.detail);
      localStorage.setItem('user exists', 'true');
      if (localStorage.getItem('user exists') === 'true') {
        localStorage.setItem("logged_in", "false");
        localStorage.removeItem("payment");
        localStorage.removeItem("msg");
        localStorage.removeItem("complexity");
        localStorage.removeItem("module");
        localStorage.removeItem("subscription_level");
        localStorage.removeItem("free_plan");
        localStorage.removeItem("userRole");
        localStorage.removeItem('user exists')
        //navigate('/signin');
      }
    }

  }, [signupState]);

  const handleSigninSuccess = (signinState: any) => {
    if (signinState.isSuccess) {
      localStorage.clear();
      toast.success("Login Success");

      localStorage.setItem("logged_in", "true");
      localStorage.setItem("free_plan", signinState.data.free_plan.toString());
      localStorage.setItem("remaining_wish_count", signinState.data.remaining_wish_count.toString());
      const subscriptionLevel = signinState.data.subscription_level || '';
      localStorage.setItem("subscription_level", subscriptionLevel);
      localStorage.setItem("user_email", signinState.data.user_email || '');
      localStorage.setItem('userRole', signinState.data.role);
      localStorage.setItem('payment', '');
      if (!signinState.data.verified) navigate("/verify");
      else {
        if (signinState.data.role === "user") {
          const freePlan = localStorage.getItem('free_plan');
          const generatingCount = localStorage.getItem('Generating Count');
          const user_email = localStorage.getItem('user_email') ?? '';

          if (generatedBlogDetail && generatedBlogDetail.length > 0) {
            navigate('/codegenie/generated-blogs');
          } else {
            navigate('/codegenie/dashboard');
          }
        } else {
          navigate("/admin/dashboard");
        }
      }
    }
    if (signinState.isError) {
      localStorage.setItem("logged_in", "false");

      if (Array.isArray((signinState.error as any).data.detail)) {
        (signinState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((signinState.error as any).data.detail);
    }
  };

  // First useEffect
  useEffect(() => {
    handleSigninSuccess(signinState);
  }, [signinState]);


  const onSubmitHandler: SubmitHandler<SignupInput> = (values) => {
    signupUser(values);
    localStorage.setItem("user_email", values.email);
    localStorage.setItem("user_pass", values.password);
    localStorage.setItem("user_name", values.name);
    //signinUser({ email: values.email, password: values.password });

  };



  const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();


  const methodsfoot = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register: RegisterFoot,
    handleSubmit: handleSubmitFoot,
    formState: { errors: Errors },
  } = methodsfoot;

  const onSubmitHandlerFoot: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .unwrap()
      .then((response) => {
        console.log('news subscription=>', response);
        const status = response.status;

        console.log(status);
        if (status === 'success') {
          toast.success(response.message);
          setSubscribeVal({
            email: '',
            status: ''
          });
        }
      })
      .catch((error) => {
        toast.error(error?.data?.detail ?? 'Something went wrong!');
        console.error('Error sending query:', error);

      });
  }
  return (
    <>
      <div className="container"></div>
      <div className="row my-sm-5 mb-5">
        <div className="col-md-5 text-white text-center mt-2">
          <div className="px-sm-0 px-2">
            <div>
              <div className="Ac-1">
                <div>
                  <Grid item xs={6} position="relative">
                    <img className="signupbg" src={BackSignup} alt="Signup Background" />
                    <Box
                      position="absolute"
                      left={32}
                      top={50}
                      display="flex"
                      alignItems="center"
                      gap={1}
                      width={450}
                    >
                      <img src={Logo} alt="Logo" onClick={() => navigate('/')} />
                      <Typography variant="h5" fontWeight={600} color="white" onClick={() => navigate('/')}>
                        CodeGenie
                      </Typography>
                    </Box>
                    <Typography
                      position="absolute"
                      bottom={103}
                      left={32}
                      variant="h4"
                      fontWeight={600}
                      color="white"
                    >
                      {/* Audit, Fix, Refactor, Document any Code Within Seconds */} <br /><br />
                    </Typography>
                  </Grid>
                </div>
              </div> <br></br> <br></br>
            </div>
          </div>
        </div>
        <div className="col-md-7 my-auto">
          <div className="px-sm-0 px-2">
            <div className="sign-up" >
              <Grid
                item
                xs={5}
                display="flex"
                alignItems="center"
                justifyContent="center"

              >
                <Box className="">
                  <Typography color="text.secondary" variant="h4" fontWeight={600}>
                    Create an account
                  </Typography>
                  {/* <Typography color="text.secondary" mt={4}>
                Sign up with social
              </Typography> */}
                  {/* <Stack direction="column" alignItems="center">
                <Box
                  width={56}
                  height={56}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border="1px solid #CACBCC"
                  borderRadius="50%"
                >
                  <LoginSocialGoogle
                    client_id={process.env.REACT_APP_GG_APP_ID || ""}
                    scope="email"
                    onResolve={({ provider, data }: IResolveParams) => {
                      if (data)
                        authSocial({
                          provider: provider as string,
                          email: data?.email||"" as string,
                          name: data?.name||"" as string,
                          id: data.sub as string
                        });
                    }}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <SvgIcon>
                      <GoogleIcon />
                    </SvgIcon>
                  </LoginSocialGoogle>
                </Box>
                
              </Stack> */}
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                    height={44}
                    mt={3}
                  >
                    {/* <Divider sx={{ flexGrow: 1 }} />
                <Typography>Or</Typography>
                <Divider sx={{ flexGrow: 1 }} /> */}
                  </Stack>
                  <FormProvider {...methods}>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit(onSubmitHandler)}
                    >
                      <Stack gap={3}>
                        <FormControl fullWidth>
                          <Typography mb={1} color="text.secondary">
                            Name
                          </Typography>
                          <TextField
                            {...register("name")}
                            placeholder="Enter full name"
                            error={!!errors["name"]}
                            helperText={errors["name"]?.message}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Typography mb={1} color="text.secondary">
                            Email address
                          </Typography>
                          <TextField
                            {...register("email")}
                            type="email"
                            placeholder="Enter your email"
                            error={!!errors["email"]}
                            helperText={errors["email"]?.message}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Typography mb={1} color="text.secondary">
                            Password
                          </Typography>
                          <TextField
                            {...register("password")}
                            type="password"
                            placeholder="min 8 characters"
                            error={!!errors["password"]}
                            helperText={errors["password"]?.message}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Typography mb={1} color="text.secondary">
                            Confirm Password
                          </Typography>
                          <TextField
                            {...register("passwordConfirm")}
                            type="password"
                            placeholder="Same as previous"
                            error={!!errors["passwordConfirm"]}
                            helperText={errors["passwordConfirm"]?.message}
                          />
                        </FormControl>
                        <input type="hidden" {...register("appsumo_coupon_code")} value={appsumoCouponCode} />
                        <Box display="flex" alignItems="center">
                          <Checkbox
                            value={termsCheck}
                            onChange={(e) => setTermsCheck(e.target.checked)}
                          />{" "}
                          <Typography style={{ color: '#fff' }}>
                            I agree to the{" "}
                            <Link
                              to="/terms"
                              target="_blank"
                              style={{
                                color: "#0168B5",
                              }}
                            >
                              Terms
                            </Link>
                            {" & "}
                            <Link
                              to="/privacy"
                              target="_blank"
                              style={{
                                color: "#0168B5",
                              }}
                            >
                              Privacy Policy
                            </Link>
                          </Typography>
                        </Box>
                      </Stack>
                      <LoadingButton
                        fullWidth
                        sx={{
                          height: 56,
                          background:
                            "linear-gradient(90deg, #036AB7 0%, #4BA5EB 100%)",
                          color: "white",
                          mt: 4,
                        }}
                        loading={signupState.isLoading}
                        type="submit"
                        disabled={!termsCheck}
                      >
                        Sign Up
                      </LoadingButton>
                    </Box>
                  </FormProvider>
                  <Typography mt={3} style={{ color: "#fff" }}>
                    Have an account?{" "}
                    <Link
                      to="/signin"
                      style={{
                        color: "#0168B5",
                      }}
                    >
                      Sign In
                    </Link>
                  </Typography>
                </Box>
              </Grid>
            </div>
          </div>
        </div>
      </div>




    </>

  );
};

export default SignupPage;
