import {
  Container,
  Box,
  Typography,
  Button,
  SvgIcon,
  Stack,
  Avatar,
} from "@mui/material";
import { ArrowForward, Margin } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { Arrowcircleright } from "../assets/icons/Arrowcircleright";
import { Arrowcircleright1 } from "../assets/icons/Arrowcircleright1";
import { Arrowright1 } from "../assets/icons/Arrowright1";
import { Arrowright5 } from "../assets/icons/Arrowright5";
import { Bracketscurly2 } from "../assets/icons/Bracketscurly2";
import { Bugbeetle } from "../assets/icons/Bugbeetle";
import { Caretright1 } from "../assets/icons/Caretright1";
import { Caretright2 } from "../assets/icons/Caretright2";
import { Caretup } from "../assets/icons/Caretup";
import { Caretup1 } from "../assets/icons/Caretup1";
import { Circlewavy1 } from "../assets/icons/Circlewavy1";
import { Code3 } from "../assets/icons/Code3";
import { Cpu1 } from "../assets/icons/Cpu1";
import { Diamondsfour } from "../assets/icons/Diamondsfour";
import { Eye } from "../assets/icons/Eye";
import { Gitbranch } from "../assets/icons/Gitbranch";
import { Gitpullrequest } from "../assets/icons/Gitpullrequest";
import { Gradient1 } from "../assets/icons/Gradient1";
import { Image1 } from "../assets/icons/Image1";
import { Note1 } from "../assets/icons/Note1";
import { Paperplanetilt1 } from "../assets/icons/Paperplanetilt1";
import { Playcircle } from "../assets/icons/Playcircle";
import { Tag } from "../assets/icons/Tag";
import { Target } from "../assets/icons/Target";
import { Terminalwindow } from "../assets/icons/Terminalwindow";
import { Treestructure2 } from "../assets/icons/Treestructure2";
import { Usergear } from "../assets/icons/Usergear";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useGetBlogDetailsQuery, useGetBlogDetQuery, useBlogsCountQuery } from "../redux/api/promptApi";


import img1 from "../assets/newimg/img-1.png";
// import img2 from "../assets/newimg/body-img.png"; 
import img2 from "../assets/newimg/body-img.png";
import text2 from "../assets/newimg/text-2.png";
import img3 from "../assets/newimg/icon1.png";
import img4 from "../assets/newimg/icon2.png";
import img5 from "../assets/newimg/icon3.png";
import img6 from "../assets/newimg/icon4.png";
import img7 from "../assets/newimg/icon5.png";
import img8 from "../assets/newimg/icon6.png";
import img9 from "../assets/newimg/icon7.png";
import img10 from "../assets/newimg/icon8.png";
import img11 from "../assets/newimg/icon9.png";
import img12 from "../assets/newimg/icon10.png";
import img13 from "../assets/newimg/icon11.png";
import img14 from "../assets/newimg/icon12.png";
import img15 from "../assets/newimg/icon13.png";
import img16 from "../assets/newimg/icon14.png";
import img17 from "../assets/newimg/icon15.png";
import img18 from "../assets/newimg//lock.png";
import img19 from "../assets/newimg/icon-1-1.png";
import img20 from "../assets/newimg/icon-1-2.png";
import img21 from "../assets/newimg/icon-1-3.png";
import img22 from "../assets/newimg/line.png";
import img23 from "../assets/newimg//lineicon1.png";
import img24 from "../assets/newimg/line.png";
import img25 from "../assets/newimg/lineicon2.png";
import img55 from "../assets/newimg/lineicon3.png";
import img26 from "../assets/newimg/line.png";
import img27 from "../assets/newimg/lineicon3.png";
import img28 from "../assets/newimg/mask_group-img.png";
import img29 from "../assets/newimg/bt_icon1.png";
import img30 from "../assets/newimg/bt_icon2.png";
import img31 from "../assets/newimg/bt_icon3.png";
import img32 from "../assets/newimg/bt_icon4.png";
import img33 from "../assets/newimg/bt_icon5.png";
import img34 from "../assets/newimg/bt_icon6.png";
import img35 from "../assets/newimg/bt_icon7.png";
import img36 from "../assets/newimg/bt_icon8.png";
import img37 from "../assets/newimg/bt_icon9.png";
import img38 from "../assets/newimg/icon-1-1.png";
import img39 from "../assets/newimg/icon-1-2.png";
import img40 from "../assets/newimg//icon-1-3.png";
import img41 from "../assets/newimg/icon-1-3.png";
import img42 from "../assets/newimg/icon-1-3.png";
import img43 from "../assets/newimg/gridicon1.png";
import img44 from "../assets/newimg/gridicon1.png";
import img45 from "../assets/newimg/gridicon1.png";
import img46 from "../assets/newimg/gridicon1.png";
import img47 from "../assets/newimg/img-1.png";
import img48 from "../assets/newimg/img-1.png";
import img49 from "../assets/newimg/img-1.png";
import img50 from "../assets/newimg/img-1.png";
import img51 from "../assets/newimg/card-img-1.png";
import img52 from "../assets/newimg/card-img-2.png";
import img53 from "../assets/newimg/card-img-3.png";
import img54 from "../assets/newimg/logo.png";
import video1 from "../assets/newimg/video.mp4";
import text1 from "../assets/newimg/text-1.png";
import body2 from "../assets/newimg/text-2.png";
import lock from "../assets/img/lock.png";
import python from "../assets/img/pythonicon.png";
import react from "../assets/img/react.png";
import vue from "../assets/img/vuejs.png";
import angular from "../assets/img/angularjsbig.png";
import nodejs from "../assets/img/nodejs.png";
import java from "../assets/img/java.png";
import cprogram from "../assets/img/c#.png";
import c1 from "../assets/img/c++.png";
import c2 from "../assets/img/c.png";
import php from "../assets/img/php.png";
import ruby from "../assets/img/ruby.png";
import go from "../assets/img/go.png";
import typescript from "../assets/img/typescript.png";
import swift from "../assets/img/swift.png";
import kotlin from "../assets/img/kotlin.png";
import rust from "../assets/img/rust.png";
import scala from "../assets/img/scala.png";
import r from "../assets/img/r.png";
import matlab from "../assets/img/matlab.png";
import perl from "../assets/img/perl.png";
import javascript1 from "../assets/img/javascript1.png";
import html5 from "../assets/img/html5.png";
import css from "../assets/img/css.png";
import sql from "../assets/img/sql.png";
import dart from "../assets/img/dart.png";
import flutter from "../assets/img/flutter.png";
import icon1 from "../assets/img/icon-1-1.png";
import iconbg from "../assets/img/icon-bg.png";
import gridicon2 from "../assets/newimg/gridicon2.png";
import gridicon3 from "../assets/newimg/gridicon3.png";
import gridicon4 from "../assets/newimg/gridicon4.png";
import logo from "../assets/newimg/logo.png";

import blog1 from "../assets/img/card-img-1.png";
import blog2 from "../assets/img/card-img-2.png";
//import blog3 from "../assets/img/blogs3.png";
import blog4 from "../assets/img/card-img-3.png";

import testimonial1 from "../assets/img/ellipse-34-2.png";
import testimonial2 from "../assets/img/ellipse-34-1.png";
import testimonial3 from "../assets/img/ellipse-34.svg";

import "./newstyle.css";
// import ".assests/Anycode.css";
import LandingHeader from "../components/headers/LandingHeader.headers";
import FAQItem from "../components/FAQItem";
import AuditItem from "../components/AuditItem";
import SliderItem from "../components/SliderItem";
import ItemsCarousel from 'react-items-carousel';
import TestimonialItem from "../components/TestimonialItem";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import { IGetBlogs } from "../redux/api/types";


import { object, string, TypeOf } from "zod";
import { useSubscribeNewsMutation } from "../redux/api/authApi";
import { toast } from "react-toastify";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";


const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})



export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>


const faqs = [
  {
    title: "What is Code Genie?",
    content:
      "Code Genie is an advanced AI-driven tool designed to audit smart contracts from various blockchain networks. It employs OpenAI's LLM model to provide comprehensive and accurate analysis of smart contract codes.",
  },
  {
    title: "How does Code Genie differ from traditional smart contract auditing methods?",
    content:
      "Unlike traditional methods that rely on manual scrutiny, Code Genie uses artificial intelligence to rapidly analyze and rectify smart contract vulnerabilities, ensuring enhanced accuracy and speed.",
  },
  {
    title: "How long does a Code Genie audit take?",
    content:
      "Leveraging AI technology, Code Genie can complete a full smart contract audit in mere seconds with just a single click.",
  },
  {
    title:
      "Is Code Genie suitable for users without coding knowledge?",
    content:
      "Absolutely! Code Genie is designed to be user-friendly. You do not need to possess coding expertise to audit your contracts using Code Genie.",
  },
  {
    title:
      "How does Code Genie ensure the upgradability of smart contracts?",
    content:
      "Code Genie's audit encompasses various aspects of smart contracts, including upgradability. It checks for future-readiness, ensuring that your contracts can adapt to changes and innovations in the blockchain ecosystem.",
  },
  {
    title: "How cost-effective is using Code Genie compared to other auditing services?",
    content:
      "Code Genie offers high-quality auditing services at a fraction of the industry's average cost. With both free and paid plans available, it provides affordable solutions for startups, developers, and agencies alike.",
  },
  {
    title: "Can Code Genie detect and rectify all types of vulnerabilities in a smart contract?",
    content:
      "Code Genie offers a comprehensive audit, identifying a wide range of vulnerabilities from security to gas usage. Moreover, it provides real-time solutions to rectify detected issues.",
  },
  {
    title: "How secure is the information and code I share with Code Genie?",
    content:
      "User security and data privacy are top priorities for Code Genie. All information and code shared are processed securely, ensuring that your data remains confidential.",
  },
  {
    title: "What blockchain networks does Code Genie support?",
    content:
      "Code Genie is versatile and designed to audit smart contracts from a myriad of blockchain networks. Detailed specifications can be found on the platform.",
  },
  {
    title: "How can I get started with Code Genie?",
    content:
      "Simply visit the Code Genie website, sign up, and you can start auditing your smart contracts with the click of a button. The platform also offers tutorials and guides to assist newcomers.",
  }
];

const audits = [
  {
    title: "Functionality: Ensuring Your Smart Contracts Work as Intended",
    icon: <Bracketscurly2 className="icon-instance-node-4" color="white" />,
    content: "Functionality lies at the core of any smart contract. With Code Genie, you can be confident that your smart contracts will execute their intended functions flawlessly. Our AI-powered auditing tool meticulously analyzes your code to identify any discrepancies or errors that might hinder your contract's functionality.",
  },
  {
    title: "Security: Safeguarding Your Smart Contracts from Threats",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "Security breaches can be catastrophic for your smart contracts. Code Genie's robust security checks go beyond conventional methods, identifying potential vulnerabilities and recommending best practices to mitigate risks. Protect your smart contracts from malicious attacks and unauthorized access with Code Genie.",
  },
  {
    title: "Gas Usage: Optimizing Efficiency and Cost-effectiveness",
    icon: <Gitpullrequest className="icon-instance-node-4" color="white" />,
    content: "Efficient gas usage is essential for optimal performance. Code Genie not only identifies gas-guzzling code segments but also suggests optimizations to reduce transaction costs. Save both time and money by ensuring your smart contracts run smoothly without wasting unnecessary resources.",
  },
  {
    title: "Visibility: Enhancing Transparency and Traceability",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "Transparency is key in the blockchain world. Code Genie enhances the visibility of your smart contracts by pinpointing areas where transparency could be improved. By fostering clear and traceable code, your users can trust the integrity of your smart contracts and make informed decisions.",
  }, {
    title: "Variables: Thorough Analysis of Data Storage",
    icon: <Code3 className="icon-instance-node-4" color="white" />,
    content: "Smart contracts rely on variables to store crucial data. Code Genie examines your variable declarations and usage patterns, ensuring that data storage is efficient and logically structured. Eliminate potential data inconsistencies with our comprehensive variable analysis.",
  }, {
    title: "Event Logs: Capturing Crucial Contract Interactions",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "Event logs play a vital role in understanding contract interactions. Code Genie reviews your event logging mechanisms, making certain that all significant interactions are appropriately captured. Gain valuable insights into your smart contract's execution for both auditing and analysis purposes.",
  }, {
    title: "Upgradability: Planning for Future Contract Evolution",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "Adapting your smart contracts over time is essential. Code Genie assesses your contract's upgradability by examining your upgrade mechanisms and compatibility considerations. Ensure your contracts can evolve while maintaining security and functionality.",
  }, {
    title: "Ownership: Clarifying Contract Ownership and Access Control",
    icon: <Usergear className="user-gear" />,
    content: "Ownership structures define who controls your smart contracts. Code Genie reviews your ownership patterns and access control mechanisms, helping you establish clear ownership guidelines and prevent unauthorized modifications.",
  }, {
    title: "Balance: Validating Token and Asset Management",
    icon: <Circlewavy1 className="icon-instance-node-4" color="white" />,
    content: "Balances are critical in token and asset management contracts. Code Genie validates your balance management functions, ensuring that token transfers and asset distributions are executed accurately and securely.",
  }, {
    title: "Fallback: Managing Unintended Contract Interactions",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "Fallback functions handle unexpected interactions with your contracts. Code Genie analyzes your fallback mechanisms, ensuring they are well-defined and protect your contracts from unintended behavior.",
  }, {
    title: "Self Destruct: Secure Contract Closure",
    icon: <Bugbeetle className="icon-instance-node-4" />,
    content: "Properly terminating a contract is as important as deploying it. Code Genie examines your self-destruct functions, verifying that contract closure is executed securely and with appropriate considerations for remaining funds or assets.",
  }, {
    title: "Exploit Scenarios: Identifying Potential Attack Vectors",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "Threats can come from various angles. Code Genie simulates potential exploit scenarios, helping you anticipate and prevent vulnerabilities before they are exploited by malicious actors.",
  },
];


const slider = [
  {
    title: "Extremely Accurate",
    icon: <Target className="icon-instance-node-3" color="white" />,
    content: " Code Genie uses a Large Language Model AI that conducts a full analysis of the smart contract code The AI will detect issues that might be overlooked by human eyes. Code Genie will even attempt to fix the bugs and issues found in your contract, assisting your developers in real time. See below for the various types of analysis performed by our AI.",
  },
  {
    title: "Extremely Fast &amp; Easy to Use",
    icon: <Paperplanetilt1 className="icon-instance-node-3" color="white" />,
    content: "Leveraging new AI technology, Code Genie can conduct a full smart contract audit in just a few seconds with a single click.You don't need any coding knowledge to self-audit your contracts. Try it for free...",
  },
  {
    title: "Extemely Affordable",
    icon: <Tag className="icon-instance-node-3" color="white" />,
    content: " Code Genie costs a fraction of the industry average. Our do-it-yourself approach could save you between $5,000 to $15,000+, the typical amount spent on auditing smart contracts in the industry.",
  },
  {
    title: "Secure your Digital Assets",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: " Code Genie makes it easy for blockchain teams building NFTs, DeFi, crypto, and other projects to self-audit their smart contracts. If at any time you wish to verify if your contract is safe, you can use Code Genie for an instant analysis and receive feedback with just one click.",
  }, {
    title: "The Value",
    icon: <Code3 className="icon-instance-node-4" color="white" />,
    content: " With Code Genie, blockchain projects can now self-audit their smart contracts instantly and securely with just one click, saving time, money, and reducing the risk of hacking or security fraud.",
  }, {
    title: ">Bug Free Contracts",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "Ultimately, by using Code Genie, companies can feel more confident about their deployed contracts. Code Genie helps identify vulnerabilities in smart contracts and assists developers in resolving these issues. Thus, Code Genie can optimize the company's development process, increase its development team's efficiency, and deliver high-quality, bug-free software products in a timely manner.",

  },
];


const testimonials = [
  {
    name: "Kristina Bruhahn - Continuum CEO,",
    icon: <img src={testimonial1} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
    content: "This is excellent for the industry and I love the data points.",
    reference: "Blockchain Compliance Expert"
  },
  {
    name: "Rutvik G.",
    icon: <img src={testimonial2} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
    content: "Code Genie gives all functions analysis and the kind of stuff that is in the function. So it is good to have an auditing perspective. Accuracy is also excellent. (regarding a Solidity smart contract Functionality audit)",
    reference: "Smart Contract Developer"
  },
  {
    name: "Jamal Woodley - CEO,",
    icon: <img src={testimonial3} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
    content: "I was able to use Code Genie to resolve the issue that I was having with a smart contract in just a few minutes. Surprising excellent time saver no headaches Rohan #mintmadecerfied let’s go.",
    reference: "Conceptualize AI Inc"
  }
];

/*const blogs = [
  {
      title: "A Dive into Decentralization: How Code Genie Elevates Smart Contracts",
      image: <img className="card-img-top" src={blog1} alt="" />,
      text: "In the vast cosmos of technological advancements, decentralization emerges as the North Star, guiding countless innovations and reshaping the very bedrock of digital interactions. At the heart of this transformative paradigm is the realm of smart contracts—self-executing contracts with the agreement directly written into lines of code. But as powerful as they are, ensuring their security, efficiency, and functionality is a task that demands a unique blend of precision, speed, and foresight. This is where Code Genie enters the scene, not just as another tool but as a revolution in itself.",
      href: "/blogs/A_Drive_into_Decentralization",
  },
  {
      title: "The AI Advantage: Code Genie's Mastery Over Traditional Auditing Methods",
      image: <img className="card-img-top" src={blog2} alt="" />,
      text: "In the intricate landscape of blockchain technology, smart contracts stand as pillars of innovation, offering a transparent, tamper-proof way to codify and automate agreements. As powerful as they might be, these contracts are not immune to human errors, and any mistake can lead to devastating financial consequences. Enter the world of smart contract auditing, where the focus isn't just on detecting problems but predicting them, mitigating them, and ensuring the seamless execution of every agreement. While traditional auditing methods have made significant strides, they are increasingly being overshadowed by the prowess of AI-driven tools, with Code Genie leading the charge.",
      href: "/blogs/The_AI_Advantage",
  },
  {
      title: "Beyond Security: The Multi-Dimensional Benefits of Code Genie’s Audits",
      image: <img className="card-img-top" src={blog4} alt="" />,
      text: "At the heart of the decentralized revolution, smart contracts are changing the way we transact, trust, and transform digital agreements. Given their pivotal role in underpinning a myriad of applications, the security of these contracts naturally comes to the forefront. However, while security remains paramount, it's merely one dimension of a broader spectrum that defines a robust smart contract. With Code Genie's innovative approach to audits, the horizon expands beyond mere security checks, unveiling a comprehensive suite of benefits that position smart contracts for optimal performance and longevity.",
      href:"/blogs/Beyond_Security",
  },
  {
      title: "Unveiling Code Genie: Revolutionizing Smart Contract Audits in the Blockchain Era",
      image: <img className="card-img-top" src={blog5} alt="" />,
      text: "The world of smart contracts is intricate and complex. Ensuring their flawlessness is paramount, not just for functionality but also to fortify them against potential breaches and vulnerabilities. Enter Code Genie – a groundbreaking AI tool that is redefining how we perceive smart contract audits.",
      href:"/blogs/Unveiling_Code_Genie",
  },
  {
      title: "Cost-Effective Auditing: Exploring Code Genie's Affordability in Blockchain",
      image: <img className="card-img-top" src={blog6} alt="" />,
      text: "The blockchain sphere is witnessing an exponential surge in the deployment of smart contracts, paving the way for a new era of digital agreements. However, as the complexity and intricacy of these contracts grow, so does the need for rigorous auditing. Traditionally, this has translated into substantial financial investments, often becoming a deterrent for startups and small projects. Enter Code Genie - an innovative solution that's not only pushing the boundaries of audit precision but also making it economically viable for all. Let's embark on a journey to unpack the affordability of Code Genie's auditing services.",
      href:"/blogs/Cost_Effective_Auditing",
  },
  {
      title: "A Novice in Blockchain? Here's Why Code Genie is Your Best Bet",
      image: <img className="card-img-top" src={blog7} alt="" />,
      text: "Blockchain, with its disruptive potential, is luring enthusiasts from all walks of life. But as novices dive deep into this ocean of possibilities, they often find themselves grappling with the complexities of smart contracts. These self-executing contracts, albeit powerful, require meticulous attention to detail. The stakes are high, and the margin for error? Minuscule. If you find yourself tiptoeing on the edge of this intimidating precipice, Code Genie is here to offer a safety net. Here's how this revolutionary tool ensures that even those without a coding background can navigate the smart contract maze with confidence.",
      href:"/blogs/A_Novice_In_Blockchain",
  },
  {
      title: "One Click, Multiple Solutions: The Comprehensive Auditing of Code Genie",
      image: <img className="card-img-top" src={blog8} alt="" />,
      text: "The blockchain universe is rapidly expanding, and as it does, the complexity of navigating its intricacies grows in tandem. From startups to seasoned developers, everyone is in search of a tool that simplifies the labyrinthine world of smart contracts. Enter Code Genie, the unparalleled smart contract auditing solution that promises not just detection but holistic enhancement. With its one-click audit feature, Code Genie is revolutionizing the way we approach blockchain efficiency, security, and functionality. Let's delve deeper into how Code Genie's comprehensive auditing is setting new standards in the blockchain landscape.",
      href:"/blogs/One_Click_Multiple_Solutions",
  },
  {
      title: "Real-time Rectifications: Code Genie's Proactive Approach to Smart Contract Audits",
      image: <img className="card-img-top" src={blog9} alt="" />,
      text: "In the intricate web of blockchain technology, smart contracts have emerged as the pivotal touchpoint. Their seamless operation determines the efficacy and trustworthiness of blockchain applications. However, as vital as detection of issues is, the true mettle of an auditing tool lies in its rectification capabilities. Code Genie, a trailblazer in the blockchain auditing domain, exemplifies this paradigm by not only identifying but also rectifying smart contract issues in real-time. With the increasing search volume around \"proactive smart contract solutions\" and \"real-time blockchain fixes\", it's evident that the market seeks tools that do more than just highlight problems. Code Genie is perfectly positioned to meet this demand.",
      href:"/blogs/Real_time_Rectifications",
  },
  {
      title: "Upgrading the Upgradable: How Code Genie Ensures Smart Contract Evolution",
      image: <img className="card-img-top" src={blog10} alt="" />,
      text: "The dynamic realm of blockchain technology is akin to a constantly moving train – with new stations being added at each turn. Amidst this progression, smart contracts act as the tracks, guiding and ensuring smooth transitions. Just like rail tracks need upgrades for newer trains, smart contracts need upgradability for evolving requirements. Recognizing this pivotal aspect, Code Genie offers specialized audits that emphasize ensuring smart contract upgradability, fostering adaptability in a world where change is the only constant.",
      href:"/blogs/Upgrading_the_Upgradable",
  },
  {
      title: "Breaking Down the Technical: Code Genie’s Audit Explained",
      image: <img className="card-img-top" src={blog11} alt="" />,
      text: "The blockchain ecosystem, while fascinating, often seems like an intricate web of complexities to the untrained eye. One such element is smart contract auditing, a crucial component to ensure security, efficiency, and robustness in the ever-evolving blockchain universe. Enter Code Genie, a revolutionary tool reshaping the audit landscape. But how does Code Genie make the magic happen? Let's embark on a deep dive into the technicalities and unravel the mysteries behind its auditing magic",
      href:"/blogs/Breaking_Down_the_Technical",
  },
  {
      title: "The Ethical Edge: Code Genie's Commitment to Transparent Auditing",
      image: <img className="card-img-top" src={blog12} alt="" />,
      text: "In the digital age, as the blockchain realm expands at a blistering pace, trust becomes the bedrock of all technological interactions. The blockchain, lauded for its immutable transparency, is no exception. Yet, as layers of complexities unfold, ensuring transparency, especially in areas like smart contract auditing, poses a challenge. Enter Code Genie, the beacon of ethical, transparent, and honest smart contract auditing. This tool stands tall not just for its technical prowess but also for its unwavering commitment to ethical standards.",
      href:"/blogs/The_Ethical_Edge",
  },
  {
      title: "The Evolution of Audits: Tracing Code Genie’s Revolutionary Journey",
      image: <img className="card-img-top" src={blog13} alt="" />,
      text: "In the heart of the digital renaissance, the concept of smart contracts has emerged as a groundbreaking innovation. As these self-executing contracts gain traction, ensuring their accuracy and security is paramount. Enter the world of smart contract audits, a niche but a vital segment of the blockchain ecosystem. This article traces the transformative journey of smart contract auditing, spotlighting the revolutionary contributions of Code Genie.",
      href:"/blogs/The_Evolution_of_Audits",
  },
];*/

const Cost = [
  {
    title: "Early Detection is Cheaper:",
    icon: <Bracketscurly2 className="icon-instance-node-4" color="white" />,
    content: "The cost to fix a bug increases the later it's found in the software development lifecycle. For example, a bug found during the requirements or design phase can be up to 100 times cheaper to fix than if it's detected after a product release.",
  },
  {
    title: "Post-release Costs",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "The Systems Sciences Institute at IBM reported that the cost to fix an error found after product release was four to five times more than one uncovered during design, and up to 100 times more than one identified in the maintenance phase.",
  },
  {
    title: "Economic Impact",
    icon: <Gitpullrequest className="icon-instance-node-4" color="white" />,
    content: "The Consortium for IT Software Quality (CISQ) estimated that poor software quality resulting from software bugs cost U.S. organizations $2.84 trillion in 2020.",
  },
  {
    title: "High-Profile Failures",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "There have been several high-profile software failures that resulted in significant financial losses. For instance, the 2012 FlashCrash linked to algorithmic trading resulted in a temporary loss of $1 trillion in market value.",
  }, {
    title: "Development Time",
    icon: <Code3 className="icon-instance-node-4" color="white" />,
    content: "In many projects, debugging consumes up to 50% of the development time, according to various reports. This means significant time (and thus money) is spent on finding and fixing issues rather than on creating new features.",
  }, {
    title: "Reputation and Revenue",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "For consumer-facing products, especially in the tech industry, a significant bug can tarnish a company's reputation and result in loss of users or sales. For instance, Apple's iOS Maps mishap in 2012 led to public apologies and recommended use of competitors.",
  }, {
    title: "Safety and Litigation Costs",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "In critical systems, like healthcare or aviation, software bugs can have dire consequences, leading to safety concerns and significant litigation costs.",
  }, {
    title: "Maintenance Overheads",
    icon: <Usergear className="user-gear" />,
    content: "A study suggested that around 60-80% of the cost of software goes into maintenance, and a significant portion of that is fixing bugs  .",
  }, {
    title: "Cost of Downtime",
    icon: <Circlewavy1 className="icon-instance-node-4" color="white" />,
    content: "For businesses that rely heavily on software (like online retail, finance, or service providers), downtime due to software bugs can be incredibly costly. Amazon, for example, would lose an estimated $220,000 per minute if its site went down",
  }, {
    title: "Developer Costs:",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "According to Cambridge University, global software developers spend approximately $312 billion a year debugging software errors.",
  },
];

const Benefits = [
  {
    title: "Code Functionality",
    icon: <Bracketscurly2 className="icon-instance-node-4" color="white" />,
    content: " Summary: Ensuring that the code works as intended and meets the specified requirements value:  Functional code is foundational; if it doesn't work, nothing else matters. This ensures that the software performs its designated tasks and fulfills its purpose.",

  },
  {
    title: "Logical Errors",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: " Summary: Checking for flaws in the logic that could cause unexpected behavior or results. value: Efficient code runs faster, uses fewer resources, and provides a better user experience, especially crucial in real-time systems.",

  },
  {
    title: "Coding Standards and Style",
    icon: <Gitpullrequest className="icon-instance-node-4" color="white" />,
    content: " Summary: nsuring that the code adheres to established organizational or industry coding standards and is consistent in style.  value: Consistent coding practices make the codebase maintainable, understandable, and less prone to errors introduced by misreading or misunderstanding code segments.",

  },
  {
    title: "Readability and Maintainability",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "Summary:  Verifying that the code is well-organized, adequately commented, and structured in a way that's easy for other developers to understand and modify. value:  Readable and maintainable code reduces future developmental costs and allows teams to make updates or find and fix bugs more efficiently.",

  }, {
    title: "Development Time",
    icon: <Code3 className="icon-instance-node-4" color="white" />,
    content: "In many projects, debugging consumes up to 50% of the development time, according to various reports. This means significant time (and thus money) is spent on finding and fixing issues rather than on creating new features.",

  }, {
    title: "Reputation and Revenue",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: " Summary: For consumer-facing products, especially in the tech industry, a significant bug can tarnish a company's reputation and result in loss of users or sales. value: For instance, Apple's iOS Maps mishap in 2012 led to public apologies and recommended use of competitors.",

  }, {
    title: "Security Vulnerabilities",
    icon: <Eye className="icon-instance-node-4" color="white" />,
    content: "Summary: Probing the code for potential security flaws or vulnerabilities that could be exploited maliciously.  value: Secure code protects user data, maintains trust, and ensures the software is resilient against cyber threats.",

  }, {
    title: "Code Redundancy",
    icon: <Usergear className="user-gear" />,
    content: "Summary: Checking for repetitive code or functions that could be optimized or consolidated.value:  Reducing redundancy minimizes the chance of errors, decreases the codebase size, and simplifies future modifications.  .",

  }, {
    title: "Testing and Coverage",
    icon: <Circlewavy1 className="icon-instance-node-4" color="white" />,
    content: "Summary: Ensuring that tests exist for the code and that they cover a wide range of scenarios. value:  Comprehensive testing ensures that any changes or additions to the code don't introduce new errors, safeguarding software reliability.",

  }, {
    title: "Integration and Dependencies",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "Summary: Reviewing how new code integrates with existing systems and ensuring dependencies are current and compatible.value:  Proper integration ensures smooth system operations, while updated dependencies prevent potential security risks and compatibility issues.",

  },

  {
    title: ">Documentation",
    icon: <Gradient1 className="icon-instance-node-4" color="white" />,
    content: "Checking if the code comes with adequate documentation, including inline comments and external documentation.value:  Documentation aids in onboarding new team members, supports maintainability, and serves as a reference during debugging.",

  },

];


const LandingPage = () => {
  //const genieSelector = useAppSelector((state) => state.genieState);
  let module_name = "SMART CONTRACTS";//genieSelector.module;
  localStorage.setItem("module", module_name);

  //blogs listing and displaying
  const [blogs, setBlogs] = useState<IGetBlogs[]>([]);
  const [randomInteger, setRandomInteger] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const params1 = new URLSearchParams(window.location.search);
  const useremail = localStorage.getItem('user_email') ?? '';
  const domain = window.location.hostname;
  const promptid = params1.get('promptid') ?? "";
  const getBlogsCount = useBlogsCountQuery({promptid: promptid, userid: useremail, cat: "SMART CONTRACTS", query: "", domain: domain });

  useEffect(() => {
    if (getBlogsCount.data) {
      setTotalPages(getBlogsCount.data);
    }
  }, [getBlogsCount.data]);


  useEffect(() => {
    // Calculate random integer when totalPages is available
    if (totalPages !== null) {
      const min = 1;
      const max = totalPages;
      const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min;
      setRandomInteger(randomInteger);
    }
  }, [totalPages]);
  // Move useGetBlogDetailsQuery outside of the useEffect
  const getBlogs = useGetBlogDetailsQuery({ limitval: 3, skipval: randomInteger, userid: useremail, domain: domain });

  useEffect(() => {
    if (getBlogs.data) {
      setBlogs([...getBlogs.data]);
    }
  }, [getBlogs]);



  const [showAllBlogs, setShowAllBlogs] = useState(false);

  useEffect(() => {
    // Scroll to the top of the landing page
    window.scrollTo(0, 0);
  }, []);




  const navigate = useNavigate();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 100;
  const slidestoshow = (isMobile) ? 1 : 3;
  const audittoshow = (isMobile) ? 1 : 3;
  const testimonialtoshow = (isMobile) ? 1 : 3;


  var slidersettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidestoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,
  };

  var auditsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,


  };
  var costsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,


  };

  var benefitssettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: audittoshow,
    slidesToScroll: 1,
    arrows: false,
    accessibility: false,
    nextArrow: false,
    prevArrow: false,


  };



  var testimonialsettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: testimonialtoshow,
    slidesToScroll: 1,
    arrows: true,
    // nextArrow: true,
    // prevArrow: ,


  };

  const module = localStorage.getItem('module');

  const filteredBlogs = blogs.filter((blog) => {
    if (module === 'SMART CONTRACTS' && (blog.cat === 'cat1' || blog.cat == 'SMART CONTRACTS')) {
      return true;
    }
    return false;
  });

  const slicedBlogs = filteredBlogs.slice(0, 3);


  const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();

  const methods = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };


  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .unwrap()
      .then((response) => {
        console.log('news subscription=>', response);
        const status = response.status;

        console.log(status);
        if (status === 'success') {
          toast.success(response.message);
          setSubscribeVal({
            email: '',
            status: ''
          });
        }
      })
      .catch((error) => {
        toast.error(error?.data?.detail ?? 'Something went wrong!');
        console.error('Error sending query:', error);

      });
  }
  return (

    <>
      <div className="container">
        <div className="row">
          <nav className="navbar navbar-expand-md">
            <div className="container-fluid">
              <a className="navbar-brand" href="#">
                {/* <img src="./img/logo.png" alt="" /> */}
                <img src={img54} className="img-fluid" alt="" />

              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarScroll"
                aria-controls="navbarScroll"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="">
                  <i className="bi bi-list text-white" />
                </span>
              </button>
              <div className="collapse navbar-collapse" id="navbarScroll">
                <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                  <li className="nav-item">
                    <a
                      className="nav-link nav_active"
                      style={{ color: "#4ba5eb", fontWeight: 800 }}
                      href="#"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup">
                      Affiliate
                    </a>
                  </li>


                  <li className="nav-item">
                    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5">
                      <li className="nav-item">
                        <a
                          className="nav-link nav_simple smart-nav-1"
                          style={{ color: "#225CD7", fontWeight: 700 }}
                          href="/"
                        >
                          Smart Contracts
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link nav_simple anycode-nav"
                          style={{ color: "#C0B5B6", fontWeight: 700 }}
                          href="/Anycode_landing"

                        >
                          Any Code
                        </a>
                      </li>

                    </ul>

                  </li>




                </ul>
                <div className="d-flex gap-4" role="search">
                  <button className="button_leniar px-3" type="submit" onClick={() => {
                    navigate("/signup");
                  }}>
                    Try Now
                  </button>
                  <button className="btn btn-outline-primary px-3"
                    onClick={() => {
                      navigate("/signin");
                    }}>Sign  in</button>
                </div>
              </div>
            </div>
          </nav>
          <hr className="mt-3" style={{ color: "#9aa0a3" }} />
        </div>
      </div>
      <div className="container">
        <div className="row my-sm-5 mb-5">
          <div className="col-md-5 text-white text-center mt-2">
            <div className="px-sm-0 px-2">
              <div>
                <div className="mb-5">
                  <img
                    src="./img/text-1.png"
                    className="img-fluid"
                    style={{ width: "80%" }}
                    alt=""
                  />
                </div>
                <div className="Ac-1">

                  {/* <div>
                <img src={text1} className="body-1" alt="" />
              </div> */}

                  <div className="frame-1707">
                    {/* <h1>CODE GENIE AI </h1>
            <h4>AUDITS AND FIXES</h4>
            <h4>SMART CONTRACTS</h4>
            <h4>With 1-Click WITH</h4>
            <h5 className="text-primary py-3">It takes only seconds</h5> */}
                    <div className="Ac-1">

                      <div>
                        <img src={text1} className="body-L1" alt="" />
                      </div>
                    </div> <br></br> <br></br>
                    <p className="never">We NEVER store your code or contract data</p>
                    <div className="frame-33345">
                      <div className="text-wrapper-7775" >Pricing Starts as low as $99 per contract</div>
                    </div> <br></br>  <br></br>

                    <div>
                      <p className="P1">
                        Code Genie can audit and fix virtually any smart contract within
                        seconds. Code Genie works on smart contracts from a variety of
                        blockchain networks. Smart Contract audits include Security Audit,
                        Functionality Audit, Ownership Audit, Gas Usage Audit and more. If
                        Code Genie finds a problem with the Contract it can Fix the Contract
                        as well.
                      </p>
                    </div>
                  </div>

                </div> <br></br> <br></br>
                {/* <p className="Never-1">
                We NEVER store your code or contract data
              </p>
              <h5 className="bg-primary py-2">TRY IT FOR FREE</h5>
              <p className="fw_samibold" style={{ textAlign: "justify" }}>
                Code Genie, harnessing LLM AI capabilities, offers exhaustive code
                reviews for various programming languages. It not only detects
                bugs but also suggests precise fixes and refactoring methods.
                Serving as a vital tool for developers, Code Genie ensures
                top-tier code quality, bolsters security, and streamlines the
                development process, elevating software robustness and efficiency.
              </p> */}
              </div>
              <div>
                <button className="button_leniar px-5 my-3"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  Get started for free <i className="bi bi-arrow-right " />
                </button>
                <div className="mt-3 mb-sm-0 mb-4">
                  <div
                    className="mx-auto px-3 py-2 rounded-1"
                    style={{
                      width: "fit-content",
                      border: "1px solid grey",
                      backgroundColor: "#020622"
                    }}
                  >
                    <h6>“Impressive.&nbsp; Very Impressive!”</h6>
                    <div className="d-flex gap-2">
                      {/* <img src="./img/img-1.png" className="img-fluid" alt="" /> */}
                      <img src={img47} className="img-fluid img_width-2" alt="" />

                      <div className="text-start">
                        <p className="mb-0">Saravanan B.</p>
                        <p
                          className="mb-0"
                          style={{ color: "#7A7A7A", fontSize: 12 }}
                        >
                          Engineering Manager
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 my-auto">
            <div className="px-sm-0 px-2">
              <img src={img2} className="img-fluid-code-L1" alt="" />
            </div>
          </div>
        </div>
        <div className="py-5 mx-sm-0 mx-2 backgroundbg">

          <div
            className="gap-3 justify-content-center"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="my-auto">
              <img src={img3} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img4} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img5} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img6} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img7} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img8} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img9} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img10} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img11} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img12} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img13} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img14} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img15} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img16} className="img-fluid" alt="" />
            </div>

            <div className="my-auto">
              <img src={img17} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
        {/* programming language Supported section end */}
      </div>
      <div className="container">
        <div className="row mt-sm-5">
          <div className="col-md-4 text-center my-auto">
            <div>
              <img src={lock} alt="" />
            </div>

          </div>
          <div className="col-md-8 my-auto">
            <div className="px-sm-0 px-2">
              <div className="back_border p-4 text-white rounded-1">
                <h3 className="mb-5 ms-3 main_heading">
                  How Safe are your digital assets?
                </h3>
                <ul className="fw_samibold">
                  <li className="mb-4">
                    70.36% of all Web3 security breaches are from smart contracts
                  </li>
                  <li className="mb-4">
                    $2.81 Billion in losses due to smart contract security compromises
                  </li>
                  <li className="mb-4">
                    91.96% of hacked contracts were previously audited by current auditing methods
                  </li>
                  {/* <li className="mb-4">
                  Developers spend up to 50% of project time on debugging,
                  delaying product releases.
                </li>
                <li className="mb-4">
                  Software vulnerabilities from bugs contribute to 60% of data
                  breaches.
                </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-4">
          <div className="col">
            <h3 className="text-center py-sm-5 py-2 main_heading">
              How is Code Genie Al Different?
            </h3>
          </div>
        </div>

        <div className="row mb-5">

          <Slider  {...slidersettings} sliderIconClass="frame-19">

            {slider.map((slider, index) => (
              <SliderItem
                title={slider.title}
                content={slider.content}
                key={`slider_item_${index}`}
                icon={slider.icon}

              />
            ))}




          </Slider>


        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 px-md-5">
            <div className="px-sm-0 px-2">
              <div className="mb-4 padding-top-90">
                <div>
                  <h4 className="text-center main_heading">
                    Empowering Blockchain Project Teams
                  </h4>
                  <div className="padding-top-50"></div>
                  <div className="rectangle"></div>
                  <div className="padding-top-30"></div>
                  {/*<div>
                  <img src={img24} className="img-fluid" alt="" />
                </div>*/}
                </div>

                <div className="d-flex gap-3 my-3">
                  <img src={img23} alt="" />
                  <h5 className="my-auto f_heading">Secure your Digital Assets</h5>
                </div>
                <p className="fw_samibold">
                  Code Genie makes it easy for blockchain teams building NFTs, DeFi, crypto, and other projects to self-audit their smart contracts. If at any time you wish to verify if your contract is safe, you can use Code Genie for an instant analysis and receive feedback with just one click.
                </p>
              </div>

              <div className="my-4">
                <div>
                  <div className="padding-top-30"></div>
                  <div className="rectangle"></div>
                  <div className="padding-top-30"></div>
                  {/*<div>
                  <img src={img24} className="img-fluid" alt="" />
              </div>*/}
                </div>

                <div className="d-flex gap-3 my-3">
                  <img src={img25} alt="" />
                  <h5 className="my-auto f_heading">The Value</h5>
                </div>

                <p className="fw_samibold">
                  With Code Genie, blockchain projects can now self-audit their smart contracts instantly and securely with just one click, saving time, money, and reducing the risk of hacking or security fraud.
                </p>
              </div>
              <div className="my-4">
                <div>
                  <div className="padding-top-30"></div>
                  <div className="rectangle"></div>
                  <div className="padding-top-30"></div>
                  {/*<div>
                  <img src={img24} className="img-fluid" alt="" />
            </div>*/}
                </div>

                <div className="d-flex gap-3 my-3">
                  <img src={img55} alt="" />
                  <h5 className="my-auto f_heading">Bug-free Development</h5>
                </div>
                <p className="fw_samibold">
                  Ultimately, by using Code Genie, companies can feel more confident about their deployed contracts. Code Genie helps identify vulnerabilities in smart contracts and assists developers in resolving these issues. Thus, Code Genie can optimize the company's development process, increase its development team's efficiency, and deliver high-quality, bug-free software products in a timely manner.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="bg_img">
              <div className="text-center">
                <img src={img28} className="img-fluid"
                  style={{ width: "80%" }} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-lg-5 p-md-5">
        <div className="row px-lg-5 px-2">
          <div className="col text-center">
            <h4 className="my-5 main_heading">
              Code Genie can audit and fix smart contract bugs and security flaws, minimizing your risk of cybersecurity attacks
            </h4>
          </div>
        </div>
        <div className="row px-lg-5 px-2">
          <div className="col-md-4 col-6 my-md-4 my-3">
            <div>
              <div className="d-flex gap-3">
                <div className="p_icon">
                  <img src={img29} alt="" />
                </div>
                <h6 className="my-auto f_heading2">
                  Functionality
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 my-md-4 my-3">
            <div>
              <div className="d-flex gap-3">
                <div className="p_icon">
                  <img src={img30} alt="" />
                </div>
                <h6 className="my-auto f_heading2">Security</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 my-md-4 my-3">
            <div>
              <div className="d-flex gap-3">
                <div className="p_icon">
                  <img src={img31} alt="" />
                </div>
                <h6 className="my-auto f_heading2">Gas Usage</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 my-md-4 my-3">
            <div>
              <div className="d-flex gap-3">
                <div className="p_icon">
                  <img src={img32} alt="" />
                </div>
                <h6 className="my-auto f_heading2">
                  Exploit Scenarios
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 my-md-4 my-3">
            <div>
              <div className="d-flex gap-3">
                <div className="p_icon">
                  <img src={img33} alt="" />
                </div>
                <h6 className="my-auto f_heading2">Balance</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 my-md-4 my-3">
            <div>
              <div className="d-flex gap-3">
                <div className="p_icon">
                  <img src={img34} alt="" />
                </div>
                <h6 className="my-auto f_heading2">Variables</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 my-md-4 my-3">
            <div>
              <div className="d-flex gap-3">
                <div className="p_icon">
                  <img src={img35} alt="" />
                </div>
                <h6 className="my-auto f_heading2">Event Logs</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 my-md-4 my-3">
            <div>
              <div className="d-flex gap-3">
                <div className="p_icon">
                  <img src={img36} alt="" />
                </div>
                <h6 className="my-auto f_heading2">Ownership</h6>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 my-md-4 my-3">
            <div>
              <div className="d-flex gap-3">
                <div className="p_icon">
                  <img src={img37} alt="" />
                </div>
                <h6 className="my-auto f_heading2">Fix Code</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" backgroundbg-do"
      // style={{
      //   backgroundImage: "url(./img/icon-bg.png)",
      //   display: "flex",
      //   flexWrap: "wrap"
      // }}
      >
        <div className="container">
          <div className="row py-5 px-0 px-lg-5">
            <div className="col-md-6">
              <div>
                <h3 className="px-lg-4 text-md-start text-center main_heading2">
                  Audit, Fix, Document your smart contracts today!
                </h3>
              </div>
            </div>
            <div className="col-md-6 text-md-end text-center my-auto">
              <div>
                <button className="button_leniar px-5 my-3"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  Get started for free <i className="bi bi-arrow-right " />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-sm-5 pb-5">
          <div className="col-md-6 my-auto">
            <div className="px-lg-5 px-2 text-sm-start text-center">
              <h4 className="mb-5 main_heading">
                Code Genie: Securing the Blockchain Ecosystem
              </h4>
              <p className="fw_samibold" style={{ textAlign: "justify" }}>
                At Code Genie, we recognize the critical importance of secure and reliable smart contracts. Our advanced auditing tool ensures that your smart contracts are meticulously examined and fortified against vulnerabilities. Discover how Code Genie can bolster the integrity and performance of your smart contracts.
              </p>
              <p className="fw_samibold">Code Genie was created by OceSha, Inc. Thank you for using our technology.</p>
              <p className="fw_samibold">Rohan Hall, CEO <br></br>
                OceSha.com</p>
            </div>
          </div>


          <div className="col-md-6">
            <div>
              <h6 className="text-primary text-center mb-3">HOW IT WORKS</h6>
              <div>
                {/* <video src="https://uiassetbucket.s3.amazonaws.com/how_it_works.mp4" className="img-fluid" controls="" /> */}
                <video
                  src="https://uiassetbucket.s3.amazonaws.com/how_it_works.mp4"
                  // height="443"
                  width="100%"
                  controls
                // style={{height:443, display:"flex", alignItems:"center", justifyContent:"center"}}
                >
                </video>
              </div>
            </div>
          </div>
        </div>
        {/* first-slider start */}
        <h4 className="text-center my-sm-5 py-sm-5 pb-5 main_heading">
          Code Genie Audits & Values
        </h4>

        <Slider  {...auditsettings}>

          {audits.map((audit, index) => (
            <AuditItem
              title={audit.title}
              content={audit.content}
              key={`audit_item_${index}`}
              icon={audit.icon}
            />
          ))
          }




        </Slider>

        <div className="my-sm-5 py-5"></div>
        <h4 className="text-center  main_heading">
          Smart Contracts Supported by Code Genie
        </h4>

        <div className="row mx-md-5 p-5">
          <div className="col-md-6 text-white text-center gird_g">
            <div className="p-4">
              <div>
                <img src={img43} alt="" />
                <h5 className="my-4">Token Contracts</h5>
                <p>
                  Ensure the secure and accurate management of your token-based
                  assets
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 text-white text-center gird_g2">
            <div className="p-4">
              <div>
                <img src={gridicon2} alt="" />
                <h5 className="my-4">DeFi Protocols</h5>
                <p>
                  Safeguard your decentralized finance protocols against potential exploits
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 text-white text-center gird_g3">
            <div className="p-4">
              <div>
                <img src={gridicon3} alt="" />
                <h5 className="my-4">Governance Contracts</h5>
                <p>
                  Maintain the integrity of your governance mechanisms and decision-making processes
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 text-white text-center gird_g4">
            <div className="p-4">
              <div>
                <img src={gridicon4} alt="" />
                <h5 className="my-4">NFT Marketplaces</h5>
                <p>
                  Enhance the security and transparency of your non-fungible token marketplaces
                </p>
              </div>
            </div>
          </div>
        </div>

        <h4 className="text-center my-sm-5 py-5 main_heading">
          Smart Contract Language Supported by Code Genie
        </h4>
        <div className="row text-white">
          <div className="col-md-6 mb-3">
            <div className="back_border p-4 rounded-1">
              <h5>Solidity</h5>
              <p className="mb-0">
                The most popular and widely used smart contract language for the
                Ethereum blockchain
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="back_border p-4 rounded-1">
              <h5>Fe</h5>
              <p className="mb-0">
                A smart contract language for the Fantom Opera blockchain, designed
                for safety, security, and efficiency
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="back_border p-4 rounded-1">
              <h5>Vyper</h5>
              <p className="mb-0">
                A Python-based language designed for Ethereum that prioritizes
                security and simplicity
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="back_border p-4 rounded-1">
              <h5>Liquidity</h5>
              <p className="mb-0">
                A language for Tezos that is high-level and easy to use, aiming to
                make smart contract development more accessible
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="back_border p-4 rounded-1">
              <h5>LLL (Low-Level Lisp-like Language)</h5>
              <p className="mb-0">
                A low-level Ethereum language for developers who want more control
                over the bytecode generated by their contracts
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="back_border p-4 rounded-1">
              <h5>Michelson</h5>
              <p className="mb-0">
                The low-level smart contract language for the Tezos blockchain, used
                for formal verification and execution
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="back_border p-4 rounded-1">
              <h5>Serpent</h5>
              <p className="mb-0">
                An older language for Ethereum that is no longer actively maintained
                but may still be encountered in legacy contracts
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="back_border p-4 rounded-1">
              <h5>V</h5>
              <p className="mb-0">
                A smart contract development platform for the Cardano blockchain,
                using Haskell-inspired language to ensure correctness
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="back_border p-4 rounded-1">
              <h5>Bamboo</h5>
              <p className="mb-0">
                A language for Ethereum that focuses on safety and expressiveness,
                designed to be more secure and human-readable
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="back_border p-4 rounded-1">
              <h5>Cadence</h5>
              <p className="mb-0">
                The resource-oriented smart contract language for the Flow
                blockchain, emphasizing security and usability
              </p>
            </div>
          </div>
        </div>


        {/* second-slider end */}
      </div>

      <div className="container text-white">
        <div className="row py-sm-5 pt-3 mx-lg-5">
          <div className="col-md-4 pt-sm-5 pb-3">
            <div className="text-md-start text-center">
              <h4 className="mb-sm-5 main_heading">
                Frequently Asked <br /> Questions
              </h4>
              <p className="fw_samibold">
                Couldn't find what you are looking for? <br />
                Write to us at :
                <a href="mailto:mail@codegenie.com"> support@code-genie.ai</a>
              </p>
            </div>
          </div>
          <div className="col-md-8 py-sm-5 py-3">
            <div>
              <div className="accordion" id="regularAccordionRobots">
                <div className="rounded-1 mb-2">
                  <h2 id="regularHeadingFirst" className="accordion-header">
                    <button
                      className="accordion-button accordion_style px-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#regularCollapseFirst"
                      aria-expanded="true"
                      aria-controls="regularCollapseFirst"
                    >
                      What is Code Genie?
                    </button>
                  </h2>
                  <div
                    id="regularCollapseFirst"
                    className="accordion-collapse collapse show"
                    aria-labelledby="regularHeadingFirst"
                    data-bs-parent="#regularAccordionRobots"
                  >
                    <div className="accordion-body">
                      Code Genie is an advanced AI-driven tool designed to audit smart contracts from various blockchain networks.
                      It employs OpenAI's LLM model to provide comprehensive and accurate analysis of smart contract codes.
                    </div>
                  </div>
                </div>
                {/*
              <div className="rounded-1 mb-2">
                <h2 className="accordion-header" id="regularHeadingSecond">
                  <button
                    className="accordion-button accordion_style collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapseSecond"
                    aria-expanded="false"
                    aria-controls="regularCollapseSecond"
                  >
                    Which programming languages does Code Genie support?
                  </button>
                </h2>
                <div
                  id="regularCollapseSecond"
                  className="accordion-collapse collapse"
                  aria-labelledby="regularHeadingSecond"
                  data-bs-parent="#regularAccordionRobots"
                >
                  <div className="accordion-body">
                    Code Genie supports a vast array of popular programming
                    languages, including Python, JavaScript, Java, C++, and many
                    more.
                  </div>
                </div>
              </div>*/}
                <div className=" rounded-1 mb-2">
                  <h2 className="accordion-header" id="regularHeadingThird">
                    <button
                      className="accordion-button accordion_style collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#regularCollapseThird"
                      aria-expanded="false"
                      aria-controls="regularCollapseThird"
                    >
                      How does Code Genie differ from traditional smart contract auditing methods?
                    </button>
                  </h2>
                  <div
                    id="regularCollapseThird"
                    className="accordion-collapse collapse"
                    aria-labelledby="regularHeadingThird"
                    data-bs-parent="#regularAccordionRobots"
                  >
                    <div className="accordion-body">
                      Unlike traditional methods that rely on manual scrutiny,
                      Code Genie uses artificial intelligence to rapidly analyze and rectify smart contract vulnerabilities,
                      ensuring enhanced accuracy and speed.
                    </div>
                  </div>
                </div>
                <div className="rounded-1 mb-2">
                  <h2 className="accordion-header" id="regularHeading4">
                    <button
                      className="accordion-button accordion_style collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#regularCollapse4"
                      aria-expanded="false"
                      aria-controls="regularCollapse4"
                    >
                      How long does a Code Genie audit take?
                    </button>
                  </h2>
                  <div
                    id="regularCollapse4"
                    className="accordion-collapse collapse"
                    aria-labelledby="regularHeading4"
                    data-bs-parent="#regularAccordionRobots"
                  >
                    <div className="accordion-body">
                      Leveraging AI technology,
                      Code Genie can complete a full smart contract audit in mere seconds with just a single click.
                    </div>
                  </div>
                </div>
                <div className="rounded-1 mb-2">
                  <h2 className="accordion-header" id="regularHeading5">
                    <button
                      className="accordion-button accordion_style collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#regularCollapse5"
                      aria-expanded="false"
                      aria-controls="regularCollapse5"
                    >
                      Is Code Genie suitable for users without coding knowledge?
                    </button>
                  </h2>
                  <div
                    id="regularCollapse5"
                    className="accordion-collapse collapse"
                    aria-labelledby="regularHeading5"
                    data-bs-parent="#regularAccordionRobots"
                  >
                    <div className="accordion-body">
                      Absolutely! Code Genie is designed to be user-friendly.
                      You do not need to possess coding expertise to audit your contracts using Code Genie.
                    </div>
                  </div>
                </div>
                <div className="rounded-1 mb-2">
                  <h2 className="accordion-header" id="regularHeading6">
                    <button
                      className="accordion-button accordion_style collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#regularCollapse6"
                      aria-expanded="false"
                      aria-controls="regularCollapse6"
                    >
                      How does Code Genie ensure the upgradability of smart contracts?
                    </button>
                  </h2>
                  <div
                    id="regularCollapse6"
                    className="accordion-collapse collapse"
                    aria-labelledby="regularHeading6"
                    data-bs-parent="#regularAccordionRobots"
                  >
                    <div className="accordion-body">
                      Code Genie's audit encompasses various aspects of smart contracts, including upgradability. It checks for future-readiness,
                      ensuring that your contracts can adapt to changes and innovations in the blockchain ecosystem.
                    </div>
                  </div>
                </div>
                <div className="rounded-1 mb-2">
                  <h2 className="accordion-header" id="regularHeading7">
                    <button
                      className="accordion-button accordion_style collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#regularCollapse7"
                      aria-expanded="false"
                      aria-controls="regularCollapse7"
                    >
                      How cost-effective is using Code Genie compared to other auditing services?
                    </button>
                  </h2>
                  <div
                    id="regularCollapse7"
                    className="accordion-collapse collapse"
                    aria-labelledby="regularHeading7"
                    data-bs-parent="#regularAccordionRobots"
                  >
                    <div className="accordion-body">
                      Code Genie offers high-quality auditing services at a fraction of the industry's average cost. With both free and paid plans available,
                      it provides affordable solutions for startups, developers, and agencies alike.
                    </div>
                  </div>
                </div>
                <div className="rounded-1 mb-2">
                  <h2 className="accordion-header" id="regularHeading8">
                    <button
                      className="accordion-button accordion_style collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#regularCollapse8"
                      aria-expanded="false"
                      aria-controls="regularCollapse8"
                    >
                      Can Code Genie detect and rectify all types of vulnerabilities in a smart contract?
                    </button>
                  </h2>
                  <div
                    id="regularCollapse8"
                    className="accordion-collapse collapse"
                    aria-labelledby="regularHeading8"
                    data-bs-parent="#regularAccordionRobots"
                  >
                    <div className="accordion-body">
                      Code Genie offers a comprehensive audit, identifying a wide range of vulnerabilities from security to gas usage.
                      Moreover, it provides real-time solutions to rectify detected issues.
                    </div>
                  </div>
                </div>
                <div className="rounded-1 mb-2">
                  <h2 className="accordion-header" id="regularHeading9">
                    <button
                      className="accordion-button accordion_style collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#regularCollapse9"
                      aria-expanded="false"
                      aria-controls="regularCollapse9"
                    >
                      How secure is the information and code I share with Code Genie?
                    </button>
                  </h2>
                  <div
                    id="regularCollapse9"
                    className="accordion-collapse collapse"
                    aria-labelledby="regularHeading9"
                    data-bs-parent="#regularAccordionRobots"
                  >
                    <div className="accordion-body">
                      User security and data privacy are top priorities for Code Genie.
                      All information and code shared are processed securely, ensuring that your data remains confidential.
                    </div>
                  </div>
                </div>

                <div className="rounded-1 mb-2">
                  <h2 className="accordion-header" id="regularHeading10">
                    <button
                      className="accordion-button accordion_style collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#regularCollapse10"
                      aria-expanded="false"
                      aria-controls="regularCollapse10"
                    >
                      What blockchain networks does Code Genie support?
                    </button>
                  </h2>
                  <div
                    id="regularCollapse10"
                    className="accordion-collapse collapse"
                    aria-labelledby="regularHeading10"
                    data-bs-parent="#regularAccordionRobots"
                  >
                    <div className="accordion-body">
                      Code Genie is versatile and designed to audit smart contracts from a myriad of blockchain networks.
                      Detailed specifications can be found on the platform.
                    </div>
                  </div>
                </div>

                <div className="rounded-1 mb-2">
                  <h2 className="accordion-header" id="regularHeading11">
                    <button
                      className="accordion-button accordion_style collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#regularCollapse11"
                      aria-expanded="false"
                      aria-controls="regularCollapse11"
                    >
                      How can I get started with Code Genie?
                    </button>
                  </h2>
                  <div
                    id="regularCollapse11"
                    className="accordion-collapse collapse"
                    aria-labelledby="regularHeading11"
                    data-bs-parent="#regularAccordionRobots"
                  >
                    <div className="accordion-body">
                      Simply visit the Code Genie website, sign up, and you can start auditing your smart contracts with the click of a button.
                      The platform also offers tutorials and guides to assist newcomers.
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="container footer_background py-sm-5 py-3">
        <div className="row text-white pt-sm-5">
          <div className="text-center">
            <p className="footer_boder mx-auto py-2 px-3 my-4 rounded-pill fs-14">
              TESTIMONIALS
            </p>
            <h3 className="fw-bold px-5">
              What people say about{" "}
              <span className="text-primary py-1 px-2 rounded-1 mt-md-0 mt-3 border_resp">
                Code Genie
              </span>
            </h3>
          </div>
        </div>
        <div className="slider center py-5">

          <Slider  {...testimonialsettings}>

            {testimonials.map((testimonial, index) => (
              <TestimonialItem
                name={testimonial.name}
                content={testimonial.content}
                key={`testimonial_item_${index}`}
                icon={testimonial.icon}
                reference={testimonial.reference}
                index={index}
              />
            ))}

          </Slider>
        </div>

      </div>
      <div className="container my-sm-5 my-3">
        <div className="">
          <h4 className="main_heading my-3 text-md-start text-center">News</h4>
        </div>
        <div className="row">
          {/* blogs.slice(0, showAllBlogs ? blogs.length : 3) */blogs.map((blog, index) => (
            <div className="col-md-4 mb-md-0 mb-3">
              <div>
                <div
                  className="card p-3 text-white"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #475569",
                    marginBottom: "1.5rem",
                  }}
                >
                  <div style={{ maxHeight: 300, minHeight: 300, overflow: 'Hidden' }}><img className="card-img-top" src={blog.blog_image_path_small} alt="" /></div>
                  <div className="card-body">
                    <h4 className="card-title">
                      {blog.blog_title}
                    </h4>
                    <Typography
                      className="card-text my-4"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                      }}
                      variant="body1">
                      {blog.blog_summary}
                    </Typography>
                    <div className="back_border ps-3 py-1">
                      {/* <a target="_blank" rel="noreferrer" href={blog.url} style={{  textDecoration: 'underline' }} >Read more</a> */}
                      <Link to={`/blogs/${blog.url}`} style={{ color: 'white' }}>Read More</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {!showAllBlogs && (
        <div className="text-center my-5">
          <button type="button" className="btn btn-outline-primary px-5">  <a target="_blank" href="/blogs?cat=SMART CONTRACTS" style={{ textDecoration: 'underline', color: '#FFF' }}> Read more blogs</a>
          </button>
        </div>
      )}
      <div className="py-5 mx-sm-0 mx-2 backgroundbg">
        <div className="container">
          <div className="row px-sm-0 px-2">
            <div className="col-lg-3 text-white text-lg-start text-center">
              <div>
                <div>
                  <img src={img54} alt="" />

                </div>
                <p className="fw_samibold">
                  OceSha/Code Genie Los Angeles, Ca 310-748-8901
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-white d-lg-none d-block  text-lg-start text-center">
              <div>
                <h6 className="f_heading mb-4">
                  Get news in your mailbox right away!
                </h6>
                <p className="fw_samibold">
                  In addition, stay in loop for our exclusive launches and limited
                  time deals.
                </p>
              </div>
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Search" />
                <button className="btn btn-primary" type="submit">
                  Subscribe
                </button>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4">
              <div>
                <div className="text-white">
                  <h6 className="fw_samibold">Products</h6>
                  <ul
                    className="ps-0 mt-3 footer_dec"
                    style={{ listStyle: "none" }}
                  >
                    <li>
                      <a target="_blank" href="https://ai-course.code-genie.ai/courses/ArtificialIntelligence">AI Course</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://consulting.code-genie.ai/">AI Consulting</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.amazon.com/TECH-WARZ-Rise-Artificial-Intelligence-ebook/dp/B0C7BPRX9J/ref=sr_1_1?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-1">AI Book</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.amazon.com/TECH-WARZ-BLOCKCHAIN-CRYPTOCURRENCY-Money-ebook/dp/B0C83TGQSY/ref=sr_1_2?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-2">Blockchain Book</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
              <div>
                <div className="text-white">
                  <h6 className="fw_samibold">Information</h6>
                  <ul
                    className="ps-0 mt-3 footer_dec"
                    style={{ listStyle: "none" }}
                  >
                    <li>
                      <a target="_blank" href="/aboutus">About Us</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873">Affiliate</a>
                    </li>
                    <li>
                      <a target="_blank" href="/contactus">Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4  text-lg-start text-sm-end">
              <div>
                <div className="text-white">
                  <h6 className="fw_samibold">Support</h6>
                  <ul
                    className="ps-0 mt-3 footer_dec"
                    style={{ listStyle: "none" }}
                  >
                    <li>
                      <a target="_blank" href="/terms">Terms and Conditions</a>
                    </li>
                    <li>
                      <a target="_blank" href="/privacy">Privacy &amp; Cookie policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text-white d-lg-block d-none">
              <div>
                <h5 className="mb-4 f_heading">
                  Get news in your mailbox right away!
                </h5>
                <p className="fw_samibold">
                  In addition, stay in loop for our exclusive launches and limited
                  time deals.
                </p>
              </div>
              {/* News Subscribe */}
              <div className="input-group mb-3" onSubmit={handleSubmit(onSubmitHandler)}>
                <input className={`form-control ${errors["email"] ? "error" : ""}`}
                  {...register("email")}
                  type="email"
                  placeholder="Enter your mail"
                  // error={!!errors["email"]}
                  // helperText={errors["email"]?.message}
                  onChange={(e) => setSubscribeVal({ ...subscribeVal, email: e.target.value })} />
                <button className="btn btn-primary" type="submit" onClick={handleSubscribeNews}>
                  Subscribe
                </button>
                {errors["email"] && (
                  <span className="error-message">{errors["email"]?.message}</span>
                )}
              </div>
              {/*  */}
            </div>
          </div>
        </div>
      </div>
      {/* first-slider js start */}
      {/* first-slider js end*/}
      {/*  */}
      {/*  */}

    </>
  );
};

export default LandingPage;